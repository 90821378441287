<template>
  <div id="partner" class="section partner">
    <div class="container">
      <div>
        <!--div>
          <h3>{{ t.t("partner", lang) }}</h3>
        </div-->
        <div class="slider-container">
          <s id="s1"></s>
          <s id="s2"></s>
          <s id="s3"></s>
          <s id="s4"></s>
          <s id="s5"></s>
          <s id="s6"></s>

          <div class="slider">
            <div>
              <div>
                <div v-for="(datax, index) in data.slice(0, 8)" :key="index">
                  <img :src="loadImg(datax.logo)" />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div v-for="(datax, index) in data.slice(8, 16)" :key="index">
                  <img :src="loadImg(datax.logo)" />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div v-for="(datax, index) in data.slice(16, 24)" :key="index">
                  <img :src="loadImg(datax.logo)" />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div v-for="(datax, index) in data.slice(24, 32)" :key="index">
                  <img :src="loadImg(datax.logo)" />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div v-for="(datax, index) in data.slice(32, 40)" :key="index">
                  <img :src="loadImg(datax.logo)" />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div v-for="(datax, index) in data.slice(40, 48)" :key="index">
                  <img :src="loadImg(datax.logo)" />
                </div>
              </div>
            </div>
          </div>

          <div class="prevNext">
            <div>
              <a href="#s6">
                <i class="fa fa-caret-left"></i>
              </a>
              <a href="#s2">
                <i class="fa fa-caret-right"></i>
              </a>
            </div>
            <div>
              <a href="#s1">
                <i class="fa fa-caret-left"></i>
              </a>
              <a href="#s3">
                <i class="fa fa-caret-right"></i>
              </a>
            </div>
            <div>
              <a href="#s2">
                <i class="fa fa-caret-left"></i>
              </a>
              <a href="#s4">
                <i class="fa fa-caret-right"></i>
              </a>
            </div>
            <div>
              <a href="#s3">
                <i class="fa fa-caret-left"></i>
              </a>
              <a href="#s5">
                <i class="fa fa-caret-right"></i>
              </a>
            </div>
            <div>
              <a href="#s4">
                <i class="fa fa-caret-left"></i>
              </a>
              <a href="#s6">
                <i class="fa fa-caret-right"></i>
              </a>
            </div>
            <div>
              <a href="#s5">
                <i class="fa fa-caret-left"></i>
              </a>
              <a href="#s1">
                <i class="fa fa-caret-right"></i>
              </a>
            </div>
          </div>

          <!--div class="bullets">
            <a href="#s1">1</a>
            <a href="#s2">2</a>
            <a href="#s3">3</a>
            <a href="#s4">4</a>
          </div-->
        </div>
        <div>&nbsp;</div>
      </div>
      <!--img id="ornamen-5" src="@/assets/templates/Ornamen_Bulat_03.png" /-->
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import jsonData from "@/assets/logo_partner/data.json";

const tlang = localStorage.getItem("lang");

export default {
  name: "About",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      data: jsonData,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    loadImg(img) {
      return require("@/assets/logo_partner/" + img);
    },
  },
};
</script>

<style>
s {
  position: relative;
  top: -400px;
}
</style>
