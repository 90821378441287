<template>
  <div id="about" class="section about">
    <div class="container">
      <img id="ornamen-1" src="@/assets/templates/Ornamen_Bulat_01.png" />
      <div>
        <div class="title-text">
          <h3>
            <hr class="line-title my-0 mx-auto" />
            {{ t.t("aboutus", lang) }}
          </h3>
        </div>
        <div class="title-desc">
          <div>
            <p>{{ t.t("aboutdesc.p1", lang) }}</p>
            <p>{{ t.t("aboutdesc.p2", lang) }}</p>
            <p>{{ t.t("aboutdesc.p3", lang) }}</p>
            <p>{{ t.t("aboutdesc.p4", lang) }}</p>
            <p>{{ t.t("aboutdesc.p5", lang) }}</p>
          </div>
        </div>
        <div class="about-type">
          <div>
            <div :style="loadImg('FaaS.png')"></div>
            <div>
              <span>FaaS</span>
            </div>
            <div>{{ t.t("about-type.p1", lang) }}</div>
          </div>
          <div>
            <div :style="loadImg('BaaS.png')"></div>
            <div>
              <span>BaaS</span>
            </div>
            <div>{{ t.t("about-type.p2", lang) }}</div>
          </div>
          <div>
            <div :style="loadImg('SaaS.png')"></div>
            <div>
              <span>SaaS</span>
            </div>
            <div>{{ t.t("about-type.p3", lang) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "About",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
  methods: {
    loadImg(img) {
      const str = require(`@/assets/templates/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-size": "cover",
      };
    },
  },
  props: {
    msg: String,
  },
};
</script>
