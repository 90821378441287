<template>
  <div class="sections investors">
    <div>
      <div>
        <img
          src="@/assets/investors/Banner-Website-DAB-Investor-Relation.jpg"
          :style="{ display: 'block !important' }"
        />
      </div>
      <div class="text-in-slide">
        <div><img src="@/assets/templates/Logo_Digiasia_White2.png" /></div>
        <div v-html="t.t('slide-text', lang)"></div>
        <div>
          Partnership is the nature of our core product marketing strategy as an
          <br />
          EFaaS Provider in developing ready to use cases for enterprises
          customers
        </div>
        <div>
          <button @click="toNews">Latest News</button>
        </div>
      </div>
    </div>
    <div class="prl-5">
      <div>
        <img
          class="present_ornamen_1"
          src="@/assets/templates/Ornamen_Bulat_01.png"
        />
        <hr
          class="bg-[#502052] rounded-xl h-2 w-50 mb-1rem border-transparent"
        />
        <h2 class="investor-comprofile">{{ t.t("company_profile", lang) }}</h2>
        <div>
          <p>{{ t.t("aboutdesc.p1", lang) }}</p>
          <p>{{ t.t("aboutdesc.p2", lang) }}</p>
          <p>{{ t.t("aboutdesc.p3", lang) }}</p>
          <p>{{ t.t("aboutdesc.p4", lang) }}</p>
          <p>{{ t.t("aboutdesc.p5", lang) }}</p>
        </div>
      </div>
      <!--div>
        <div>
          <div>
            <p>70+</p>
            <hr class="bg-white rounded-xl h-2 w-44 border-transparent" />
            <div>
              ENTERPRISE PARTNERS who have collaborated and entrusted their
              business with DigiAsia Bios Group
            </div>
            <hr class="hr-dotted" />
          </div>
          <div>
            <p>14m+</p>
            <hr class="bg-white rounded-xl h-2 w-44 border-transparent" />
            <div>
              Reaching out to people from all walks of life in Indonesia through
              various financial services offered through our FaaS partner
              customers
            </div>
            <hr class="hr-dotted" />
          </div>
          <div>
            <p>USD 45bn+</p>
            <hr class="bg-white rounded-xl h-2 w-44 border-transparent" />
            <div>
              NUMBER of current transactions that have been processed from
              various Partners.
            </div>
            <hr class="hr-dotted" />
          </div>
          <div>
            <p>780k SME Merchants</p>
            <hr class="bg-white rounded-xl h-2 w-44 border-transparent" />
            <div>
              TOTAL MERCHANT on platform offering escrow and embedded wallets
              into telco, FMCG, construction, agri and technology supply chains
              with close to a million active merchants.
            </div>
          </div>
        </div>
      </div-->
    </div>
    <div class="prl-5 about-type threes">
      <img
        class="present_ornamen_2"
        src="@/assets/templates/Ornamen_Bulat_02.png"
      />
      <div>
        <div :style="loadImg('FaaS.png')"></div>
        <div>
          <span>FaaS</span>
        </div>
        <div>{{ t.t("about-type.p1", lang) }}</div>
      </div>
      <div>
        <div :style="loadImg('BaaS.png')"></div>
        <div>
          <span>BaaS</span>
        </div>
        <div>{{ t.t("about-type.p2", lang) }}</div>
      </div>
      <div>
        <div :style="loadImg('SaaS.png')"></div>
        <div>
          <span>SaaS</span>
        </div>
        <div>{{ t.t("about-type.p3", lang) }}</div>
      </div>
    </div>

    <LeaderBoard />

    <div class="release prl-5 mt-50">
      <img
        class="present_ornamen_2"
        src="@/assets/templates/Ornamen_Bulat_02.png"
      />
      <div>
        <div>
          <hr
            class="bg-[#502052] rounded-xl h-2 w-50 mb-1rem border-transparent"
          />
          <div>News Release</div>
        </div>
        <div>
          <template v-for="(datax, index) in dtList" :key="index">
            <div>
              <div>
                <span v-if="lang == 'ind'">
                  {{ datax.title }}
                </span>
                <span v-if="lang == 'en'">
                  {{ datax.title_en }}
                </span>
              </div>
              <div>
                <span v-if="lang == 'ind'">
                  {{ datax.description }}
                </span>
                <span v-if="lang == 'en'">
                  {{ datax.description_en }}
                </span>
              </div>
              <div>
                <span @click="toDetailNews(datax.id)">
                  >>> {{ t.t("readmore", lang) }}</span
                >
              </div>
            </div>
          </template>
        </div>
        <div>
          <button @click="toNews">More News</button>
        </div>
      </div>
      <div>
        <div>
          <hr
            class="bg-[#502052] rounded-xl h-2 w-50 mb-1rem border-transparent"
          />
          <div>Investor Presentation</div>
        </div>
        <div>
          <a href="javascript:void(0)" @click="toPresent">
            <img
              src="@/assets/investors/Screenshot-Presentation.png"
              usemap="#present"
            />
          </a>
          <map name="present">
            <area
              target="_blank"
              alt=""
              title=""
              href=""
              coords="769,554,728,520"
              shape="rect"
            />
          </map>
        </div>
        <!--div>
          <button @click="toPresent">More Presentation</button>
        </div-->
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import dummy_3 from "@/assets/_dummy/news_top_3.json";
import LeaderBoard from "@/components/portal/InvestorLeaderBoard.vue";

const tlang = localStorage.getItem("lang");

export default {
  name: "InvestorRelations",
  components: {
    LeaderBoard,
  },
  data: () => ({
    t: i18n.global,
    lang: tlang,
    dtList: dummy_3,
  }),
  methods: {
    loadImg(img) {
      const str = require(`@/assets/templates/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-size": "cover",
      };
    },
    toNews() {
      location.href = "/newsRoom";
    },
    toDetailNews(id) {
      location.href = "/newsRoomDetails/" + id;
    },
    toPresent() {
      location.href = "/investor/presentations";
    },
  },
};
</script>
