<template>
  <div id="nrdcontent" class="section nrdcontent">
    <div class="container">
      <div>{{ loadDate(dt.date) }}</div>
      <div>
        <span v-if="lang == 'ind'">{{ dt.title }}</span>
        <span v-if="lang == 'en'">{{ dt.title_en }}</span>
      </div>
      <div>
        <img :src="loadImg(dt.image)" v-if="dt.image != undefined" />
      </div>
      <div>
        <div>
          <div>Share:</div>
          <div>
            <div>
              <a :href="loadLinkSosmed('fb')" target="_blank">
                <img src="@/assets/icons/icon_facebook.png" />
              </a>
            </div>
            <div>
              <a :href="loadLinkSosmed('tw')" target="_blank">
                <img src="@/assets/icons/icon_twitter.png" />
              </a>
            </div>
            <div>
              <a :href="loadLinkSosmed('ln')" target="_blank">
                <img src="@/assets/icons/icon_linkedin.png" />
              </a>
            </div>
          </div>
        </div>
        <div v-if="lang == 'ind'" v-html="dt.detail"></div>
        <div v-if="lang == 'en'" v-html="dt.detail_en"></div>
      </div>
      <div>
        <div>Media Coverage</div>
        <div v-if="dtCoverage != undefined">
          <div v-for="(datax, index) in dtCoverage" :key="index">
            <a :href="loadLink(datax.link, 1)" target="_blank">
              <img :src="loadImgPartner(datax.logo)" />
            </a>
          </div>
        </div>
      </div>
      <div>
        <div>Artikel Lainnya</div>
        <div>
          <div v-for="(dtLists, index) in dtList" :key="index">
            <div :style="loadBgImg(dtLists.image)">&nbsp;</div>
            <div>
              <a :href="loadLink(dtLists.id, 2)">
                <span
                  v-if="lang == 'ind'"
                  v-html="limitChar(dtLists.title, 'title')"
                ></span>
                <span
                  v-if="lang == 'en'"
                  v-html="limitChar(dtLists.title_en, 'title')"
                ></span>
              </a>
            </div>
            <div>
              <span
                v-if="lang == 'ind'"
                v-html="limitChar(dtLists.description, 'desc')"
              ></span>
              <span
                v-if="lang == 'en'"
                v-html="limitChar(dtLists.description_en, 'desc')"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import dummy from "@/assets/_dummy/news.json";
import dummy_3 from "@/assets/_dummy/news_top_3.json";
import moment from "moment";

const tlang = localStorage.getItem("lang");

export default {
  name: "newsRoomDetails",
  props: {
    msg: String,
  },
  methods: {
    loadDate(value) {
      if (value == "" || value == undefined) {
        return "-";
      } else {
        return moment(String(value)).format("DD MMMM YYYY");
      }
    },
    loadImg(imgPath) {
      //return `${process.env.VUE_APP_IMAGE_PATH}news/${imgPath}`;
      return require(`@/assets/newsroom/${imgPath}`);
    },
    loadImgPartner(imgPath) {
      //return `${process.env.VUE_APP_IMAGE_PATH}media_coverege/${imgPath}`;
      return require(`@/assets/newsroom/${imgPath}`);
    },
    loadBgImg(imgPath) {
      const path = require(`@/assets/newsroom/${imgPath}`);
      return {
        "background-size": "cover",
        //"background-image": `url(${process.env.VUE_APP_IMAGE_PATH}news/${imgPath})`,
        "background-image": `url(${path})`,
      };
    },
    loadLink(url, type) {
      var str;
      switch (type) {
        case 1:
          str = url;
          break;
        case 2:
          str = "/newsRoomDetails/" + url;
          break;
      }
      return str;
    },
    loadLinkSosmed(nil) {
      var val;
      switch (nil) {
        case "fb":
          val =
            "https://www.facebook.com/sharer/sharer.php?u=" + window.location;
          break;
        case "tw":
          val = "http://twitter.com/intent/tweet?url=" + window.location;
          break;
        case "ln":
          val =
            "https://www.linkedin.com/shareArticle?mini=true&url=" +
            window.location;
          break;
      }
      return val;
    },
    limitChar(char, type) {
      var varchar;
      if (type == "title") {
        varchar =
          char.length <= this.charLength1
            ? char.substring(0, this.charLength1)
            : char.substring(0, this.charLength1) + "...";
      } else if (type == "desc") {
        varchar =
          char.length <= this.charLength2
            ? char.substring(0, this.charLength2)
            : char.substring(0, this.charLength2) + "...";
      } else {
        varchar = "Unknown Handler";
      }

      return varchar;
    },
  },
  data() {
    return {
      lang: tlang,
      dt: [],
      dtDummy: dummy,
      dtDummy_3: dummy_3,
      dtList: [],
      dtCoverage: [],
      charLength1: 85,
      charLength2: 200,
    };
  },
  mounted() {
    /*axios
      .get(process.env.VUE_APP_IP_SERVER + "news/" + this.$route.params.id, {
        timeout: 5000,
      })
      .then((res) => {
        this.dt = res.data.data[0];
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        for (var i = 0; i < this.dtDummy.length; i++) {
          if (this.dtDummy[i].id == this.$route.params.id) {
            this.dt = this.dtDummy[i];
          }
        }
      });

    axios
      .get(process.env.VUE_APP_IP_SERVER + "newsLimit/3", {
        timeout: 5000,
      })
      .then((res) => {
        this.dtList = res.data.data;
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        this.dtList = this.dtDummy_3;
      });

    axios
      .get(
        process.env.VUE_APP_IP_SERVER +
          "coverageDetails/" +
          this.$route.params.id,
        {
          timeout: 5000,
        }
      )
      .then((res) => {
        this.dtCoverage = res.data.data;
        console.log(this.dtCoverage);
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        for (var i = 0; i < this.dtDummy.length; i++) {
          if (this.dtDummy[i].id == this.$route.params.id) {
            this.dtCoverage = this.dtDummy[i].partner;
          }
        }
      });*/

    for (var i = 0; i < this.dtDummy.length; i++) {
      if (this.dtDummy[i].id == this.$route.params.id) {
        this.dt = this.dtDummy[i];
      }
    }
    this.dtList = this.dtDummy_3;
    for (var y = 0; y < this.dtDummy.length; y++) {
      if (this.dtDummy[y].id == this.$route.params.id) {
        this.dtCoverage = this.dtDummy[y].partner;
      }
    }
  },
};
</script>

<style>
div.section.nrdcontent {
  margin-top: 10%;
}

div.section.nrdcontent > div.container {
  display: inline-block;
  width: 83%;
}

div.section.nrdcontent > div.container > div:nth-child(1) {
  color: rgba(146, 146, 146, 1); /* abu abu*/
  font-size: 18px;
  text-align: left;
}

div.section.nrdcontent > div.container > div:nth-child(2) {
  color: #8a2785; /* ungu tua */
  font-size: 55px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
  padding: 1% 4% 2% 0;
}

div.section.nrdcontent > div.container > div:nth-child(4) {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 2% 0;
  width: 100%;
}

div.section.nrdcontent > div.container > div:nth-child(5) {
  display: inline-block;
  padding: 5% 0;
  width: 100%;
}

div.section.nrdcontent > div.container > div:nth-child(4) > div:nth-child(1) {
  width: 20%;
}

div.section.nrdcontent > div.container > div:nth-child(4) > div:nth-child(2) {
  border-bottom: 1px solid rgba(86, 86, 86, 0.6);
  color: rgba(86, 86, 86, 1); /* abu abu*/
  font-size: 20px;
  padding-bottom: 3%;
  width: 80%;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(4)
  > div:nth-child(1)
  > div:nth-child(1) {
  font-weight: 700;
  padding-left: 5px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(4)
  > div:nth-child(1)
  > div:nth-child(2) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(4)
  > div:nth-child(1)
  > div:nth-child(2)
  > div {
  margin-right: 5px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(4)
  > div:nth-child(1)
  > div:nth-child(2)
  > div
  > a
  > img {
  width: 50px;
}

div.section.nrdcontent > div.container > div:nth-child(5) > div:nth-child(1) {
  font-size: 26px;
  font-weight: 700;
  padding: 2% 0;
  text-align: left;
}

div.section.nrdcontent > div.container > div:nth-child(5) > div:nth-child(2) {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(5)
  > div:nth-child(2)
  > div
  > a {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(5)
  > div:nth-child(2)
  > div
  > a
  > img {
  width: 50%;
}

div.section.nrdcontent > div.container > div:nth-child(6) {
  margin-bottom: 30px;
}

div.section.nrdcontent > div.container > div:nth-child(6) > div:nth-child(1) {
  font-size: 26px;
  font-weight: 700;
  padding: 4% 0 3% 0;
  text-align: left;
}

div.section.nrdcontent > div.container > div:nth-child(6) > div:nth-child(2) {
  display: inline-grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(6)
  > div:nth-child(2)
  > div {
  border: 1px solid rgba(86, 86, 86, 0.4);
  border-radius: 10px;
  margin: 0 1%;
  padding: 6%;
  text-align: left;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(6)
  > div:nth-child(2)
  > div
  > div:nth-child(1) {
  min-height: 250px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(6)
  > div:nth-child(2)
  > div
  > div:nth-child(2) {
  font-size: 25px;
  font-weight: 700;
  height: 110px;
  line-height: 1.1;
  padding: 3% 0;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(6)
  > div:nth-child(2)
  > div
  > div:nth-child(2)
  > a {
  color: #8a2785; /* ungu tua */
  text-decoration: none;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(6)
  > div:nth-child(2)
  > div
  > div:nth-child(3) {
  color: rgba(86, 86, 86, 1); /* abu abu*/
  font-size: 18px;
  height: 160px;
}

div.section.nrdcontent
  > div.container
  > div:nth-child(6)
  > div:nth-child(2)
  > div
  > div
  > img {
  width: 100%;
}

div.section.nrdcontent > div.container > div > img {
  border-radius: 10px;
  width: 100%;
}
</style>
