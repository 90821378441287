<template>
  <div id="nrcontent" class="section nrcontent">
    <div class="container">
      <div>
        <template v-for="(datas, index) in data" :key="index">
          <div v-if="index < 6 || this.isHidden == false">
            <div :style="loadBgImg(datas.image)">&nbsp;</div>
            <div>
              {{ datas.length }}
              <a :href="loadLink(datas.id)">
                <span v-if="lang == 'ind'">{{
                  limitChar(datas.title, "title")
                }}</span>
                <span v-if="lang == 'en'">{{
                  limitChar(datas.title_en, "title")
                }}</span>
              </a>
            </div>
            <div maxlength="200">
              <span
                v-if="lang == 'ind'"
                v-html="limitChar(datas.description, 'desc')"
              ></span>
              <span
                v-if="lang == 'en'"
                v-html="limitChar(datas.description_en, 'desc')"
              ></span>
            </div>
          </div>
        </template>
      </div>
      <a
        id="sHide"
        href="#sHide"
        class="hide"
        v-if="isHidden == true"
        v-on:click="isHidden = false"
      >
        {{ t.t("more", lang) }}
      </a>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import dummy from "@/assets/_dummy/news.json";
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "newsRoomContent",
  props: {
    msg: String,
  },
  methods: {
    loadBgImg(imgPath) {
      const path = require(`@/assets/newsroom/${imgPath}`);
      return {
        "background-size": "cover",
        //"background-image": `url(${process.env.VUE_APP_IMAGE_PATH}news/${imgPath})`,
        "background-image": `url(${path})`,
      };
    },
    loadLink(id) {
      return "/newsRoomDetails/" + id;
    },
    limitChar(char, type) {
      var varchar;
      if (type == "title") {
        varchar =
          char.length < this.charLength1
            ? char.substring(0, this.charLength1)
            : char.substring(0, this.charLength1) + "...";
      } else if (type == "desc") {
        varchar =
          char.length < this.charLength2
            ? char.substring(0, this.charLength2)
            : char.substring(0, this.charLength2) + "...";
      } else {
        varchar = "Unknown Handler";
      }
      return varchar;
    },
  },
  data() {
    return {
      lang: tlang,
      data: [],
      dummy: dummy,
      charLength1: 90,
      charLength2: 200,
      t: i18n.global,
      isHidden: true,
    };
  },
  mounted() {
    /*axios
      .get(process.env.VUE_APP_IP_SERVER + "newsCoverage", {
        mode: "no-cors",
        headers: {},
        timeout: 5000,
      })
      .then((res) => {
        this.data = res.data.data;
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        this.data = this.dummy;
      });*/
    this.data = this.dummy;
  },
};
</script>

<style>
div.section.nrcontent {
  margin-bottom: 5%;
}

div.section.nrcontent > div.container {
  border-radius: 10px;
  display: inline-block;
  width: 83%;
}

div.section.nrcontent > div.container > div {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
}

div.section.nrcontent > div.container > div > div {
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  padding: 8% 7%;
}

div.section.nrcontent > div.container > div > div > div:nth-child(1) {
  min-height: 270px;
}

div.section.nrcontent > div.container > div > div > div:nth-child(2) {
  color: #8a2785; /* ungu tua */
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
  height: 100px;
  padding: 20px 0 0 0;
  text-align: left;
}

div.section.nrcontent > div.container > div > div > div:nth-child(3) {
  color: rgba(121, 121, 121, 1); /* abu abu*/
  font-size: 18px;
  height: 120px;
  padding: 20px 0 0 0;
  text-align: left;
}

div.section.nrcontent
  > div.container
  > div
  > div
  > div:nth-child(3)
  > span
  > p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

div.section.nrcontent > div.container > div > div > div > img {
  width: 100%;
}
</style>
