<template>
  <div id="testimoni" class="section testimoni">
    <div class="container">
      <div>
        <div>
          <h3>
            <hr class="line-title my-0 mx-auto" />
            <span v-html="t.t('what_they_say', lang)"></span>
          </h3>
        </div>
        <div class="slider-container slide-testi">
          <s id="n1"></s>
          <s id="n2"></s>

          <div class="slider">
            <div>
              <div v-for="(datax, index) in data" :key="index">
                <div>
                  <div>
                    <img :src="loadImg(datax.foto)" width="100" class="pl-5" />
                  </div>
                  <div>
                    <b>{{ datax.person }}</b>
                    <br />{{ datax.title }} <br />{{ datax.partner }}
                  </div>
                </div>
                <div>
                  <div v-html="datax.comment"></div>
                </div>
              </div>
            </div>
            <!--div>
              <div v-for="index in 4" :key="index">
                <div>
                  <div>
                    <img
                      src="@/assets/logo_partner/logo_inkoppas.png"
                      width="100"
                    />
                  </div>
                  <div><b>Jhon Dee</b><br />Partner</div>
                </div>
                <div>
                  <div>
                    Memperluas payment points offline untuk meningkatkan
                    penggunaan E-money di kota kecil dan daerah pedesaan
                    Indonesia. Dengan lisensi LKD kami menciptakan outlet untuk
                    menyediakan layanan perbankan dan keuangan.
                  </div>
                </div>
              </div>
            </div-->
          </div>

          <!--div class="prevNext btnGroup">
            <div>
              <a href="#n2">
                <img
                  src="@/assets/templates/Button_Prev.png"
                  class="btn_prevs"
                />
              </a>
              <a href="#n2">
                <img
                  src="@/assets/templates/Button_Next.png"
                  class="btn_nexts"
                />
              </a>
            </div>
            <div>
              <a href="#n1">
                <img
                  src="@/assets/templates/Button_Prev.png"
                  class="btn_prevs"
                />
              </a>
              <a href="#n1">
                <img
                  src="@/assets/templates/Button_Next.png"
                  class="btn_nexts"
                />
              </a>
            </div>
          </div-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "Testimoni",
  props: {
    msg: String,
  },
  methods: {
    loadImg(img) {
      return require(`@/assets/testimoni/${img}`);
    },
  },
  data: () => ({
    t: i18n.global,
    lang: tlang,
    data: Object,
  }),
  mounted() {
    this.data = [
      {
        foto: this.t.t("testi_person.p1.foto", this.lang),
        person: this.t.t("testi_person.p1.person", this.lang),
        title: this.t.t("testi_person.p1.title", this.lang),
        partner: this.t.t("testi_person.p1.partner", this.lang),
        comment: this.t.t("testi_person.p1.comment", this.lang),
      },
      {
        foto: this.t.t("testi_person.p2.foto", this.lang),
        person: this.t.t("testi_person.p2.person", this.lang),
        title: this.t.t("testi_person.p2.title", this.lang),
        partner: this.t.t("testi_person.p2.partner", this.lang),
        comment: this.t.t("testi_person.p2.comment", this.lang),
      },
      {
        foto: this.t.t("testi_person.p3.foto", this.lang),
        person: this.t.t("testi_person.p3.person", this.lang),
        title: this.t.t("testi_person.p3.title", this.lang),
        partner: this.t.t("testi_person.p3.partner", this.lang),
        comment: this.t.t("testi_person.p3.comment", this.lang),
      },
      {
        foto: this.t.t("testi_person.p4.foto", this.lang),
        person: this.t.t("testi_person.p4.person", this.lang),
        title: this.t.t("testi_person.p4.title", this.lang),
        partner: this.t.t("testi_person.p4.partner", this.lang),
        comment: this.t.t("testi_person.p4.comment", this.lang),
      },
    ];
  },
};
</script>

<style>
.testimoni {
  margin-top: 50px;
  margin-bottom: 50px;
}

.testimoni > .container > div {
  display: inline-flex;
  position: relative;
  width: 80%;
}

.testimoni > .container > div > div:nth-child(1) {
  width: 30%;
}

.testimoni > .container > div > div:nth-child(2) {
  width: 70%;
}

.testimoni > .container > div > div:nth-child(1) > h3 {
  text-align: left;
  width: 220px;
}

.testimoni > .container > div > div:nth-child(1) > h3 > .line-title {
  margin-inline-start: 0;
}

.btnGroup {
  position: unset !important;
}

.btnGroup > div > a:nth-child(1) {
  position: absolute;
  top: 22rem;
  left: 0;
}

.btnGroup > div > a:nth-child(2) {
  position: absolute;
  top: 22rem;
  left: 40px !important;
}

.btn_prevs,
.btn_nexts {
  width: 40px;
}

.slide-testi {
  position: unset !important;
  height: auto !important;
}

.slide-testi > .slider > div {
  display: inline-grid;
  font-size: 16px;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 30px;
  align-items: flex-start;
  justify-items: center;
  margin-bottom: 10%;
}

.slide-testi > .slider > div > div {
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  color: #000;
  display: inline-block !important;
  margin-bottom: 10px;
  width: 95% !important;
}

.slide-testi > .slider > div > div > div {
  display: inline-block !important;
  width: 100%;
}

.slide-testi > .slider > div > div > div:nth-child(1) {
  background-color: #8a2785;
  border-radius: 15px 15px 0 15px;
  color: white;
  display: inline-flex !important;
  padding: 10px 0;
  width: 100%;
}

.slide-testi > .slider > div > div > div:nth-child(1) > div:nth-child(1) {
  width: 45%;
}
.slide-testi > .slider > div > div > div:nth-child(1) > div:nth-child(2) {
  font-size: 16px;
  text-align: left;
  width: 65%;
}
.slide-testi > .slider > div > div > div:nth-child(1) > div:nth-child(2) > b {
  font-size: 24px;
}

.slide-testi > .slider > div > div > div:nth-child(2) {
  height: 245px;
  overflow: hidden;
}

.slide-testi > .slider > div > div:nth-child(1) > div:nth-child(2):hover,
.slide-testi > .slider > div > div:nth-child(2) > div:nth-child(2):hover,
.slide-testi > .slider > div > div:nth-child(3) > div:nth-child(2):hover,
.slide-testi > .slider > div > div:nth-child(4) > div:nth-child(2):hover {
  height: auto;
}

.slide-testi > .slider > div > div > div:nth-child(2) > div {
  color: rgba(0, 0, 0, 0.6);
  font-family: SF Pro Display;
  margin: 30px;
  min-height: 200px;
  text-align: left;
}
</style>
