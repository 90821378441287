<template>
  <div class="sections investors">
    <div>
      <div>
        <img
          src="@/assets/investors/Banner-Website-DAB-Presentation.jpg"
          :style="{ display: 'block !important' }"
        />
      </div>
      <div class="text-in-slide present-box">
        <div>&nbsp;</div>
        <div v-html="t.t('investor_presentation', lang)"></div>
      </div>
    </div>
    <div class="presentation prl-5 relative">
      <div>
        <img
          class="present_ornamen_1"
          src="@/assets/templates/Ornamen_Bulat_01.png"
        />
        <hr
          class="bg-[#502052] rounded-xl h-2 w-50 mb-1rem border-transparent"
        />
        <h2 class="investor-comprofile">
          {{ t.t("investor_presentation", lang) }}
        </h2>
        <div>
          <template v-for="(dt, idx) in data" :key="idx">
            <div class="lists">
              <div>
                <a
                  :href="'/file_investor_presentation/' + dt.file"
                  target="_blank"
                >
                  <img
                    class="present-img"
                    src="@/assets/investors/presentation.png"
                  />
                </a>
              </div>
              <div>
                <div>{{ dt.date }}</div>
                <div>Investor Presentation</div>
              </div>
              <div>
                <!--a href="javascript:void(0)" @click="downloadPDF(dt.file)">
                  <img
                    class="present-img"
                    src="@/assets/investors/logo_pdf.png"
                  />
                </a-->
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "InvestorPresentations",
  data: () => ({
    t: i18n.global,
    lang: tlang,
    data: [
      {
        date: "1 Feb 2024",
        file: "DigiAsia Investor Presentation (1 Feb 2024).pdf",
      },
      {
        date: "17 May 2024",
        file: "DigiAsia Investor Presentation (17 May 2024).pdf",
      },
    ],
  }),
  methods: {
    downloadPDF(file) {
      axios
        .get("/file_investor_presentation/" + file, { responseType: "blob" })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
