<template>
  <div id="copyright" class="section copyright">
    <div class="container">
      <div>© {{ new Date().getFullYear() }} DIGIASIA All Rights Reserved.</div>
      <div>&nbsp;</div>
      <div>
        <div>
          <div>
            <a target="_blank" href="https://www.instagram.com/digiasia_bios/">
              <img :src="require('@/assets/icons/icon_instagram.png')" />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://web.facebook.com/Digiasia-Bios-113029900383228"
            >
              <img :src="require('@/assets/icons/icon_facebook.png')" />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://twitter.com/digiasia_bios/">
              <img :src="require('@/assets/icons/icon_twitter.png')" />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/digiasiabios"
            >
              <img :src="require('@/assets/icons/icon_linkedin.png')" />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC-UEkBY6QRxlCORbl3W7-Ig/videos"
            >
              <img :src="require('@/assets/icons/icon_youtube.png')" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
div.copyright {
  background: url("../../assets/templates/Footer_Background.png") no-repeat;
  background-position: 0 -100px;
  background-size: cover;
  background-repeat: no-repeat;
}

div.copyright > .container {
  border-top: 1px solid #ccc;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  margin-top: 50px;
  width: 90%;
}

div.copyright > .container > div:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  width: 40%;
}

div.copyright > .container > div:nth-child(2) {
  width: 30%;
}

div.copyright > .container > div:nth-child(3) {
  width: 30%;
}

div.copyright > .container > div:nth-child(3) > div {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 260px;
}

div.copyright > .container > div:nth-child(3) > div > div > a > img {
  width: 55px;
}
</style>
