export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahasa"])},
  "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang Kami"])},
  "achievement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencapaian Kami"])},
  "ourvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nilai Kami"])},
  "newsroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruang Berita"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
  "contact-investors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontak Investor"])},
  "contact-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk informasi lebih lanjut, Anda dapat langsung mengisi formulir dibawah ini dan tim kami akan segera menghubungi Anda!"])},
  "slide-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami Adalah Perusahaan Fintech as a Service <br />(FaaS) Pertama di Indonesia!"])},
  "why-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengapa Digiasia Bios"])},
  "vision-mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tumbuh Bersama <br />Digiasia"])},
  "aboutdesc": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia Bios, didirikan di Jakarta, pada November 2017."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi DigiAsia memungkinkan transaksi keuangan di semua segmen pelanggan. Dengan Misi Mendukung aspek transformasi keuangan mitra kami ke era digital dengan beragam produk keuangan untuk masyarakat luas dengan biaya rendah."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia menggunakan strategi dengan mengakuisisi dan mengkonsolidasikan operasional dan finansial digital, aset distribusi tradisional dan logistik; melalui jalur rendah pembiayaan menggunakan pendekatan keuangan tertanam."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia menawarkan layanan keuangan termasuk dengan sistem closed dan open loop wallets, pembayaran QR, pengiriman uang berbasis tunai dan digital, fungsi perbankan ATM / cabang bank serta penerbitan kartu melalui serangkaian akses API terbuka; yang dapat digunakan pada aplikasi seluler dan web antarmuka oleh para mitra kami."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menghindari investasi modal keuangan, waktu, dan sumber daya manusia yang berharga, mitra kami dapat memiliki layanan fintech bermerek atau menggunakan sistem white-label yang dapat dioperasikan hanya dalam hitungan minggu."])}
  },
  "about-type": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia menawarkan layanan keuangan termasuk dengan sistem closed dan open loop wallets, pembayaran QR, pengiriman uang berbasis tunai dan digital, fungsi perbankan ATM / cabang bank serta penerbitan kartu melalui serangkaian akses API terbuka; API tersebut dapat diintegrasikan pada aplikasi seluler dan web antarmuka oleh para mitra kami. Kami juga membantu Mitra untuk mendapatkan persetujuan regulasi yang relevan serta dapat memiliki layanan fintech bermerek atau dengan sistem white-label yang dapat dioperasikan dalam hitungan minggu."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia menawarkan para mitra layanan perbankan dan non-perbankan, dengan akses ke produk perbankan seperti rekening virtual, kartu prabayar, transfer valas, penerbitan kartu yang mencakup kartu prabayar dan debit/kredit, penarikan uang tanpa kartu di ATM, dan sebagainya, melalui sistem API white-label kami."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan integrasi mendalam pada berbagai produk fintech, DigiAsia memungkinkan Mitra mengakses produk-produk terdepan seperti, manajemen program loyalitas, analisis mendalam dalam pengoptimalan pendapatan, dan sebagainya. Juga melalui kolaborasi teknologi yang kami miliki seperti MasterCard."])}
  },
  "achievement-details": {
    "2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Didirikan pada November 2017</li></ul>"])},
    "2018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Memperkenalkan PayPro sebagai B2C Consumer Wallet</li><li class='list-disc'>Berubah strategi menjadi B2B untuk menargetkan pertumbuhan multi sektor yang menguntungkan (hal ini tervalidasi selama pandemi Covid-19)</li><li class='list-disc'><b>Kontrak pertama B2B - Dompet untuk Asian Games (Cashless pertama acara Asian Games)</b></li><li class='list-disc'>Mengakuisisi bisnis pengiriman uang dengan persetujuan untuk domestik dan lintas negara</li><li class='list-disc'>Meluncurkan KreditPro - Platform P2P lending</li></ul>"])},
    "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Pertumbuhan Bisnis B2B dimulai dari industri Telco, F&B, FMCG</li><li class='list-disc'><b>Meluncurkan bank digital pertama (KasPro Bank) untuk transaksi bernilai tinggi dan melakukan riset terkait Bank as a Services (BaaS)</b></li></ul>"])},
    "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Investasi Strategis dari MasterCard</li><li class='list-disc'><b>Membuka Branchless Banking - DigiBos memperoleh lisensi sebagai agen branchless banking yang diberikan kepada UKM sebagai cabang / ATM</b></li></ul>"])},
    "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Pertumbuhan signifikan pada perusahaan Pemerintah seperti kereta api, maskapai penerbangan, dana pensiun, dana asuransi kesehatan dari perusahaan, dll</li><li class='list-disc'><b>Peluncuran kartu virtual (VCN) melalui kemitraan dengan Mastercard</b></li></ul>"])},
    "2022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Kartu prabayar dan Syariah dengan Mastercard sebagai mitra global</li><li class='list-disc'><b>Mengakuisisi saham di Bank Index untuk meningkatkan layanan Banking-as-a-Service (BaaS)</b></li><li class='list-disc'><b>Mengakuisisi saham di Matchmove, perusahaan penerbit kartu terbesar di Asia Tenggara</b></li><li class='list-disc'><b>Mengembangkan Sistem Pembayaran KasPro Syariah dan Payment Gateway Syariah (akan datang)</b></li><li class='list-disc'><b>Meluncurkan dompet Syariah dan pembiayaan syariah bersama Nahdatul Ulama (NU)</b></li></ul>"])}
  },
  "vision": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terbukti dan Terpercaya"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitra bisnis kami telah berhasil mendigitalisasikan kebutuhan finansial bisnisnya dengan membangun ekosistem bersama Digiasia. Dengan komitmen team yang berpengalaman di industri fintech, kami telah menjadi solusi bagi kesuksesan bisnis mitra."])}
  },
  "mission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berkembang"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersama mitra bisnis, kami mengutamakan tumbuh dan perkembangan bisnis mitra untuk mencapai keuntungan serta keberhasilan bersama."])}
  },
  "why1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisensi"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami memiliki lisensi terlengkap pada layanan keuangan diantaranya, Uang Elektronik, P2P Lending, Remitansi, dan Layanan Keuangan Digital."])}
  },
  "why2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia memberikan dampak besar kepada mitra bisnis untuk meningkatkan layanan keuangan, dengan koneksi ekosistem dan touch points LKD terbesar di seluruh Indonesia."])}
  },
  "why3": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudah"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi bagi mitra melalui integrasi menggunakan API, sehingga mempercepat pemenuhan kebutuhan mitra bisnis terhubung dengan layanan kami pada aplikasi dan platform milik mitra."])}
  },
  "why4": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemudahan bagi mitra bisnis untuk terintegrasi dengan berbagai layanan kami sesuai dengan kebutuhan."])}
  },
  "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen"])},
  "we diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apa yang membuat kami berbeda?"])},
  "we diff desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendekatan B2B2C kami yang unik selalu membantu mitra bisnis kami untuk mendigitalkan operasional dan basis pelanggan mereka, sehingga dapat membentuk ekosistem digital dan non-tunai secara efektif dan menyeluruh. Selain segala kemudahan yang kami berikan, kami adalah perusahaan teknologi keuangan yang telah mempunyai lisensi lengkap di Industri layanan keuangan. Kami memiliki semua surat izin wajib untuk Pembayaran Digital, Pinjaman P2P dan Pengiriman Uang (Remitansi) di bawah 3 produk resmi Digiasia: KasPro, KreditPro & RemitPro."])},
  "team title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tim manajemen digiasia"])},
  "board-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia-Board Member & Advisors"])},
  "product-and-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk dan Layanan"])},
  "product": {
    "product1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menawarkan layanan E-money white-label atau co-branded (dompet digital, pembayaran QR) untuk kebutuhan pembayaran dengan persetujuan dan pengawasan penuh oleh Bank Indonesia."])},
    "product2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan P2P Lending yang berlisensi dan diawasi oleh Otoritas Jasa Keuangan (OJK)."])},
    "product3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan pengiriman uang melalui akses supply chain merchant aktif UKM kami yang telah terdaftar dan diawasi oleh Bank Indonesia."])},
    "product4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menawarkan ATM/layanan cabang melalui supply chain merchant aktif kami dengan menggunakan lisensi Layanan Keuangan Digital (LKD) dari Bank Indonesia."])}
  },
  "solutions-offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi Yang Kami Tawarkan"])},
  "solution1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen Digitalisasi Supply Chain"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk menjembatani antara permintaan dan penawaran, mengurangi biaya cash management."])}
  },
  "solution2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memungkinkan Pertumbuhan Untuk UMKM"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperluas pinjaman mikro ke UMKM."])}
  },
  "solution3": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayani Masyarakat Unbanked"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartu prabayar bersifat open-loop yang bisa digunakan untuk pembayaran online, membuka efisiensi e-commerce bagi masyarakat unbanked."])}
  },
  "solution4": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehadiran Pembayaran Offline"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperluas payment points offline untuk meningkatkan penggunaan E-money di kota kecil dan daerah pedesaan Indonesia."])}
  },
  "solution5": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformasi Bank Koperasi Digital"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisasi koperasi tradisional melalui platform yang aman, bebas gangguan dan terikat secara teknologi."])}
  },
  "solution6": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efisiensi Dalam Pengiriman Uang"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayani remitansi ke luar dan dalam negeri dengan skala yang cukup besar."])}
  },
  "solution7": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Mikro Melalui Kemitraan Telco"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengikuti perubahan di industri telekomunikasi dari fokus penawaran data ke fokus konten dan pengalaman."])}
  },
  "solution8": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terciptanya Branchless Banking"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan lisensi LKD kami menciptakan outlet untuk menyediakan layanan perbankan dan keuangan."])}
  },
  "footer": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia Bios merupakan Grup Fintech-as-a-Service (Faas) pertama di Indonesia. Berkomitmen untuk menjawab segala jenis tantangan yang berhubungan dengan lingkup finansial dan memiliki empat perizinan melalui afiliasinya: Digital Payment (KasPro), P2P Lending (KreditPro), Remittances (RemitPro), dan Layanan Keuangan Digital (DigiBos)."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Masing-masing afiliasi produk Digiasia Bios memiliki izin / terdaftar dan diawasi / berlisensi:"])}
  },
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitra"])},
  "our slide text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CORE VALUE\nDIGIASIA BIOS"])},
  "asia spirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Spirit"])},
  "asia-spirit-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Core Value kami adalah ASIA SPIRIT, yang menjadi pedoman bagi semua interaksi karyawan DigiAsia Bios. Prinsip utamanya adalah menjadi Agile, Speak Up, Integrity dan Team Work. Nilai-nilai ini bertindak sebagai pedoman dasar bagi seluruh keluarga DigiAsia Bios dalam mendukung kredibilitas dan komitmen perusahaan untuk terus bergerak maju seiring dengan semakin banyaknya mitra yang bergabung dan memperluas rangkaian produk kami."])},
  "agile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berfikir cepat dan beradaptasi dengan berbagai bentuk perubahan."])},
  "speakup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyampaikan suatu gagasan, ide, kritik dengan jelas, secara positif."])},
  "integrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berpikir positif, konsisten dalam tindakan, serta dapat diandalkan."])},
  "teamwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolaborasi team kerja untuk mencapai tujuan yang sama."])},
  "finding-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temukan Pekerjaan Impian Anda di Digiasia Bios"])},
  "more-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Lebih Lanjut"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Lebih Banyak"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Sedikit"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berita"])},
  "news_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Berita"])},
  "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karir"])},
  "career_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Karir"])},
  "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
  "media_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Media"])},
  "coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liputan"])},
  "coverage_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Liputan"])},
  "instagram_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Instagram"])},
  "youtube_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Youtube"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat Datang"])},
  "welcome-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat datang di dashboard DAB"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judul"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deskripsi"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanggal"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "profile_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rincian Profil"])},
  "company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Perusahaan"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama Pengguna"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata Kunci"])},
  "confirm-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfirmasi Kata Kunci"])},
  "password-notmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password tidak cocok"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda Yakin?"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buat"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubah"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpan"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batal"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutup"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambah"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambar"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posisi"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama File"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pekerjaan"])},
  "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permintaan"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])},
  "new_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Admin"])},
  "partnership_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MITRA yang telah bekerjasama dan mempercayakan usahanya bersama Group DigiAsia Bios."])},
  "partnership_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NILAI transaksi saat ini yang telah diproses dari berbagai Mitra."])},
  "partnership_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menjangkau semua lapisan masyarakat di Indonesia melalui berbagai layanan finansial digital yang dimiliki oleh Mitra FaaS."])},
  "partnership_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menawarkan <i>escrow</i> dan dompet digital ke dalam rantai industry telekomunikasi, FMCG, konstruksi, pertanian, serta teknologi dengan hampir satu juta pedagang aktif."])},
  "tPartner1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD 1M+"])},
  "tPartner2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14jt+"])},
  "tPartner3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["930rb UKM"])},
  "what_they_say": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apa Kata Mereka"])},
  "testi_person": {
    "p1": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_PaperID.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lifiana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Development"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper.id"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Paper.id merupakan B2B payment solution &amp; integrated invoice management yang berfokus membantu para pelaku bisnis untuk dapat menggunakan digitalisasi transaksi end-to-end pada bisnisnya.</p><p>Kami bekerjasama dengan DigiAsia Bios agar arus keuangan para pelaku bisnis atau UMKM ini lebih lancar dan terdigitalisasi. Semoga kedepannya semakin luas lagi kerjasamanya, tidak hanya di bagian financing, namun juga kerjasama di bidang yang lainnya sehingga para pelaku UMKM di Indonesia dapat merasakan manfaat fasilitas Paper.id dan KreditPro.</p>"])}
    },
    "p2": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_Stefanus.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stefanus Harjono"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktur"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT Surya Parama Distribusindo"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>PT Surya Parama Distribusindo bergerak dibidang perdagangan umum dan distribusi penjualan makanan,minuman dan sembako.</p><p>Terima kasih karena telah membantu pendanaan kami melalui KreditPro, perputaran keuangan kami jadi lebih lancar dan pembelian barang jadi lebih maksimal. Yang istimewa dari KreditPro adalah memberikan pembiayaan tanpa jaminan asset, bunga ringan, proses pengajuan mudah, sehingga bisa memberikan solusi untuk cash flow kami.</p>"])}
    },
    "p3": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_Akulaku.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malvin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Development"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkuLaku"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Akulaku adalah platform perbankan dan keuangan digital yang membantu memenuhi kebutuhan keuangan dalam menggunakan layanan perbankan dan pendanaan.</p><p>Kami sudah bekerjasama dengan KasPro untuk layanan sistem pembayaran QRIS yang memudahkan pelanggan AkuLaku untuk bertransaksi. Proses kerjasama ini berjalan dengan baik, pihak KasPro selalu fast respond dan bertanggung jawab sehingga memudahkan komunikasi kedua belah pihak. Semoga hubungan kerjasama ini terus berlanjut dan terjalin lebih baik untuk kedepannya.</p>"])}
    },
    "p4": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_David-TRON.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["David Santoso"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tron"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tron adalah sebuah perusahaan yang bergerak di bidang transportasi publik. KasPro membantu kami dalam mendigitalisasikan pembayaran transportasi umum tersebut. Ini didukung dengan proses integrasi API dengan KasPro yang selain mudah dan cepat, juga bisa disesuaikan dengan kebutuhan bisnis kami. Semoga kerjasama ini terus berkembang untuk kedepannya."])}
    }
  },
  "dab_icon": {
    "payment_online": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi / Pembayaran Online"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Digital untuk Payment Point Online Bank (PPOB), transaksi perbankan, dan pembayaran online."])}
    },
    "msme": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Mikro Melalui Kemitraaan dan Pertumbuhan UMKM"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekerjasama dengan sejumlah ekosistem pemasok, yang memiliki spesialisasi dalam pinjaman produktif. Selain itu juga ingin membantu menyalurkan kredit mikro kepada UMKM, guna menghilangkan beban transaksi di setiap pembayaran yang terjadi."])}
    },
    "mor": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencatatan Merchant"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menambah nilai usaha sekaligus memperluas jangkauan pelanggan, sehingga transaksi pun meningkat melalui pembayaran online dan pembayaran menggunakan kartu kredit maupun debit makin populer."])}
    },
    "branchless_banking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehadiran Pembayaran Offline dan Branchless Banking"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperluas payment points offline untuk meningkatkan penggunaan E-money (wallet), layanan PPOB, remittance, pembayaran QRIS dan tabungan emas di kota kecil dan daerah pedesaan Indonesia. Dengan lisensi LKD kami menciptakan outlet untuk menyediakan layanan perbankan dan keuangan."])}
    },
    "money_Transfer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efisiensi Pengiriman Uang"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengirim / penerima dapat mengunjungi layanan web kami untuk mengirim / menerima uang kepada orang yang Anda cintai, atau kepada mitra bisnis Anda. Ini juga dapat membantu Anda dalam menangani beberapa pembayaran digital."])}
    },
    "kaspro": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformasi Bank Koperasi Digital (KasPro Bank)"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisasi koperasi tradisional melalui platform yang aman, bebas gangguan dan terikat secara teknologi dengan berkolaborasi dengan Bank yang terpercaya."])}
    },
    "cash_management": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen Uang Tunai"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengelolaan keuangan secara digital yang dapat mempermudah pengecekan, rekonsiliasi dan penyelesaian serta pengurangan biaya-biaya transaksi."])}
    },
    "unbanked_customer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayani Masyarakat Unbanked"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memberikan kemudahan layanan perbankan / keuangan dengan platform digital berupa dompet digital dan layanan lainnya."])}
    }
  },
  "linkedin_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sejak 2010 pertama kali transportasi masal berbasis online hadir di Indonesia, pengguna mode transportasi ini mengalami peningkatan yang pesat karena kehadirannya telah memudahkan masyarakat memperoleh pelayanan transportasi yang lebih cepat dan efisien. Hal ini menyebabkan terjadinya persaingan harga, nilai ataupun kualitas, kepercayaan pada penyedia, serta adanya alternatif pilihan yang membuat konsumen semakin selektif dalam menentukan pilihan. Besarnya wilayah di Indonesia membuat para pelaku transportasi Indonesia juga berupaya melakukan berbagai inovasi layanan."])},
  "embedded_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded Finance"])},
  "our_solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solusi Kami"])},
  "user_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Journey"])},
  "open_api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Terbuka"])},
  "efaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded Finance as a Service"])},
  "efassdesc": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan berbagai lisensi fintech yang kami miliki, Digiasia Bios menyediakan layanan keuangan terpilih untuk para mitra terkait seperti untuk Pembayaran B2B, Pembayaran B2C, Pengiriman Uang, Pinjaman POS, Beli Sekarang Bayar Nanti, Pembiayaan Perdagangan, Tarik Tunai atau Setor Tunai, dll."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami melayani segmen perusahaan dari berbagai industri dengan memungkinkan mereka menggunakan API EFaaS kami untuk disematkan ke dalam histori transaksi asli mereka guna menutup rangkaian nilai transaksi ini."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oleh karena itu, kami mendefinisikan mitra berdasarkan peran mereka dalam tumpukan Digiasia Bios:"])},
    "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Southbound Partners, perusahaan yang berkolaborasi dengan Digiasia Bios sebagai pengaktif dengan mengizinkan kami menggunakan API mereka dalam kasus penggunaan EFaaS kami; dan"])},
    "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Mitra Northbound, perusahaan yang berkolaborasi dengan Digiasia Bios sebagai pengguna EFaaS dengan menggunakan API kami untuk memberikan layanan kepada pelanggan mereka"])}
  },
  "usecases": {
    "0": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen Fasilitas"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API untuk layanan yang menggunakan fasilitas seperti apartemen dan perkantoran."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Wallet on Partner app"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengintegrasikan Kaspro sebagai dompet utama di aplikasi partner untuk mengaktifkan pengguna untuk memproses transaksi menggunakan saldo Kaspro sebagai sumber dana. "])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner melakukan registrasi KasPro melalui aplikasi partner."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Permintaan OTP digunakan untuk memicu OTP untuk Nomor handphone yang didaftarkan. OTP dikirimkan ke nomor ponsel yang didaftarkan melalui WhatsApp atau SMS (jika nomor handphone yang didaftarkan tidak terdaftar di WhatsApp). "])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Validate OTP digunakan untuk melakukan validasi terhadap kode OTP yang telah di input oleh user."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk user melakukan upgrade menggunakan sistem OCR, identifikasi ID user dengan mengirimkan data foto KTP, foto Selfie, dan foto selfie memegang KTP."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner request customer detail seperti nama, tipe akun, status akun, tipe customer, email, nomor telepon, status KYC, jumlah saldo."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-Up [transfer VA and cash-in via modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer dan modern trade [Alfamart & Indomaret]"])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out [Bank Transfer and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Cash Out digunakan untuk melakukan transaksi transfer dari wallet user ke rekening bank atau Tarik tunai melalui modern trade."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API P2P transaction digunakan untuk melakukan transfer ke wallet sesama pengguna KasPro."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Requirement"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber wallet & Corporate Wallet"])}
        }
      }
    },
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rantai Pasok Ekosistem KasPro Bank"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundel API untuk Solusi Ekosistem Bisnis yang menyelaraskan Prinsipal, Distributor, Pedagang Besar, Pengecer menjadi satu ekosistem yang kuat & mengamankan risiko di bawah satu ekosistem."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melakukan transaksi pembayaran QRIS."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment to Merchant"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Payment to merchant digunakan untuk melakukan pembayaran QRIS dengan sumber dana wallet kaspro ke wallet merchant."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Check Status"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Check Status digunakan untuk partner request Status transaksi QRIS. "])}
        }
      }
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rantai Ritel"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundel API untuk bisnis rantai ritel berisi orientasi Pelanggan dan lain-lain."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co branding wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner melakukan registrasi KasPro melalui aplikasi partner."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Premium Member"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk user melakukan upgrade menggunakan sistem OCR, identifikasi ID user dengan mengirimkan data foto KTP, foto Selfie, dan foto selfie memegang KTP."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner request customer detail seperti nama, tipe akun, status akun, tipe customer, email, nomor telepon, status KYC, jumlah saldo."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Up"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer dan modern trade [Alfamart & Indomaret]."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melakukan pembayaran transaksi di dalam aplikasi."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Balance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API transfer balance digunakan untuk melakukan transfer dari saldo wallet."])}
        }
      }
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fintech lending yang memberikan pinjaman, cicilan tanpa kartu kredit, pulsa dan layanan PayLater."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengintegrasikan Kaspro sebagai dompet utama di aplikasi partner untuk mengaktifkan pengguna untuk memproses transaksi menggunakan saldo Kaspro sebagai sumber dana. "])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner melakukan registrasi KasPro melalui aplikasi partner."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk user melakukan upgrade menggunakan sistem OCR, identifikasi ID user dengan mengirimkan data foto KTP, foto Selfie, dan foto selfie memegang KTP."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Permintaan OTP digunakan untuk memicu OTP untuk Nomor handphone yang didaftarkan. OTP dikirimkan ke nomor ponsel yang didaftarkan melalui Whatsapp atau SMS (jika nomor handphone yang didaftarkan tidak terdaftar di whatsapp)."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Validate OTP digunakan untuk melakukan validasi terhadap kode OTP yang telah di input oleh user."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-up [transfer via VA and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer dan modern trade [Alfamart & Indomaret]."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS PayLater"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Paylater digunakan untuk melakukan pembayaran transaksi QRIS dengan menggunakan saldo deposit partner sebagai sumber dana."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet requirement: Corporate Wallet & Subscriber Wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layanan Pengiriman"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API untuk layanan pengiriman yang menyediakan layanan orientasi, pembayaran, dan dompet pelanggan."])},
      "api": {
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk memproses pembayaran transaksi dalam aplikasi menggunakan saldo wallet."])}
        }
      }
    },
    "5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportasi"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasar transportasi berbasis seluler yang memungkinkan pengguna menemukan dan memesan sepeda motor, taksi, dan pengiriman sesuai permintaan."])},
      "api": {
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners requesting customer details such as name, account type, account status, customer type, email, telephone number, KYC status, total balance."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-up User wallet [transfer VA and cash-in via modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer dan modern trade [Alfamart & Indomaret]."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit/Top Up Driver wallet [transfer VA and cash-in via modern trade] using Prefix"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan wallet milik driver untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer dan modern trade [Alfamart & Indomaret] dengan prefix khusus."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash-out user & driver [Bank transfer and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Cash Out digunakan untuk melakukan transaksi transfer dari wallet user ke rekening bank atau Tarik tunai melalui modern trade."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Payment (Ride Hailing Payment)"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk memproses pembayaran transaksi dalam aplikasi menggunakan saldo wallet."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips Ride Hailing"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk memproses pembayaran tips kepada driver menggunakan saldo wallet."])}
        },
        "11": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API P2P transaction digunakan untuk melakukan transfer ke wallet sesama pengguna KasPro."])}
        },
        "12": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melakukan transaksi pembayaran QRIS."])}
        },
        "13": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet requirement: Corporate Wallet, Escrow Wallet, Merchant Wallet, Subscriber Wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "6": {
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelanggan dapat menjelajahi & menyematkan setiap atau semua daftar produk tagihan kami."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Manajemen Tagihan"])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Biller Inquiry digunakan untuk melakukan pengecekan detail transaksi pembelian PPOB sebelum dilakukan pembayaran."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Biller Payment digunakan untuk melakukan pembayaran produk PPOB dengan menggunakan sumber dana dari wallet KasPro."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airtime & Data Package"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepaid & Postpaid Electricity Bill : PLN"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Bill : PDAM"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Bill"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Finance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-money"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance : BPJS"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property taxes : PBB"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "11": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Voucher"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregator Mitra QRIS"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundel API untuk bisnis yang membutuhkan Acquiring merchant dan menyediakan persyaratan fitur QRIS."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Merchant Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Merchant Registration digunakan untuk partner mendaftarkan merchant partner sebagai merchant QRIS KasPro via API."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic QR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Dynamic QR digunakan untuk partner generate QR dynamic KasPro."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static QR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Static QR digunakan untuk partner generate QR statis KasPro."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment Acquirer Notification"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Payment Acquirer Notification digunakan untuk partner dapat menerima notifikasi transaksi pembayaran QRIS."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Refund"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Refund digunakan untuk mendapatkan notifikasi dari KasPro untuk refund Transaksi QRIS."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP for Transaction Report & Static QRIS Image Transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API SFTP for Transaction Report & Static QRIS Image Transfer digunakan untuk KasPro & Partner dapat mengirim dan menerima Laporan transaksi QRIS dan gambar QRIS statis melalui SFTP."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Callback Transaction digunakan untuk mengaktifkan KasPro untuk menyediakan transaksi Callback dengan detail seperti Issuer Name, Customer Data (Phone Name, Details) Details of Paid Nominal & MDR Partner."])}
        }
      }
    },
    "8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrasi Manajemen Tunai"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen tunai yang dibuat khusus untuk pelanggan korporat yang bertujuan untuk meningkatkan efisiensi perusahaan mereka."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengintegrasikan Kaspro sebagai dompet utama di aplikasi partner untuk mengaktifkan pengguna untuk memproses transaksi menggunakan saldo Kaspro sebagai sumber dana."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Cash Out digunakan untuk melakukan penarikan tunai dari saldo wallet user."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Bank portal partner"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap Development"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual account KasPro Bank"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum Tersedia"])}
        }
      }
    },
    "9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akun Linkage"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tautkan akun dan integrasi sistem dalam aplikasi atau bisnis Anda, layanan elektronik yang sudah dapat digunakan."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate KasPro as the main wallet in partner applications to enable users to process transactions using KasPro balance as a source of funds."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Premium (OCR KYC)"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Stage"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Permintaan OTP digunakan untuk memicu OTP untuk Nomor handphone yang didaftarkan. OTP dikirimkan ke nomor ponsel yang didaftarkan melalui Whatsapp atau SMS (jika nomor handphone yang didaftarkan tidak terdaftar di whatsapp)."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Validate OTP digunakan untuk melakukan validasi terhadap kode OTP yang telah di input oleh user."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Up / Isi Saldo KasPro"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions"])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melakukan transaksi pembayaran QRIS."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Transfer digunakan untuk melakukan transaksi transfer dari wallet user ke rekening bank."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Cash Out digunakan untuk melakukan penarikan tunai dari saldo wallet user."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melihat histori transaksi berdasarkan waktu transaksi."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI/UX (design & structure) on partner side"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desain UI/UX disesuaikan berdasarkan aplikasi partner."])}
        }
      }
    },
    "10": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank KasPro"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisasi Koperasi Tradisional – melalui platform yang aman dan lancar serta rangkaian teknologi yang ada."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get balance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Get Balance digunakan untuk menampilkan saldo yang tersedia di akun rekening KasProbank."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get bank list"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Get Bank List digunakan untuk menampilkan daftar bank sebagai tujuan transfer."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Inquiry transfer digunakan untuk menampilkan detail transaksi transfer sebelum dilakukan konfirmasi."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API confirm transfer digunakan untuk melakukan konfirmasi transaksi transfer (Page 15-16 API KasPro Bank)"])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melihat histori transaksi berdasarkan waktu transaksi."])}
        }
      }
    }
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can you build?"])},
    "0": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dompet Digital"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda dapat menyematkan dompet digital kami ke dalam aplikasi asli Anda dengan tautan akun demi meningkatkan pengalaman pelanggan saat bertransaksi dengan mulus untuk meningkatkan loyalitas pada aplikasi Anda sementara kami menjaga ketentuan peraturan dan teknologi."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Wallet on Partner app"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengintegrasikan Kaspro sebagai dompet utama di aplikasi partner untuk mengaktifkan pengguna untuk memproses transaksi menggunakan saldo Kaspro sebagai sumber dana. "])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner melakukan registrasi KasPro melalui aplikasi partner."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Permintaan OTP digunakan untuk memicu OTP untuk Nomor handphone yang didaftarkan. OTP dikirimkan ke nomor ponsel yang didaftarkan melalui WhatsApp atau SMS (jika nomor handphone yang didaftarkan tidak terdaftar di WhatsApp). "])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Validate OTP digunakan untuk melakukan validasi terhadap kode OTP yang telah di input oleh user."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk user melakukan upgrade menggunakan sistem OCR, identifikasi ID user dengan mengirimkan data foto KTP, foto Selfie, dan foto selfie memegang KTP."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk partner request customer detail seperti nama, tipe akun, status akun, tipe customer, email, nomor telepon, status KYC, jumlah saldo."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-Up [transfer VA and cash-in via modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer dan modern trade [Alfamart & Indomaret]"])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out [Bank Transfer and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Cash Out digunakan untuk melakukan transaksi transfer dari wallet user ke rekening bank atau Tarik tunai melalui modern trade."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API P2P transaction digunakan untuk melakukan transfer ke wallet sesama pengguna KasPro."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment to Merchant Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Payment to merchant transaction digunakan untuk melakukan pembayaran dengan menggunakan wallet KasPro sebagai sumber dana ke wallet merchant."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Requirement"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber wallet & Corporate Wallet"])}
        }
      }
    },
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran QRIS"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dengan menyematkan Pembayaran QRIS, mengaktifkan pembayaran QRIS yang dapat digunakan pada aplikasi pelanggan atau system POS."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk melakukan transaksi pembayaran QRIS."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment to Merchant"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Payment to merchant digunakan untuk melakukan pembayaran QRIS dengan sumber dana wallet kaspro ke wallet merchant."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Check Status"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Check Status digunakan untuk partner request Status transaksi QRIS. "])}
        }
      }
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managemen Tunai"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyematkan sistem manajemen tunai dalam sistem ERP memungkinkan Anda membuat sistem dengan closed-loop untuk pengguna Anda."])}
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinjaman B2B"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Pinjaman B2B ditujukan untuk usaha mikro kecil dan menengah yang dapat disematkan ke dalam aplikasi digital yang disediakan oleh penyedia platform teknologi. Ini akan meningkatkan GTV pemilik platform dan meningkatkan pengalaman pengguna dalam sistem B2B mereka."])}
    },
    "4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Digital & Tagihan"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyematkan Produk Digital & Tagihan kami ke dalam aplikasi asli Anda memungkinkan pengguna untuk membeli produk digital atau membayar tagihan ke beberapa penerbit atau penagih dari daftar mitra onboard yang kami miliki."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Biller Inquiry digunakan untuk melakukan pengecekan detail transaksi pembelian PPOB sebelum dilakukan pembayaran."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Biller Payment digunakan untuk melakukan pembayaran produk PPOB dengan menggunakan sumber dana dari wallet KasPro."])}
        }
      }
    },
    "5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengiriman Uang"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produk Pengiriman Uang yang dapat disematkan ke aplikasi konsumen dan perusahaan yang memungkinkan pengguna untuk mentransfer dana dari aplikasi mereka secara tunai, tunai ke rekening, melalui rekening, dan antar rekening."])}
    },
    "6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabungan Emas"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tambahkan nilai bagi pelanggan Anda dengan menyematkan Produk Tabungan Emas kami ke dalam aplikasi asli Anda. API ini akan meningkatkan pengalaman pelanggan dengan memperoleh pendapatan dari setiap transaksi yang ada."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Registration digunakan untuk mengaktifkan wallet GoldPro."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Check Out Gram digunakan untuk melakukan pengecekan harga beli emas berdasarkan gram."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Buy Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Buy Gram digunakan untuk melakukan pembelian emas berdasarkan gram."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Rupiah"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Check Out Rupiah digunakan untuk melakukan pengecekan harga beli emas berdasarkan rupiah."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Buy Rupiah"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Buy Rupiah digunakan untuk pembelian emas berdasarkan rupiah."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Sell Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Check Out Sell Gram digunakan untuk melakukan pengecekan harga jual emas berdasarkan gram."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Sell Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Sell Gram digunakan untuk melakukan penjualan emas berdasarkan gram."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Get Chart"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Get Chart digunakan untuk menampilkan harga jual dan harga beli emas berdasarkan waktu request API."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Get Wilayah"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Get Wilayah digunakan untuk mengecek tempat pengambilan emas fisik terdekat."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Withdraw Address"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Withdraw Address digunakan untuk menampilkan alamat pengambilan emas fisik berdasarkan alamat yang telah dimasukkan oleh user."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Get Address"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" API GoldPro Get Address digunakan untuk mengecek tempat pengambilan emas fisik terdekat."])}
        },
        "11": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Withdraw Confirmation"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" API GoldPro Withdraw Confirmation dilakukan untuk melakukan konfirmasi transaksi penarikan emas."])}
        },
        "12": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Transfer Check Out"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Transfer Check Out digunakan untuk melakukan transfer emas ke sesama pengguna GoldPro."])}
        },
        "13": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Transfer Confirmation"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Transfer Confirmation digunakan untuk melakukan informasi transaksi transfer emas ke sesama pengguna GoldPro."])}
        }
      }
    },
    "7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nomor Kartu Virtual (VCN)"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sematkan VCN kami untuk menghasilkan faktur digital kepada pelanggan Anda dan menerima pembayaran melalui transaksi daring."])}
    },
    "8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beli Sekarang Bayar Nanti (BNPL)"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk meningkatkan pengalaman penjualan dan pelanggan Anda, penjual online atau offline dapat menyematkan BNPL ini ke modul pembayaran Anda untuk memberikan opsi pembayaran bagi pelanggan Anda."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS PayLater"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Paylater digunakan untuk melakukan pembayaran transaksi QRIS dengan menggunakan saldo deposit partner sebagai sumber dana."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment to Merchant"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API QRIS Payment to merchant digunakan untuk melakukan pembayaran QRIS dengan sumber dana wallet kaspro ke wallet merchant."])}
        }
      }
    },
    "9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasProBank"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisasi Koperasi Tradisional – melalui platform yang aman dan lancar serta rangkaian teknologi yang ada."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get balance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Get Balance digunakan untuk menampilkan saldo yang tersedia di akun rekening KasProbank."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get bank list"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Get Bank List digunakan untuk menampilkan daftar bank sebagai tujuan transfer."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API is used to display transfer transaction details prior to confirmation"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirm transfer API is used to confirm transfer transactions."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to view transaction history based on transaction time."])}
        }
      }
    },
    "10": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-KYC"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikasi pelanggan baru Anda secara aman sesuai dengan peraturan dengan menyematkan modul e-KYC kami ke dalam aplikasi Anda."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk user melakukan upgrade menggunakan sistem OCR, identifikasi ID user dengan mengirimkan data foto KTP, foto Selfie, dan foto selfie memegang KTP."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with Passport and SIM"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ini digunakan untuk user melakukan upgrade menggunakan sistem OCR, identifikasi ID (Passport atau SIM) user dengan mengirimkan data foto Passport atau SIM, foto Selfie, dan foto selfie memegang Passport atau SIM."])}
        }
      }
    },
    "11": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setor & Tarik Tunai"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank atau LJK dapat langsung memanfaatkan jaringan gerai ritel onboard kami sebagai agen perbankan digital berlisensi untuk menawarkan poin setor dan tarik tunai yang dapat dinikmati oleh segmen tunai terutama mereka dari usaha mikro kecil dan menengah."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from Bank"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro menggunakan VA transfer bank."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash in From Modern Trade "])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan pengguna untuk mengisi ulang saldo akun Kaspro melalui modern trade [Alfamart & Indomaret]."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Bank"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Transfer digunakan untuk melakukan transaksi transfer dari wallet user ke rekening bank."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank List"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Bank List digunakan untuk menampilkan daftar bank untuk tujuan transfer. "])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Bank Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Transfer to Bank Inquiry digunakan untuk melakukan pengecekan detail transaksi transfer ke bank."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Payment Token"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Generate Payment Token digunakan untuk generate token untuk melakukan penarikan tunai di modern merchant."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Danamon Cash Out"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API KasPro Danamon Cash Out digunakan untuk generate token untuk melakukan penarikan tunai di atm bank Danamon."])}
        }
      }
    }
  },
  "view_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Journey"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirim"])},
  "hero_video": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia Bios:"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami adalah Perusahaan Embeddable Fintech as a Service (EFaaS) Pertama di Indonesia!"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengaktifkan layanan keuangan tertentu dengan menggabungkan API kami pada aplikasi asli Anda untuk memberikan pengalaman pengguna yang seamless bagi user Anda"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menjadikan API layanan keuangan tertentu untuk digabungkan dan digunakan ke aplikasi asli Anda."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami memberikan pengalaman yang mulus bagi pengguna Anda, dan API kami dapat disematkan ke dalam perjalanan transaksi Anda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemitraan adalah sifat dari strategi pemasaran produk kami sebagai Penyedia EFaaS dalam mengembangkan portofolio kami yang siap digunakan untuk para mitra."])}
  },
  "user_journey_detail": {
    "facility_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manajemen Fasilitas"])},
    "facility_management_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API untuk layanan yang menggunakan fasilitas seperti apartemen dan perkantoran."])},
    "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started with Digiasia Bios"])},
    "get_started_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our team to learn more about what we can help you build or create an account to get started right away."])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom nama kosong"])},
  "company_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom perusahaan kosong"])},
  "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom email kosong"])},
  "phone_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom telepon kosong"])},
  "email_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format email tidak sesuai"])},
  "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolom pesan kosong"])},
  "empty_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten Kosong"])},
  "investor_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relasi Investor"])},
  "investor_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentasi Investor"])},
  "readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baca Selengkapnya"])}
}