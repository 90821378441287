<template>
  <div id="achievement" class="section achievement">
    <img id="ornamen-2" src="@/assets/templates/Ornamen_Bulat_02.png" />
    <div class="container">
      <div>
        <div class="title-ach">
          <h3>
            <hr class="line-title my-0 mx-auto" />
            {{ t.t("achievement", lang) }}
          </h3>
        </div>
        <div class="ach-contains">
          <div>
            <div
              @click="selected($event, '2017')"
              ref="ref2017"
              class="actived"
            >
              2017
            </div>
            <div @click="selected($event, '2018')" ref="ref2018">2018</div>
            <div @click="selected($event, '2019')" ref="ref2019">2019</div>
            <div @click="selected($event, '2020')" ref="ref2020">2020</div>
            <div @click="selected($event, '2021')" ref="ref2021">2021</div>
            <div @click="selected($event, '2022')" ref="ref2022">2022</div>
          </div>
          <div>
            <Journey :image="image" :year="year" :lists="lists" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import Journey from "@/components/portal/PageAchievementJourney";

const tlang = localStorage.getItem("lang");

export default {
  name: "Achievement",
  components: {
    Journey,
  },
  methods: {
    selected(event, thn) {
      for (var i = 0; i < event.target.parentElement.children.length; i++) {
        event.target.parentElement.children[i].className = "";
      }
      event.target.className = "actived";

      this.image = `OurJourney${thn}.png`;
      this.year = thn;
      this.lists = this.t.t(`achievement-details.${thn}`, this.lang);

      if (event.isTrusted == false) {
        this.clickNext(thn);
      }
    },
    clickNext(value) {
      switch (value) {
        case "2017":
          setTimeout(() => this.$refs.ref2018.click(), this.timeout);
          break;
        case "2018":
          setTimeout(() => this.$refs.ref2019.click(), this.timeout);
          break;
        case "2019":
          setTimeout(() => this.$refs.ref2020.click(), this.timeout);
          break;
        case "2020":
          setTimeout(() => this.$refs.ref2021.click(), this.timeout);
          break;
        case "2021":
          setTimeout(() => this.$refs.ref2022.click(), this.timeout);
          break;
        case "2022":
          setTimeout(() => this.$refs.ref2017.click(), this.timeout);
          break;
      }
    },
  },
  props: {
    msg: String,
  },
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      image: "",
      year: "",
      lists: "",
      timeout: 10000,
    };
  },
  beforeMount() {
    this.image = "OurJourney2017.png";
    this.year = "2017";
    this.lists = this.t.t("achievement-details.2017", this.lang);
  },
  mounted() {
    setTimeout(() => this.$refs.ref2017.click(), this.timeout);
  },
};
</script>

<style scoped>
.actived {
  background-color: #8a2785;
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  font-family: SF Pro Display Bold;
  margin: 0 !important;
  padding: 2px 15px 0 15px;
  position: relative;
  right: 0;
  left: 0;
  width: 76px;
}
.actived::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #8a2785;
  clear: both;
  transform: rotate(-90deg);
}
</style>
