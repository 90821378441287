<template>
  <div class="prl-5 mt-50 text-centered relative">
    <img
      class="present_ornamen_1"
      src="@/assets/templates/Ornamen_Bulat_01.png"
    />
    <hr class="bg-[#502052] rounded-xl h-2 w-50 mb-1rem border-transparent" />
    <div class="title-leadership">
      Leadership Team with Track Record Across <br />
      Financial Services & Technology
    </div>
    <div class="leadership">
      <div class="subtitle-leadership">Leadership Team</div>
      <div class="leader_boards">
        <template v-for="(dt, index) in dtLeadTeam" :key="index">
          <div>
            <img :src="linkImg(dt.picture)" />
            <div class="desc-mgmt">
              <div>{{ dt.name }}</div>
              <div>{{ dt.position }}</div>
              <div>
                <span v-for="(dm, idx) in dt.logo" :key="idx">
                  <img :src="linkImg(dm)" />
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="boards">
      <img
        class="present_ornamen_2"
        src="@/assets/templates/Ornamen_Bulat_02.png"
      />
      <div class="subtitle-leadership">Board Members</div>
      <div class="leader_boards">
        <template v-for="(dt, index) in dtBoardMembers" :key="index">
          <div>
            <img :src="linkImg(dt.picture)" />
            <div class="desc-mgmt" v-if="dt.picture != ''">
              <div>{{ dt.name }}</div>
              <div>{{ dt.position }}</div>
              <div>
                <span v-for="(dm, idx) in dt.logo" :key="idx">
                  <img :src="linkImg(dm)" />
                </span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <img
      class="present_ornamen_3"
      src="@/assets/templates/Ornamen_Bulat_01.png"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    dtLeadTeam: [
      {
        picture: "DAB-Profile-Prashant.png",
        name: "Prashant Gokarn",
        position: "Co-CEO",
        logo: ["logo-indosat-ooredoo.png", "logo-reliance.png"],
      },
      {
        picture: "DAB-Profile-Alexander.png",
        name: "Alexander Rusli",
        position: "Co-CEO",
        logo: ["logo-indosat-ooredoo.png", "logo-northstar.png"],
      },
      {
        picture: "DAB-Profile-Subir.png",
        name: "Subir Lohani",
        position: "CFO and Chief Strategy Officer",
        logo: ["rocket_internet.png", "logo-barclays.png"],
      },
    ],
    dtBoardMembers: [
      {
        picture: "DAB-Profile-Prashant.png",
        name: "Prashant Gokarn",
        position: "Co-CEO",
        logo: ["logo-indosat-ooredoo.png", "logo-reliance.png"],
      },
      {
        picture: "DAB-Profile-Alexander.png",
        name: "Alexander Rusli",
        position: "Co-CEO",
        logo: ["logo-indosat-ooredoo.png", "logo-northstar.png"],
      },
      {
        picture: "DAB-Profile-Bhargav-Marepally.png",
        name: "Bhargav Marepally",
        position: "CEO StoneBridge",
        logo: ["logo-indosat-ooredoo.png", "logo-GSS.png", "logo-forbes.png"],
      },
      {
        picture: "DAB-Profile-Prabhu-Antony.png",
        name: "Prabhu Antony",
        position: "President & CFO StoneBridge",
        logo: [
          "logo-stonebridge.png",
          "logo-settlucas.png",
          "logo-linus-ventures.png",
        ],
      },
      {
        picture: "DAB-Profile-Ken-Sommer.png",
        name: "Ken Sommer",
        position: "Board Member, Ex-CEO & CFO-Visa",
        logo: ["logo-visa.png", "logo-mercedespay.png", "logo-citigroup.png"],
      },
      {
        picture: "DAB-Profile-Rudiantara.png",
        name: "Rudiantara",
        position: "Board Member",
        logo: [],
      },
      {
        picture: "",
        name: "",
        position: "",
        logo: [],
      },
      {
        picture: "DAB-Profile-Andreas-Gregori.png",
        name: "Andreas Gregori",
        position: "Board Member",
        logo: [],
      },
      {
        picture: "",
        name: "",
        position: "",
        logo: [],
      },
    ],
  }),
  methods: {
    linkImg(img) {
      if (img !== "") {
        return require(`@/assets/leadership_boards/${img}`);
      } else {
        return "";
      }
    },
  },
};
</script>
