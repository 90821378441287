export default {
  "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "achievement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Journey"])},
  "ourvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Values"])},
  "newsroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Room"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "contact-investors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor Contact"])},
  "contact-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information, you can directly fill the form and our team will contact you soon!"])},
  "slide-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are The First Fintech as a Service <br />(FaaS) Company in Indonesia!"])},
  "why-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Digiasia Bios"])},
  "vision-mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROW WITH <br />DIGIASIA"])},
  "aboutdesc": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia Bios, was founded in Jakarta, in November 2017."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia’s Vision is enabling financial transactions across all customer segments. With Mission To support very aspect of our partners’ financial transformation to the digital era with low cost mass market diverse financial products."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia’s strategy is to acquire and operationally consolidate digital, traditional distribution and logistics ecosystems through low cost payment rails using an embedded finance approach."])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia offers its financial services including closed and open loop wallets, QR payments, cash based and digital remittances, merchant operated ATM/branch banking functionality and card issuance through a set of open access APIs; to be consumed in the mobile app and web interfaces of our partner customers."])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rather than invest precious financial capital, time and human resources, our partner customers have their branded or white-label fintech services live and operational within weeks."])}
  },
  "about-type": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia offers its financial services including closed and open loop wallets, QR payments, cash based and digital remittances, P2P lending, corporate cards, merchant operated ATM/branch banking functionality and card issuance through a set of open access APIs. These APIs are integrated in the mobile app and web interfaces of our partner customers. We assist our partner customers to get the relevant regulatory approvals and they have their branded or white-label fintech services live and operational within weeks."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DigiAsia offers its partner customers, including banks and non-banks, access to bank products such as virtual accounts, prepaid cards, forex transfers, card issuance covering prepaid and debit/credit cards, cardless withdrawal at ATMs etc. through white-label APIs."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given the deep integration on various fintech products, DigiAsia enables our partner customers access to leading edge products on both in-house products of loyalty program management, deep analytics for revenue optimization etc. also through our technology partners including MasterCard."])}
  },
  "achievement-details": {
    "2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Founded November 2017</li></ul>"])},
    "2018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Launched as a B2C Consumer Wallet (PayPro)</li><li class='list-disc'>Pivoted to B2B strategy to target profitable multi sector growth (strategy validated during Covid)</li><li class='list-disc'><b>First B2B contract - Asian Games wallet (first 'non cash' Asian Games)</b></li><li class='list-disc'>Acquired remittances business with approvals for both domestic and cross-border</li><li class='list-disc'>P2P lending platform launched (KreditPro)</li></ul>"])},
    "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Grew the B2B business initially in Telco, F&B, FMCG</li><li class='list-disc'><b>Launched digital bank (KasPro Bank) for high value transactions and test BaaS</b></li></ul>"])},
    "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>MasterCard strategic investment</li><li class='list-disc'><b>Branchless Banking - DigiBos acquired agent bank licence - SMEs as branches / ATMs</b></li></ul>"])},
    "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Key growth in Govt companies - railways, airlines, pensions, health insurance companies etc.</li><li class='list-disc'><b>Launch of virtual cards (VCN) through Mastercard partnership</b></li></ul>"])},
    "2022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul><li class='list-disc'>Prepaid card and Shariah with Mastercard as global partner</li><li class='list-disc'><b>Acquired stake in Bank Index to scale Banking-as-a-Service model</b></li><li class='list-disc'><b>Acquired stake in Matchmove, largest card issuance company in SEA</b></li><li class='list-disc'><b>Develop KasPro Syariah Payment System, and Syariah Payment Gateway (coming soon)</b></li><li class='list-disc'><b>Launching Syariah wallet and syariah financing with Nahdatul Ulama (NU)</b></li></ul>"])}
  },
  "vision": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proven & Credible"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our business partners have successfully digitized their business financial needs by building an ecosystem with Digiasia. With the commitment of an experienced team in the fintech industry, we have become the solution for partner business success."])}
  },
  "mission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growing"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together with business partners, we prioritize the growth and development of partners' businesses to achieve mutual benefits and success."])}
  },
  "why1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have the most complete licenses in financial services including Electronic Money, P2P Lending, Remittances, and Branchless Banking Services."])}
  },
  "why2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impact"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia’s impact on business partners in improving their financial services, with ecosystem connections and the largest LKD touch points throughout Indonesia"])}
  },
  "why3": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions for partners through integration using APIs, thereby accelerating the fulfillment of business partners' needs to connect with our services on partner-owned applications and platforms."])}
  },
  "why4": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy Access"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straightforward and accessible for business partners to integrate with our various services according to business partner needs"])}
  },
  "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "we diff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["what make us different?"])},
  "we diff desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our unique B2B2C approach always assists our business partners to digitize their operation & customer base, hence developing fully digital and cashless services we provide, we are a financial technology company with complete licenses in the financial service industry. We have the proper licenses for Digital Payment, P2P Lending and Remittance under 3 legitimate brands of Digiasia: KasPro, KreditPro & RemitPro."])},
  "team title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["management team of digiasia"])},
  "board-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia-Board Member & Advisors"])},
  "product-and-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products and Services"])},
  "product": {
    "product1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering co-branding E-money services (wallet, QR payment) for payment needs with full approval and supervision by Bank Indonesia."])},
    "product2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Lending services that is licensed and supervised by the Financial Service Authority (OJK)."])},
    "product3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remittance services through access of our active supply chain SME merchants that have been registered and supervised by Bank Indonesia."])},
    "product4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering branchless banking services through our active supply chain merchants using our Digital Money Services (LKD) license from Bank Indonesia."])}
  },
  "solutions-offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of Our Solutions"])},
  "solution1": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitization of Supply Chain Management"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To breach the gap between demand and supply, reduce cost of cash management."])}
  },
  "solution2": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Further Growth For MSMEs"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extending micro loan to MSMEs."])}
  },
  "solution3": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serve The Unbanked Customer"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open-loop Prepaid Card that can be used for online payments, opens up e-commerce efficiencies to the unbanked."])}
  },
  "solution4": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline Payment Presence"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expanding offline payment points to extending the growth of e- money adoption in Indonesia to smaller town and rural Indonesia."])}
  },
  "solution5": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperative Bank Transformation"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitization of Traditional Cooperatives – through secure and seamless platform and technology tie-up."])}
  },
  "solution6": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiency in Money Transfers"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serving large market in inbound and outbound remittance."])}
  },
  "solution7": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Micro Payment Through Telco Partnership"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ride on the shift in telco industry from data offering focus to Content and experience focus."])}
  },
  "solution8": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling Branchless Banking"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With LKD License we create outlet to provide banking and financial services."])}
  },
  "footer": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia Bios is the first Fintech-as-a-Service (Faas) Company in Indonesia. Committed to respond all kinds of challenges related to the financial sphere, and Digiasia Bios has four licenses through its affiliates: Digital Payment (KasPro), P2P Lending (KreditPro), Remittances (RemitPro), and Digital Financial Services (DigiBos)."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Each of Digiasia Bios products is registered and licensed by:"])}
  },
  "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "our slide text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CORE VALUES <br> DIGIASIA BIOS"])},
  "asia spirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asia Spirit"])},
  "asia-spirit-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guiding all employee interactions through ASIA SPIRIT is the core value of DigiAsia Bios. The key tenets are Be Agile, Speak Up, Display Integrity, and Team Work. This acts as a basic guideline for the entire DigiAsia Bios family in supporting the company's credibility and commitment to keep moving forward as we bring more and more partners on board and expand our products suite."])},
  "agile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think fast and adapt to various forms of change."])},
  "speakup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convey ideas, criticism clearly, positively."])},
  "integrity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Think positively, be consistent and reliable in actions."])},
  "teamwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared success or failure driven by the same goal."])},
  "finding-jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Your Dream Job at Digiasia Bios"])},
  "more-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Info"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Less"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "news_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Details"])},
  "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Careers"])},
  "career_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career Details"])},
  "media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
  "media_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Details"])},
  "coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage"])},
  "coverage_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coverage Details"])},
  "instagram_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram Details"])},
  "youtube_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube Details"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "welcome-dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome in DAB dashboard"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "profile_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Detail"])},
  "company_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Profile"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confirm-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation Password"])},
  "password-notmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password not match"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure?"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "filename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filename"])},
  "jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "new_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Admin"])},
  "partnership_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARTNERS who have collaborated and entrusted their business with DigiAsia Bios Group."])},
  "partnership_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALUE of current transactions that have been processed from various Partners."])},
  "partnership_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaching out to people from all walks of life in Indonesia through various financial services offered through our FaaS partner customers"])},
  "partnership_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering escrow and embedded wallets into telco, FMCG, construction, agri and technology supply chains with close to a million active merchants."])},
  "tPartner1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USD 1Bn+"])},
  "tPartner2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14m+"])},
  "tPartner3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["930k SME merchants"])},
  "what_they_say": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What They Says"])},
  "testi_person": {
    "p1": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_PaperID.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lifiana"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Development"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper.id"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Paper.id is a B2B payment solution &amp; integrated invoice management that focuses on helping business people to digitize their end-to-end business transactions.</p><p>We have collaborated with DigiAsia Bios so that the cash flow for our MSMEs is smoother and on our fully digitized order management platform. We are looking to further expand our collaboration, not only in the financing sector but also in other fields so that MSMEs in Indonesia can feel the benefits of the Paper.id and KreditPro facilities.</p>"])}
    },
    "p2": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_Stefanus.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stefanus Harjono"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT Surya Parama Distribusindo"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>PT Surya Parama Distribution is engaged in general trading and distribution of sales of food, beverages and basic necessities</p><p>Thank you for helping our finances through KreditPro, our financial turnover has become smoother and the purchase of the goods has been maximized. What&#39;s special about KreditPro is that it provides financing without asset collateral, low interest, and the easy application process to provide solutions for our cash flow.</p>"])}
    },
    "p3": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_Akulaku.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malvin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Development"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AkuLaku"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Akulaku is a digital banking and finance platform that helps meet financial needs in using banking and funding services.</p><p>We are partnering with KasPro for QRIS payment system services that make it easier for AkuLaku customers to transact. The collaboration process went well, KasPro always responded quickly and responsibly, thus facilitating communication between the two parties. Hopefully, this cooperative relationship will continue and be better established in the future.</p>"])}
    },
    "p4": {
      "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimoni_David-TRON.png"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["David Santoso"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
      "partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tron"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tron is a company engaged in the field of public transportation. KasPro helps us in digitizing these public transportation payments. This is supported by an API integration process with KasPro which, apart from being easy and fast, can also be adapted to our business needs. Hopefully, this collaboration will continue to grow in the future."])}
    }
  },
  "dab_icon": {
    "payment_online": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction / Online Payment"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembayaran Digital untuk Payment Point Online Bank (PPOB), transaksi perbankan, dan pembayaran online."])}
    },
    "msme": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Micro Payment Through Telco Partnership and MSME Growth"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with supply chain ecosystems, which specialize in productive loans. Also, to help extend the micro loans to MSMEs, and to take the burden of payment processing and compliance away."])}
    },
    "mor": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchant On Record"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase business value while expanding customer reach, so it will advance the transactions through online payments, also the use of credit and debit cards will be increasingly popular."])}
    },
    "branchless_banking": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline Payment Presence and Enabling Branchless Banking"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expanding the offline payment points to increase the use of e-money (wallet), PPOB services, remittance, QRIS payments and gold savings in small cities and rural areas of Indonesia. With the LKD license, we create outlets to provide banking and financial services."])}
    },
    "money_Transfer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficiency in Money Transfers"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both sender / receiver can visit our web services to send / receive money to your loved ones, or to your business partners. This can also help you in handling several digital payments."])}
    },
    "kaspro": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperative Bank Transformation (KasPro Bank)"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalization of traditional cooperatives through a platform that is safe, hassle-free and technologically bound by collaborating with trusted banks."])}
    },
    "cash_management": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Management"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital financial management that can facilitate monitoring, reconcile and settlement systems, and also reduce transaction costs."])}
    },
    "unbanked_customer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serve The Unbanked Customer"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing convenience in banking / financial services with digital platforms such as e-wallet and other services."])}
    }
  },
  "linkedin_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since 2010 when online-based mass transportation first arrived in Indonesia, users of this mode of transportation have increased rapidly because its presence has made it easier for people to obtain transportation services more quickly and efficiently. This has led to competition in price, value or quality, trust in providers, and alternative choices that make consumers more selective in making choices. The large area in Indonesia makes Indonesian transportation players also strive to make various service innovations."])},
  "embedded_finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded Finance"])},
  "our_solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Solution"])},
  "user_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Journey"])},
  "open_api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Api"])},
  "efaas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded Finance as a Service"])},
  "efassdesc": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our suite of various fintech licenses, Digiasia Bios provides enterprise partners a selected financial service pertinent such as B2B Payment, B2C Payment, Remittance, POS Lending, Buy Now Pay Later, Trade Financing, Cash in or Cash Out, etc."])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are serving the enterprise segments from various verticals by enabling them to consume our EFaaS APIs to be embedded into their native transaction journey to close value chain transactions."])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hence, we define partners based on their roles in Digiasia Bios's stack:"])},
    "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Southbound Partners, enterprises collaborating with Digiasia Bios as enablers by allowing us to consume their APIs in our EFaaS use cases."])},
    "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Northbound Partners, enterprises collaborating with Digiasia Bios as the user of EFaaS by consuming our APIs to provide service to their customers"])}
  },
  "usecases": {
    "0": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Management"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API for services within the management of facilities (apartment, office)."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Wallet on Partner app"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate KasPro as the main wallet in partner applications to enable users to process transactions using Kaspro balance as a source of fund."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners to register KasPro through partner applications."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OTP Request API is used to trigger an OTP for the registered Mobile Number. The OTP will be sent to the registered mobile number via WhatsApp or SMS (if the registered mobile number is not registered on WhatsApp."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Validate OTP API is used to validate the OTP code that has been input by the user."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for users to upgrade using the OCR system, identify user ID by sending KTP photo data, selfie photos, and selfie photos holding KTP."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners requesting customer details such as name, account type, account status, customer type, email, telephone number, KYC status, total balance."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-Up [transfer VA and cash-in via modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable users to top up KasPro account balances using VA transfers and modern trade [Alfamart & Indomaret]."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out [Bank Transfer and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cash Out API is used to make transfer transactions from a user's wallet to a bank account or withdraw cash via modern trade."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The P2P transaction API is used to make transfers to fellow KasPro user wallets."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Requirement"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber wallet & Corporate Wallet"])}
        }
      }
    },
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Bank Ecosystem Supply Chain"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API bundle for Business Ecosystem Solution that aligns Principals, Distributors, Wholesalers, Retailers into one robust ecosystem & securing risks under one roof."])}
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail Chain"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API bundle for retail chain business contains Customer onboarding, etc."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co branding wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners to register KasPro through partner applications."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Premium Member"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for users to upgrade using the OCR system, identify user ID by sending KTP photo data, selfie photos, and selfie photos holding KTP"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners requesting customer details such as name, account type, account status, customer type, email, telephone number, KYC status, total balance."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Up"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable users to top up KasPro account balances using VA transfers and modern trade [Alfamart & Indomaret]."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make payment transactions within the application."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Balance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The API transfer balance is used to make transfers from wallet balances."])}
        }
      }
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial technology lending that gives lending, instalments without credit cards, cell phone credit and PayLater services."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate KasPro as the main wallet in partner applications to enable users to process transactions using KasPro balance as a source of funds."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners to register KasPro through partner applications."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for users to upgrade using the OCR system, identify user ID by sending KTP photo data, selfie photos, and selfie photos holding KTP."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Request API is used to trigger OTP for registered Mobile Numbers. The OTP will be sent to the registered mobile number via WhatsApp or SMS (if the registered mobile number is not registered on WhatsApp)"])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Validate OTP API is used to validate the OTP code that has been input by the user."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-up [transfer via VA and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable users to top up Kaspro account balances using VA transfers and modern trade [Alfamart & Indomaret]."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS PayLater"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Paylater API is used to make payments for QRIS transactions using partner deposit balances as a source of funds."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet requirement: Corporate Wallet & Subscriber Wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Service"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API for delivery services that provide customers onboarding, payment, and wallet services."])},
      "api": {
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to process in-app transaction payments using wallet balances."])}
        }
      }
    },
    "5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile-based transporting marketplace that allows users to find and book for taxi bike, taxi, and on-demand delivery."])},
      "api": {
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners requesting customer details such as name, account type, account status, customer type, email, telephone number, KYC status, total balance."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-up User wallet [transfer VA and cash-in via modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable users to top up KasPro account balances using VA transfers and modern trade [Alfamart & Indomaret]."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit/Top Up Driver wallet [transfer VA and cash-in via modern trade] using Prefix"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate the driver's wallet to top up KasPro account balances using VA transfers and modern trade [Alfamart & Indomaret] with a special prefix."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash-out user & driver [Bank transfer and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cash Out API is used to make transfer transactions from a user's wallet to a bank account or withdraw cash via modern trade."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Payment (Ride Hailing Payment)"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to process in-app transaction payments using wallet balances"])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips Ride Hailing"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to process tip payments to drivers using a wallet balance."])}
        },
        "11": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The P2P transaction API is used to make transfers to fellow KasPro user wallets."])}
        },
        "12": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions."])}
        },
        "13": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet requirement: Corporate Wallet, Escrow Wallet, Merchant Wallet, Subscriber Wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Management Product"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers can explore & embedded each or all of the list of our biller products."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Biller Inquiry API is used to check PPOB purchase transaction details before making a payment. "])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Biller Payment API is used to make payments for PPOB products using a source of funds from KasPro wallet."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airtime & Data Package"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepaid & Postpaid Electricity Bill : PLN"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Bill : PDAM"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Bill"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Finance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-money"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance : BPJS"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property taxes : PBB"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        },
        "11": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games Voucher"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        }
      }
    },
    "7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Merchant Aggregator"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API bundle for businesses that need Acquiring merchants and provide QRIS feature requirements."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Merchant Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Merchant Registration API is used for partners to register merchant partners as QRIS KasPro merchants via API."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic QR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Dynamic QR API is used for KasPro's dynamic QR generation partner."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static QR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Static QR API is used for KasPro's static QR generating partner."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment Acquirer Notification"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Payment Acquirer Notification API is used for partners to receive QRIS payment transaction notifications."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Refund"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Refund API is used to get notifications from KasPro for QRIS Transaction refunds."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP for Transaction Report & Static QRIS Image Transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SFTP API for Transaction Report & Static QRIS Image Transfer is used for KasPro & Partners to send and receive QRIS transaction reports and static QRIS images via SFTP."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback Transaction API is used to enable KasPro to provide Callback transactions with details such as Issuer Name, Customer Data (Phone Name, Details) Details of Paid Nominal & MDR Partner."])}
        }
      }
    },
    "8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrated Cash Management"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tailor-made cash management for corporate customers aiming to enhance their corporate efficiency."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro wallet"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate KasPro as the main wallet in partner applications to enable users to process transactions using KasPro balance as a source of funds."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cash Out API is used to make cash withdrawals from the user’s wallet balance"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Bank portal partner"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Stage"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual account KasPro Bank"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Yet Available"])}
        }
      }
    },
    "9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Linkage"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkage accounts and system integration in your application or business, electronic services can already be used."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate KasPro as the main wallet in partner applications to enable users to process transactions using KasPro balance as a source of funds."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Premium (OCR KYC)"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Stage"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Request API is used to trigger OTP for registered Mobile Numbers. The OTP will be sent to the registered mobile number via WhatsApp or SMS (if the registered mobile number is not registered on WhatsApp)"])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Validate OTP API is used to validate the OTP code that has been input by the user."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Up / Isi Saldo KasPro"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions"])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer API is used to make transfer transactions from a user’s wallet to a bank account."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cash Out API is used to make cash withdrawals from the user’s wallet balance."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to view transaction history based on the transaction time."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI/UX (design & structure) on partner side"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI/UX design is customized based on the partner application."])}
        }
      }
    },
    "10": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Bank"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitization of Traditional Cooperatives – through secure and seamless platform and technology tie-up."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get balance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Get Balance API is used to display available balances in KasProBank accounts."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get bank list"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Get Bank List API is used to display a list of banks as transfer destinations."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Inquiry API is used to display transfer transaction details prior to confirmation."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirm transfer API is used to confirm transfer transactions."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to view transaction history based on transaction time."])}
        }
      }
    }
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can you build?"])},
    "0": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Wallet"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can embed our digital wallet into your native apps with account linkage to enhance your customer experience, with a seamless transaction journey to improve loyalty on your apps. At the same time, we take care of regulatory compliance and technology provision."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Wallet on Partner app"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrate KasPro as the main wallet in partner applications to enable users to process transactions using Kaspro balance as a source of fund."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners to register KasPro through partner applications."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OTP Request API is used to trigger an OTP for the registered Mobile Number. The OTP will be sent to the registered mobile number via WhatsApp or SMS (if the registered mobile number is not registered on WhatsApp."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate OTP"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Validate OTP API is used to validate the OTP code that has been input by the user."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for users to upgrade using the OCR system, identify user ID by sending KTP photo data, selfie photos, and selfie photos holding KTP."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for partners requesting customer details such as name, account type, account status, customer type, email, telephone number, KYC status, total balance."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-Up [transfer VA and cash-in via modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable users to top up KasPro account balances using VA transfers and modern trade [Alfamart & Indomaret]."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Out [Bank Transfer and modern trade]"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Cash Out API is used to make transfer transactions from a user's wallet to a bank account or withdraw cash via modern trade."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The P2P transaction API is used to make transfers to fellow KasPro user wallets."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment to Merchant Transaction"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Payment to merchant transaction API is used to make payments using the KasPro wallet as a source of funds to the merchant wallet."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Requirement"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber wallet & Corporate Wallet"])}
        }
      }
    },
    "1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embed QRIS Payment as a Service, enables QRIS payment for your customers or POS applications."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to make QRIS payment transactions."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment to Merchant"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Payment to merchant API is used to make QRIS payments using the Kaspro wallet as a source of funds to the merchant wallet."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Check Status"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Check Status API is used for partner requests for QRIS transaction status."])}
        }
      }
    },
    "2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Management"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedding our Cash Management system in your ERP system, allows you to create a closed-loop cash management system for your value chain stakeholders."])}
    },
    "3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B Loan"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our B2B Loan product is designated into micro, small, and medium enterprises, embeddable into digital apps provided by tech platform providers. This will enhance the GTV of the platform owners and improve the user experience of their B2B users."])}
    },
    "4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Product & Billers"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedding our Digital Product & Billers into your native app allows users to purchase digital products or pay bills to multiple issuers or billers from our list of onboarded partners."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Biller Inquiry API is used to check PPOB purchase transaction details before making a payment."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biller Payment"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Biller Payment API is used to make payments for PPOB products using a source of funds from KasPro wallet."])}
        }
      }
    },
    "5": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remittance"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Remittance Product is embeddable in both consumer and enterprise apps enabling users to transfer funds from their application cash-to-cash, cash-to-account, account-to-cash, and account-to-account."])}
    },
    "6": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold Saving"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When embedding our Gold saving product into your native app, you will improve and expand your customer experience, generating revenue from each transaction."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Registration"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Registration API is used to activate the GoldPro Wallet."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Check Out Gram API is used to check the purchase price of gold by gram."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Buy Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Buy Gram API is used to purchase gold by gram."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Rupiah"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Rupiah API is used to check the purchase price of gold based on Rupiah."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Buy Rupiah"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Buy Rupiah is used to purchase gold based on Rupiah."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Check Out Sell Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API GoldPro Check Out Sell Gram is used to check the selling price of gold by gram."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Sell Gram"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Sell Gram API is used to sell gold by gram."])}
        },
        "7": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Get Chart"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Get Chart API is used to display the selling and buying price of gold based on the API request time."])}
        },
        "8": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Get Wilayah"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Get Region API is used to check the nearest physical gold collection point."])}
        },
        "9": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Withdraw Address"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Withdraw Address API is used to display the physical gold withdrawal address based on the address input by the user."])}
        },
        "10": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Get Address"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Get Address API is used to check the nearest physical gold collection point."])}
        },
        "11": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Withdraw Confirmation"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Withdraw Confirmation API is used to confirm gold withdrawal transactions."])}
        },
        "12": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Transfer Check Out"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Transfer Check Out API is used to transfer gold to fellow GoldPro users."])}
        },
        "13": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoldPro Transfer Confirmation"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GoldPro Transfer Confirmation API is used to perform gold transfer transaction information among fellow GoldPro users."])}
        }
      }
    },
    "7": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCN Virtual Card Number"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedding our VCN allows you to generate a digital invoice for customers to accept payment over internet transactions."])}
    },
    "8": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy Now Pay Later (BNPL)"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you embed our BNPL payment module, to your shopping cart feature, this improves your sales and customer experience by providing online or offline sellers with the option of BNPL."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS PayLater"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Paylater API is used to make payments for QRIS transactions using partner deposit balances as a source of funds."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QRIS Payment to Merchant"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QRIS Payment to merchant API is used to make QRIS payments using the KasPro wallet as a source of funds to the merchant wallet."])}
        }
      }
    },
    "9": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Bank"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banks or FSI can instantly utilize our networks of retail outlets onboarded as licensed digital banking agents to offer cash-in & cash-out points to serve the cash basis segments, especially micro small, and medium enterprises."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get balance"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Get Balance API is used to display available balances in KasProBank accounts."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get bank list"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Get Bank List API is used to display a list of banks as transfer destinations."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Inquiry API is used to display transfer transaction details prior to confirmation"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm transfer"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The confirm transfer API is used to confirm transfer transactions."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used to view transaction history based on transaction time."])}
        }
      }
    },
    "10": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-KYC"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onboard any new customers securely in compliance with regulation, when embedding our e-KYC module into your application."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with OCR"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for users to upgrade by using OCR system identify user ID by sending KTP photo data, selfie photos, and selfie photos holding KTP."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Customer Account with Passport and SIM"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This API is used for users to upgrade using the OCR system, identify the user's ID (Passport or Driving License) by sending Passport or Driving License photo data, Selfie photos, and selfie photos holding a Passport or Driving License."])}
        }
      }
    },
    "11": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash in & Cash Out"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banks or FSI are able to instantly utilize our networks of retail outlets onboarded as licensed digital banking agents. This will enable you to offer cash-in and cash-out points, to serve the cash basis segments especially micro, small, and medium enterprises."])},
      "api": {
        "0": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer from Bank"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling users to top up Kaspro account balances using VA bank transfers."])}
        },
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash in From Modern Trade "])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable users to top up Kaspro account balances through modern trade [Alfamart & Indomaret]."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Bank"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer API is used to make transfer transactions from a user’s wallet to a bank account"])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank List"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Bank List API is used to display a list of banks for transfer purposes."])}
        },
        "4": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer to Bank Inquiry"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Transfer to Bank Inquiry is used to check the details of transfer transactions to banks."])}
        },
        "5": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Payment Token"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Generate Payment Token API is used to generate tokens to make cash withdrawals at modern merchants."])}
        },
        "6": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KasPro Danamon Cash Out"])},
          "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The KasPro Danamon Cash Out API is used to generate tokens to make cash withdrawals at Bank Danamon ATMs."])}
        }
      }
    }
  },
  "view_journey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Journey"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "hero_video": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digiasia Bios:"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are The First Embeddable Fintech as a Service (EFaaS) Company in Indonesia!"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling specific financial services to be stitched to your native application in order to provide a seamless user experience for your stakeholders."])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling specific financial services APIs to be stitched and consumed to your native application"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a seamless user experience for your stakeholders, and to be embedded into your native transaction journey"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership is the nature of our core product marketing strategy as an EFaaS Provider in developing ready to use use cases for enterprises customers"])}
  },
  "user_journey_detail": {
    "facility_management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facility Management"])},
    "facility_management_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API for services within the management of facilities (apartment, office)."])},
    "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started with Digiasia Bios"])},
    "get_started_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our team to learn more about what we can help you build or create an account to get started right away."])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
  },
  "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name field empty"])},
  "company_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company field empty"])},
  "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email field empty"])},
  "phone_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone field empty"])},
  "email_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email format incorrect"])},
  "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message field empty"])},
  "empty_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty Content"])},
  "investor_relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor Relations"])},
  "investor_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor Presentations"])},
  "readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])}
}