import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import tailwindStyles from "./assets/css/tailwind-output.css";
import styles from "./assets/css/app.css";
import styles2 from "./assets/css/mobile_view.css";
import i18n from "./plugin/locales/i18n";
import VueCryptojs from "vue-cryptojs";
import axios from "axios";
import VueAxios from "vue-axios";

createApp(App)
  .use(VueCryptojs)
  .use(i18n)
  .use(router)
  .use(styles)
  .use(styles2)
  .use(tailwindStyles)
  .use(VueAxios, axios)
  .mount("#app");
