<template>
  <div class="sections investors">
    <div>
      <div>
        <img
          src="@/assets/investors/Banner-Website-DAB-Presentation.jpg"
          :style="{ display: 'block !important' }"
        />
      </div>
      <div class="text-in-slide present-box">
        <div>&nbsp;</div>
        <div>SEC Filings</div>
      </div>
    </div>
    <div class="presentation prl-5">
      <div>
        <img
          class="present_ornamen_1"
          src="@/assets/templates/Ornamen_Bulat_01.png"
        />
        <hr
          class="bg-[#502052] rounded-xl h-2 w-50 mb-1rem border-transparent"
        />
        <h2 class="investor-comprofile">SEC Filings</h2>
        <div>
          <template v-for="(dt, idx) in data" :key="idx">
            <div class="lists">
              <div>
                <img
                  class="present-img"
                  src="@/assets/investors/presentation.png"
                />
              </div>
              <div>
                <div>{{ dt.date }}</div>
                <div>View Filings</div>
              </div>
              <div>
                <a
                  href="https://www.sec.gov/edgar/browse/?CIK=1844981&owner=exclude"
                  target="_blank"
                >
                  <img
                    class="present-img"
                    src="@/assets/investors/logo_pdf.png"
                  />
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestorFilings",
  data: () => ({
    data: [
      {
        date: "1 Feb 2024",
        link: "https://www.sec.gov/edgar/browse/?CIK=1844981&owner=exclude",
      },
    ],
  }),
};
</script>
