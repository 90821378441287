<template>
  <section
    class="grid grid-cols-1 md:grid-cols-3 py-14 mt-48 prl-5 invest-contact"
    :style="loadBg('ContactUs_Footer.png')"
  >
    <div id="contact-us">
      <hr class="bg-white rounded-xl h-2 w-44 border-transparent" />
      <h3 class="text-white">{{ t.t("contact-investors", lang) }}</h3>
    </div>
    <div class="address-investors">
      <table id="addr-first" class="footer-table text-3xl">
        <tr>
          <td width="20px"><SvgLocation /></td>
          <td><strong>Digiasia Bios</strong></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <Addressed />
          </td>
        </tr>
        <tr>
          <td height="20px"></td>
          <td></td>
        </tr>
        <tr>
          <td><SvgEmail /></td>
          <td>inquiry@digiasia.asia</td>
        </tr>
        <tr>
          <td></td>
          <td>investor.relation@digiasia.asia</td>
        </tr>
      </table>
    </div>
    <div class="email-investors">
      <div>Subscribe to our Email Alerts</div>
      <div>
        By submitting your email, you are providing consent to <br />
        Digiasia Limited to send you email alerts.
      </div>
      <div class="flex flex-col gap-6 mt-16 pr-8">
        <input
          class="input input-lg w-full max-w-4xl rounded-3xl"
          type="text"
          name="email"
          placeholder="Email"
          v-model="email"
        />
        <button
          class="btn bg-[#502052] max-w-4xl rounded-3xl btn-lg"
          @click="send"
        >
          SEND
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import i18n from "@/plugin/locales/i18n";
import SvgLocation from "@/components/logo/SvgLocation";
import SvgEmail from "@/components/logo/SvgEmail";
import Addressed from "@/components/portal/Addressed";

const tlang = localStorage.getItem("lang");

export default {
  name: "ContactUsInvestors",
  components: {
    SvgLocation,
    SvgEmail,
    Addressed,
  },
  data: () => ({
    t: i18n.global,
    lang: tlang,
    email: "",
  }),
  methods: {
    loadBg(img) {
      const str = require(`@/assets/templates/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-position": "0 top",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    },
    emailValidation(email) {
      return String(email)
        .toLowerCase()
        .match(/^\w+([.-]?\w+)*@\w+(\[\.-\]?\w+)*\.[a-z]+$/i);
    },
    validation() {
      if (this.emailValidation(this.email) == null) {
        alert(this.t.t("email_format", this.lang));
        return false;
      }

      return true;
    },
    send() {
      var valid = this.validation();
      if (valid == true) {
        this.sendMail();
      }
    },
    sendMail() {
      var form = {
        name: this.name,
        company: this.company,
        email: this.email,
        phone: this.phone,
        message: this.message,
      };
      axios
        .post(process.env.VUE_APP_IP_SERVER + "sendMail", form, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          alert(
            "Your request has been sent to our admin, please wait for our email reply"
          );

          // clear form
          this.name = "";
          this.company = "";
          this.email = "";
          this.phone = "";
          this.message = "";
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
