<template>
  <div id="ourspirit" class="section ourspirit">
    <div class="container">
      <div class="title-text title-spirit">
        <h3>{{ t.t("asia spirit", lang) }}</h3>
      </div>
      <div class="title-desc">
        <div class="our-child-desc">
          <div>
            <div>{{ t.t("asia-spirit-desc", lang) }}</div>
            <div>
              <div class="btn-e" @click="onClick()">E-Book ASIA SPIRIT</div>
            </div>
          </div>
          <div>
            <div class="moto !flex flex-col justify-center items-center">
              <img src="@/assets/ourvalue/ICON-AGILE.png" />
              <div>AGILE</div>
              <div>{{ t.t("agile", lang) }}</div>
              <hr />
            </div>
            <div class="moto !flex flex-col justify-center items-center">
              <img src="@/assets/ourvalue/ICON-SPEAK-UP.png" />
              <div>SPEAK UP</div>
              <div>{{ t.t("speakup", lang) }}</div>
              <hr />
            </div>
            <div class="moto !flex flex-col justify-center items-center">
              <img src="@/assets/ourvalue/ICON-INTEGRITY.png" />
              <div>INTEGRITY</div>
              <div>{{ t.t("integrity", lang) }}</div>
              <hr />
            </div>
            <div class="moto !flex flex-col justify-center items-center">
              <img src="@/assets/ourvalue/ICON-TEAM-WORK.png" />
              <div>TEAM WORK</div>
              <div>{{ t.t("teamwork", lang) }}</div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "About",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
  methods: {
    onClick() {
      window.open("https://bit.ly/Ebook_ASIASPIRIT", "_blank");
    },
  },
};
</script>
