<template>
  <div
    class="w-full flex-1 flex gap-10 flex-col md:flex-row"
    :class="{ 'flex-col-reverse md:flex-row-reverse': right }"
  >
    <div class="card w-full xl:w-[45rem] p-4 px-8 bg-gray-100">
      <img :src="values[0].img" alt="car!" class="w-[200px]" />
      <div class="card-body p-5 flex flex-col">
        <h2 class="card-title !text-primary !capitalize !mb-2">
          {{ values[0].title }}
        </h2>
        <div class="flex-1">
          <p class="text-2xl text-left">{{ values[0].desc }}</p>
        </div>
        <div class="card-actions mt-2" v-if="values[0].link">
          <a
            :href="values[0].link"
            class="btn btn-ghost p-0 !normal-case text-xl text-[#77307c]"
          >
            {{ t.t("view_journey", lang) }} >
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-10">
      <div
        class="card w-full xl:w-[65rem] flex flex-col md:flex-row flex-1 p-4 px-8 bg-gray-100"
      >
        <div class="flex-1">
          <img :src="values[1].img" alt="car!" class="w-[180px]" />
        </div>
        <div class="flex-1 flex flex-col">
          <h2 class="card-title !text-primary !capitalize !mb-2">
            {{ values[1].title }}
          </h2>
          <div class="flex-1">
            <p class="text-2xl text-left">{{ values[1].desc }}</p>
          </div>
          <div class="card-actions mt-2" v-if="values[1].link">
            <a
              :href="values[1].link"
              class="btn btn-ghost p-0 !normal-case text-xl text-[#77307c]"
            >
              {{ t.t("view_journey", lang) }} >
            </a>
          </div>
        </div>
      </div>
      <div
        class="card w-full xl:w-[65rem] flex flex-col md:flex-row flex-1 p-4 px-8 bg-gray-100"
      >
        <div class="flex-1">
          <img :src="values[2].img" alt="car!" class="w-[180px]" />
        </div>
        <div class="flex-1 flex flex-col">
          <h2 class="card-title !text-primary !capitalize !mb-2">
            {{ values[2].title }}
          </h2>
          <div class="flex-1">
            <p class="text-2xl text-left">{{ values[2].desc }}</p>
          </div>
          <div class="card-actions mt-2" v-if="values[2].link">
            <a
              :href="values[2].link"
              class="btn btn-ghost p-0 !normal-case text-xl text-[#77307c]"
            >
              {{ t.t("view_journey", lang) }} >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "ServiceCard",
  props: {
    values: Array,
    right: Boolean,
  },
  data() {
    return {
      isActive: true,
      t: i18n.global,
      lang: tlang,
    };
  },
};
</script>
