<template>
  <Navbar bgimage="Logo_Digiasia_Color.png" />
  <NRHeader />
  <NRContent />
  <Footer />
  <Copyright />
</template>

<script>
import Navbar from "@/components/portal/Navbar.vue";
import NRHeader from "@/components/portal/NewsRoomHeader.vue";
import NRContent from "@/components/portal/NewsRoomContent.vue";
import Footer from "@/components/portal/PageFooterSecond.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "OurValue",
  components: {
    Navbar,
    NRHeader,
    NRContent,
    Footer,
    Copyright,
  },
};
</script>
