<template>
  <section
    class="grid grid-cols-1 md:grid-cols-2 py-14 pl-40 mt-48"
    :style="loadBg('ContactUs_Footer.png')"
  >
    <div id="contact-us">
      <hr class="bg-white rounded-xl h-2 w-44 border-transparent" />
      <h3 class="text-white">{{ t.t("contact-us", lang) }}</h3>
      <p class="text-white">
        {{ t.t("contact-desc", lang) }}
      </p>
    </div>
    <div class="flex flex-col gap-6 mt-16 pr-8">
      <input
        type="text"
        placeholder="Name"
        class="input input-lg w-full max-w-4xl rounded-3xl"
        v-model="name"
      />
      <input
        type="text"
        placeholder="Company"
        class="input input-lg w-full max-w-4xl rounded-3xl"
        v-model="company"
      />
      <input
        type="text"
        placeholder="Email"
        class="input input-lg w-full max-w-4xl rounded-3xl"
        v-model="email"
      />
      <input
        type="text"
        placeholder="Phone Number"
        class="input input-lg w-full max-w-4xl rounded-3xl"
        v-model="phone"
        :maxlength="maxphone"
        @keypress="onlyNumber($event)"
      />
      <input
        type="text"
        placeholder="Message"
        class="input input-lg w-full max-w-4xl rounded-3xl"
        v-model="message"
      />
      <button
        class="btn bg-[#502052] max-w-4xl rounded-3xl btn-lg"
        @click="send"
      >
        {{ t.t("send", lang) }}
      </button>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "ContactUs",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
      maxphone: "14",
    };
  },
  methods: {
    loadBg(img) {
      const str = require(`@/assets/templates/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-position": "0 top",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    },
    onlyNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    emailValidation(email) {
      return String(email)
        .toLowerCase()
        .match(/^\w+([.-]?\w+)*@\w+(\[\.-\]?\w+)*\.[a-z]+$/i);
    },
    validation() {
      if (this.name == "") {
        alert(this.t.t("name_empty", this.lang));
        return false;
      }
      if (this.company == "") {
        alert(this.t.t("company_empty", this.lang));
        return false;
      }
      if (this.email == "") {
        alert(this.t.t("email_empty", this.lang));
        return false;
      }
      if (this.emailValidation(this.email) == null) {
        alert(this.t.t("email_format", this.lang));
        return false;
      }
      if (this.phone == "") {
        alert(this.t.t("phone_empty", this.lang));
        return false;
      }
      if (this.message == "") {
        alert(this.t.t("message_empty", this.lang));
        return false;
      }

      return true;
    },
    send() {
      var valid = this.validation();
      if (valid == true) {
        this.sendMail();
      }
    },
    sendMail() {
      var form = {
        name: this.name,
        company: this.company,
        email: this.email,
        phone: this.phone,
        message: this.message,
      };
      axios
        .post(process.env.VUE_APP_IP_SERVER + "sendMail", form, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          alert(
            "Your request has been sent to our admin, please wait for our email reply"
          );

          // clear form
          this.name = "";
          this.company = "";
          this.email = "";
          this.phone = "";
          this.message = "";
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
