<template>
  <div id="slide" class="section slide !m-0">
    <vueper-slides fixed-height="650px" :arrows="false" autoplay>
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :image="slide.link"
      >
      </vueper-slide>
    </vueper-slides>
    <div class="slide-text">
      <div class="first-slide-text">
        <div>
          <div class="flex justify-center">
            <img src="@/assets/templates/Logo_Digiasia_White2.png" />
          </div>
          <div v-html="t.t('slide-text', lang)"></div>
        </div>
      </div>
      <!--div class="sub-slide-text" v-html="t.t('sub-slide-text', lang)"></div-->
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

const tlang = localStorage.getItem("lang");
const images = [
  { title: "", link: require("@/assets/banner/Banner_Home_1.png") },
  { title: "", link: require("@/assets/banner/Banner_Home_2.png") },
  { title: "", link: require("@/assets/banner/Banner_Home_3.png") },
  { title: "", link: require("@/assets/banner/Banner_Home_4.png") },
];

export default {
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      slides: images,
    };
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    msg: String,
  },
};
</script>
