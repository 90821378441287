<template>
  <div class="card w-[35rem] rounded-b-2xl bg-[#f6f8f9]">
    <figure class="rounded-b-2xl">
      <img
        :src="cardImage(value.img)"
        alt="value.title"
        class="w-full bg-cover"
      />
    </figure>
    <div class="card-body p-5">
      <h2 class="card-title !text-black !capitalize">
        {{ t.t(value.title, lang) }}
      </h2>
      <p class="text-2xl">{{ t.t(value.desc, lang) }}</p>
      <div class="card-actions mt-2">
        <a
          :href="value.link"
          class="btn btn-ghost p-0 !normal-case text-xl text-[#77307c]"
        >
          {{ t.t("view_journey", lang) }} >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "Card",
  props: {
    value: {
      title: String,
      desc: String,
      img: String,
      link: String,
    },
  },
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
  methods: {
    cardImage(img) {
      return require(`@/assets/user_journey/${img}.webp`);
    },
  },
};
</script>
