<template>
  <div
    id="footer"
    class="section footer"
    :style="loadBg('ContactUs_Footer.png')"
  >
    <div class="container text-centered">
      <div>
        <div class="!w-full md:leading-normal">
          <div>
            <h3>{{ t.t("contact-us", lang) }}</h3>
          </div>
          <div>
            <table id="addr-first" class="footer-table mt-[10%] text-3xl">
              <tr>
                <td width="20px"><SvgLocation /></td>
                <td><strong>Digiasia Bios</strong></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Addressed />
                </td>
              </tr>
              <tr>
                <td height="20px"></td>
                <td></td>
              </tr>
              <tr>
                <td><SvgEmail /></td>
                <td>inquiry@digiasia.asia</td>
              </tr>
              <tr>
                <td></td>
                <td>investor.relation@digiasia.asia</td>
              </tr>
            </table>
            <table id="addr-second" style="display: none">
              <tr>
                <td width="20px"><SvgLocation /></td>
                <td><strong>Digiasia Bios</strong></td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  Treasury Tower 3rd floor (Nexis) <br />
                  Kawasan District 8 Lot. 28 <br />
                  Jl. Tulodong Atas no. 28 Jakarta 12190
                </td>
              </tr>
              <tr>
                <td height="20px"></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td><SvgEmail /></td>
                <td>inquiry@digiasia.asia</td>
              </tr>
              <tr>
                <td></td>
                <td>investor.relation@digiasia.asia</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="text-centered">
          <div class="linkedfeed">
            <div>LinkedIn Feed</div>
            <div class="instaContent">
              <div
                :style="loadLinkedIn('DAB_MAXIM.png')"
                @click="
                  openTab(
                    'https://www.linkedin.com/posts/digiasiabios_jakarta-indonesia-since-2010-when-online-based-activity-7190603658156281856-B38J?utm_source=share&utm_medium=member_desktop'
                  )
                "
              ></div>
              <div>
                <div>
                  <img src="@/assets/templates/Profile_Picture.jpeg" />Digiasia
                  Bios
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/posts/digiasiabios_jakarta-indonesia-since-2010-when-online-based-activity-7190603658156281856-B38J?utm_source=share&utm_medium=member_desktop"
                  >
                    <img src="@/assets/linkedin/Logo_Linkedin.png" />
                  </a>
                </div>
              </div>
              <div v-html="t.t('linkedin_comment', lang)"></div>
              <!--template v-if="data.length === 0">
                <div v-for="idx in 6" :key="idx">
                  <a>
                    <img :src="require('@/assets/templates/default.png')" />
                  </a>
                </div>
              </template>
              <div v-for="(datax, index) in data" :key="index" v-else>
                <a :href="loadLink(datax.link)" target="_blank">
                  <img :src="loadImg(datax.image)" />
                </a>
              </div-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="footer-add" class="section footer-add">
    <div>
      <div>{{ t.t("footer.p1", lang) }}</div>
      <div>
        <div>{{ t.t("footer.p2", lang) }}</div>
        <div class="logo_verified">
          <img src="@/assets/templates/Logo_Bank_Indonesia.png" />
          <img src="@/assets/templates/Logo_OJK_2.png" />
          <a
            href="javascript:void(0)"
            onclick="window.open('https://www.certipedia.com/quality_marks/0000085533?locale=en')"
          >
            <img src="@/assets/templates/Rheinland_Certified_PCIDSS.png" />
          </a>
          <a
            href="javascript:void(0)"
            onclick="window.open('https://www.certipedia.com/quality_marks/9000029605?locale=en&certificate_number=824+153+23014')"
          >
            <img
              src="@/assets/templates/Rheinland_Certified_ISO27001_2022.png"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgLocation from "@/components/logo/SvgLocation";
import SvgEmail from "@/components/logo/SvgEmail";
import i18n from "@/plugin/locales/i18n";
import Addressed from "@/components/portal/Addressed";
//import axios from "axios";

const tlang = localStorage.getItem("lang");

export default {
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      //data: [],
    };
  },
  components: {
    SvgLocation,
    SvgEmail,
    Addressed,
  },
  props: {
    msg: String,
  },
  methods: {
    loadImg(img) {
      return `${process.env.VUE_APP_IMAGE_PATH}instagram/${img}`;
    },
    loadLink(url) {
      return url;
    },
    loadBg(img) {
      const str = require(`@/assets/templates/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-position": "0 top",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    },
    loadLinkedIn(img) {
      const str = require(`@/assets/linkedin/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-position": "0 top",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    },
    openTab(url) {
      if (url != "") {
        window.open(url);
      }
    },
  },
  mounted() {
    /*axios
      .get(process.env.VUE_APP_IP_SERVER + "instagram")
      .then((res) => {
        this.data = res.data.data;
      })
      .catch((error) => {
        console.log(error);
      });*/
  },
};
</script>

<style scoped>
div.section.footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: #fff;
}

div.section.footer > div.container {
  margin: 0;
  padding: 0;
}

div.section.footer > div.container > div {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  width: 90%;
}

div.section.footer > div.container > div > div > div {
  text-align: left;
}

div.section.footer > div.container > div > div:nth-child(1) {
  padding: 0 0 0 15%;
  width: 50%;
}

div.section.footer > div.container > div > div:nth-child(2) {
  width: 50%;
}

div.section.footer > div.container > div > div:nth-child(2) > div {
  background-color: #fff;
  border-radius: 15px;
  width: 430px;
  padding: 30px;
}

div.section.footer
  > div.container
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1) {
  color: #8a2785; /* ungu tua */
  font-family: SF Pro Display Bold;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
}

div.section.footer
  > div.container
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > a {
  background-color: #e3d2e2;
  border-radius: 8px;
  color: #8a2785; /* ungu tua */
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 7px 30px;
  text-decoration: none;
}

div.section.footer > div.container > div > div > div:nth-child(1) > h3 {
  color: #fff;
}

.instaContent {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;
  margin-bottom: 20px;
}

.instaContent > div:nth-child(1) {
  border-radius: 7px;
  cursor: pointer;
  height: 230px;
  width: 100%;
}

.instaContent > div:nth-child(2) {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.instaContent > div:nth-child(3) {
  color: rgba(0, 0, 0, 0.6);
  font-family: SF Pro Display;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}

.instaContent > div:nth-child(2) > div:nth-child(1) {
  color: #8a2785; /* ungu tua */
  font-size: 16px;
  font-family: SF Pro Display Bold;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
  width: 50%;
}

.instaContent > div:nth-child(2) > div:nth-child(2) {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.instaContent > div:nth-child(2) > div:nth-child(1) > img {
  margin-right: 10px;
  width: 40px;
}

.instaContent > div:nth-child(2) > div:nth-child(2) > a > img {
  width: 25px;
}

table.footer-table {
  border: none;
}

.footer-add {
  width: 100%;
}
.footer-add > div {
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  margin-top: 50px;
  width: 90%;
}
.footer-add > div > div {
  padding: 5px 20px;
}
.footer-add > div > div:nth-child(1) {
  text-align: justify;
}
.footer-add > div > div:nth-child(2) {
  font-size: 14px;
  font-style: italic;
}
.logo_verified {
  align-items: center;
  display: inline-flex;
  flex-flow: wrap;
  padding-top: 20px;
}
.logo_verified img:nth-child(1) {
  width: 200px;
}
.logo_verified img,
.logo_verified > a {
  margin-right: 10px;
  width: 100px;
}
</style>
