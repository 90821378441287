<template>
  <div v-for="(lb, idx) in items" :key="idx">
    <div class="lists">
      <div>
        <img :src="loadImg(lb.img)" />
      </div>
      <div>
        <div>{{ loadDate(lb.date) }}</div>
        <div>{{ lb.title }}</div>
        <div>
          <button @click="details(lb)">Baca selangkapnya &gt;</button>
        </div>
      </div>
    </div>
  </div>
  <div class="pagination">
    <span
      v-for="n in totalRow"
      :key="n"
      @click="inPage(n)"
      :style="inBold(n)"
      >{{ n }}</span
    >
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BlogHome",
  props: {
    param: Array,
  },
  methods: {
    loadDate(value) {
      if (value == "" || value == undefined) {
        return "-";
      } else {
        return moment(String(value)).format("DD MMMM YYYY");
      }
    },
    loadImg(img) {
      return require(`@/assets/blog/lists/${img}`);
    },
    details(params) {
      //this.$emit("callbackDetail", params);
      location.href = `/blog/${params.hash}`;
    },
    inBold(nil) {
      return nil == this.page ? { "font-weight": 600 } : "";
    },
    inPage(nil) {
      this.page = nil;
      this.loadPerPage();
    },
    loadPerPage() {
      var page =
        this.page == 1 ? this.page - 1 : (this.page - 1) * this.perPage;
      var perPage = this.page * this.perPage;
      var data = [],
        y = 0;
      for (var i = page; i < perPage; i++) {
        if (this.param[i] != undefined) {
          data[y] = this.param[i];
          y++;
        }
      }
      this.items = data;
    },
    pagination() {
      this.totalRow = parseInt(Math.ceil(this.param.length / this.perPage));
    },
  },
  data: () => ({
    totalRow: 0,
    perPage: 10,
    page: 1,
    items: [],
  }),
  mounted() {
    this.loadPerPage();
    this.pagination();
  },
};
</script>
