<template>
  <Navbar bgimage="Logo_Digiasia_Color.png" />
  <JourneyHeader :data="card" />
  <ContactUsBanner />
  <Footer />
  <Copyright />
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import Navbar from "@/components/portal/Navbar.vue";
import JourneyHeader from "@/components/portal/JourneyHeader.vue";
import ContactUsBanner from "@/components/portal/ContactUsBanner.vue";
import Footer from "@/components/portal/Footer.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

const t = i18n.global;
const tlang = localStorage.getItem("lang");
const imageLink = (name) => require(`@/assets/services/${name}`);
const imageUseCaseLink = (name) => require(`@/assets/user_journey/${name}`);

export default {
  name: "UserJourney",
  data() {
    return {
      card: null,
      cards: {
        "digital-wallet": {
          img: imageLink("digital_wallet.png"),
          title: t.t("services.0.title", tlang),
          desc: t.t("services.0.desc", tlang),
          apis: [
            {
              title: t.t("services.0.api.0.title", tlang),
              desc: t.t("services.0.api.0.desc", tlang),
              endpoint: "",
              res_success: "",
            },
            {
              title: t.t("services.0.api.1.title", tlang),
              desc: t.t("services.0.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "message": [string],
              "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "responseId ": [string],
              "code": [int],
              "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.2.title", tlang),
              desc: t.t("services.0.api.2.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/pil-partner-get-otp/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                  "message": [string],
                  "code": [int],
                  "data": {
                  "expiry": [int]
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.3.title", tlang),
              desc: t.t("services.0.api.3.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/validate-otp/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "message": [string],
                "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.4.title", tlang),
              desc: t.t("services.0.api.4.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/customer-account-inquiry/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                  "code": [int]
                  "message": [string]
                  "data": {
                  "firstName": [string],
                  "middleName": [string],
                  "lastName": [string],
                  "fullName": [string],
                  "accountType": [string],
                  "accountStatus": [string],
                  "customerType": [string],
                  "email": [string],
                  "mobileNumber": [numeric],
                  "kycStatus": [string],
                  "balance":
                {
                  "pocketId": [string],
                  "availableBalance": [string]
                }
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.5.title", tlang),
              desc: t.t("services.0.api.5.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                "account": "888xxxxxxxxx918",
                "amount": "4xxx9",
                "price": "4xxx9",
                "serviceFee": "6xxx",
                "reffId": "OLSFQ20220214101951114",
                "flowType": "Async"
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.6.title", tlang),
              desc: t.t("services.0.api.6.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("services.0.api.7.title", tlang),
              desc: t.t("services.0.api.7.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                "account": "888xxxxxxxxx918",
                "amount": "4xxx9",
                "price": "4xxx9",
                "serviceFee": "6xxx",
                "reffId": "OLSFQ20220214101951114",
                "flowType": "Async"
              }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.8.title", tlang),
              desc: t.t("services.0.api.8.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/p2p-transaction/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "data": {
                "pocketId": [int],
                "walletTransactions": [
              {
                "account": [string],
                "amount": [numeric],
                "reference": [numeric],
                "balanceBefore": [money],
                "balanceAfter": [money]
              }
              ],
              }
                "message": [string]
              }`,
              res_fail: `Headers
            Content-Type:application/json
            Body
            {
              "responseId": [string],
              "code": [int],
              "message": [string]
            }`,
            },
            {
              title: t.t("services.0.api.9.title", tlang),
              desc: t.t("services.0.api.9.desc", tlang),
              endpoint:
                "[POST]\nhttps://{{hostname}}/pil-partner-merchant-inquiry/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "code": [int],
              "data": {
                "merchantAccount": [string],
                "serviceFee": [int],
                "merchantName": [string] },
                "message": [string]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("services.0.api.10.title", tlang),
              desc: t.t("services.0.api.10.desc", tlang),
              endpoint: "",
            },
          ],
        },
        "qris-payment": {
          title: t.t("services.1.title", tlang),
          desc: t.t("services.1.desc", tlang),
          img: imageLink("qris_payment.png"),
          apis: [
            {
              title: t.t("services.1.api.0.title", tlang),
              desc: t.t("services.1.api.0.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/qris-issuer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "code": [string],
 "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [string],
"message": [string]
}`,
            },
            {
              title: t.t("services.1.api.1.title", tlang),
              desc: t.t("services.1.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/qris-issuer/",
              res_success: `Headers
Content-Type:application/json
Body
{
"code": [string],
"acquirerName": [string],
"customerPan": [string],
"merchantPan": [string],
"message": [string],
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [string],
"message": [string]
}`,
            },
            {
              title: t.t("services.1.api.2.title", tlang),
              desc: t.t("services.1.api.2.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/qr-check-status/",
              res_success: `{
"ResponseCode": "0",
"TransactionType": "CASHOUT",
"AcquirerName": "BIMASAKTI MULTI SINERGI",
"CustomerMobile": "082xxxxxx070",
"MerchantLocation": "JAKARTA PUSAT"
"MerchantName": "TOKO NIKI AYU",
"OriginalTrxRefNo": "CASHOUT10032",
"MerchantPan": "936xxxxxxx120",
"CustomerPan": "936xxxxxxxxxxx519",
"TerminalID": "A01",
"ResponseTime": "20210815181553",
"CustomerName": "Dicha Putri",
"TxnRefNo": "0077643",
"PaymentStatusCode": "000000",
"ResponseDesc": "Success"
}`,
            },
          ],
        },
        "cash-manajement": {
          title: t.t("services.2.title", tlang),
          desc: t.t("services.2.desc", tlang),
          img: imageLink("cash_management.png"),
        },
        "b2b-loan": {
          title: t.t("services.3.title", tlang),
          desc: t.t("services.3.desc", tlang),
          link: "/user-journey/b2b-loan",
          img: imageLink("b2b_loan.png"),
        },
        "digital-product": {
          title: t.t("services.4.title", tlang),
          desc: t.t("services.4.desc", tlang),
          link: "/user-journey/digital-product",
          img: imageLink("digital_product.png"),
          apis: [
            {
              title: t.t("services.4.api.0.title", tlang),
              desc: t.t("services.4.api.0.desc", tlang),
              endpoint: "[POST]\nhttps:// {{hostname}}/biller-inquiry/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "code": [int],
              "message": [string],
              "data": [
              {
              "Name": [string],
              "Code": [string],
              "Desc": [string],
              "Nominal": [nt],
              "Price": [nt],
              "Fee": [nt],
              "Category": [string]
              }
              …. [other data varies by product]
              ]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "code": [int],
              "message": [string]
              }`,
            },
            {
              title: t.t("services.4.api.1.title", tlang),
              desc: t.t("services.4.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/biller-payment/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "code": [int],
              "message": [string],
              "data": {
              "mobileNumber": [numeric],
              "amount": [money],
              "price": [money],
              "serviceFee": [money],
              "reffId": [numeric]
              ….[other data varies by product]
              }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "code": [int],
              "message": [string]
              }`,
            },
          ],
        },
        remittance: {
          title: t.t("services.5.title", tlang),
          desc: t.t("services.5.desc", tlang),
          link: "",
          img: imageLink("remittance.png"),
        },
        "gold-saving": {
          title: t.t("services.6.title", tlang),
          desc: t.t("services.6.desc", tlang),
          link: "/user-journey/gold-saving",
          img: imageLink("gold_saving.png"),
          apis: [
            {
              title: t.t("services.6.api.0.title", tlang),
              desc: t.t("services.6.api.0.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/register",
              res_success: `Body
              {
              "data": {
              "request_id": 4084,
              "user_id": "085777090084", 
              "name": "Roy Manson",
              "email": "mansonsiallagan@gmail.com", "phone":
              "085777090084"},
              "general_response": {
              "response_status": true,
              "response_code": 200,
              "response_message": "Success",
              "response_timestamp": "2021-02-
              09T10:50:25+07:00"}
              }`,
              res_fail: `Body
              {
              "general_response": {
              "response_status": false, 
              "response_code": 400, "response_message": "Your
              account is not premium yet",
              "response_timestamp": "2021-02-
              15T10:20:20+07:00" }
              }`,
            },
            {
              title: t.t("services.6.api.1.title", tlang),
              desc: t.t("services.6.api.1.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/checkout/gram",
              res_success: `Body
              {
              "data": {
              "response_id": "1612853774",
              "phone_number": "085777090084",
              "gold_weight": 0.0005,
              "amount": 441,
              "fee": 5000,
              "additional": {} },
              "general_response": {
              "response_status": true,
              "response_code": 200,
              "response_message": "Success",
              "response_timestamp": "2021-02-
              09T12:48:04+07:00" }
              }`,
              res_fail: `Body
              {
              "general_response": {
              "response_status": false,
              "response_code": 400,
              "response_message": "User not found",
              "response_timestamp": "2021-02-
              15T11:10:11+07:00" }
              }`,
            },
            {
              title: t.t("services.6.api.2.title", tlang),
              desc: t.t("services.6.api.2.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/purchase/gram",
              res_success: `Body
              {
              "data": {
              "request_id": "1612853774",
              "response_id": "1082102090000011",
              "phone_number": "085777090084",
              "gold_weight": 0.0005,
              "amount": 441, 
              "fee": 5000,
              "additional": {},
              "session_id":
              "764FC70DD96B9E07713D95A9AD004702" },
              "general_response": {
              "response_status": true,
              "response_code": 200,
              "response_message": "Success",
              "response_timestamp": "2021-02-
              09T12:52:06+07:00" }
              }`,
              res_fail: `Body
              {
              "general_response": {
              "response_status": false,
              "response_code": 400,
              "response_message": "Price changes have
              occurred, Please try again",
              "response_timestamp": "2021-02-
              15T11:16:06+07:00" } 
              }`,
            },
            {
              title: t.t("services.6.api.3.title", tlang),
              desc: t.t("services.6.api.3.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/checkout/rupiah",
              res_success: `Body
{
"data": {
"response_id": "1612854864",
"phone_number": "085777090084",
"gold_weight": 0.0113,
"amount": 10000,
"fee": 5000,
"additional": {} },
"general_response": {
"response_status": true, 
"response_code": 200, "response_message":
"Success", "response_timestamp": "2021-02-
09T13:06:06+07:00" }
}`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "User not found",
"response_timestamp": "2021-02-
17T16:47:48+07:00"}
}`,
            },
            {
              title: t.t("services.6.api.4.title", tlang),
              desc: t.t("services.6.api.4.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/purchase/rupiah",
              res_success: `Body
{
"data": {
"request_id": "1612854864",
"response_id": "1082102090000012",
"phone_number": "085777090084",
"gold_weight": 0.0113,
"amount": 10000,
"fee": 5000, "additional": {}, 
"session_id":
"4368992F975487B918AF66083ADEC857"},
"general_response": {
"response_status": true,
"response_code": 200, "response_message":
"Success", "response_timestamp": "2021-02-
09T13:08:38+07:00" }
}`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "wallet balance is
insufficient", "response_timestamp": "2021-02-
17T16:49:53+07:00" }
}`,
            },
            {
              title: t.t("services.6.api.5.title", tlang),
              desc: t.t("services.6.api.5.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/checkout/sell",
              res_success: `Body
{
"data": {
"response_id": "1612856463",
"phone_number": "085777090084",
"gold_weight": 0.0005,
"amount": 423,
"fee": 0, "additional": {} },
"general_response": {
"response_status": true,
"response_code": 200, "response_message":
"Success", "response_timestamp": "2021-02-
09T13:32:39+07:00" }
}`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "Price changes have
occurred, Please try again",
"response_timestamp": "2021-02-
17T17:39:22+07:00" }
}`,
            },
            {
              title: t.t("services.6.api.6.title", tlang),
              desc: t.t("services.6.api.6.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/checkout/sell",
              res_success: `Body
              {
              "data": {
              "response_id": "1612856463",
              "phone_number": "085777090084",
              "gold_weight": 0.0005,
              "amount": 423,
              "fee": 0, "additional": {} },
              "general_response": {
              "response_status": true,
              "response_code": 200, "response_message":
              "Success", "response_timestamp": "2021-02-
              09T13:32:39+07:00" }
              }`,
              res_fail: `Body
              {
              "general_response": {
              "response_status": false,
              "response_code": 400,
              "response_message": "Price changes have
              occurred, Please try again",
              "response_timestamp": "2021-02-
              17T17:39:22+07:00" }
              }`,
            },
            {
              title: t.t("services.6.api.7.title", tlang),
              desc: t.t("services.6.api.7.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/chart/buy",
              res_success: `Body
{
"data": [ { "date": "03 Feb 2021", "value": 883380 }, {
"date": "04 Feb 2021", "value": 883380 }, { "date":
"05 Feb 2021", "value": 878335 }, { "date": "06 Feb
2021", "value": 878335 }, { "date": "07 Feb 2021",
"value": 878335 }, { "date": "08 Feb 2021", "value":
880859 }, { "date": "09 Feb 2021", "value": 880859 } ],
"general_response": { "response_status": true,
"response_code": 200,
"response_message": "Success", 
"response_timestamp": "2021-02-
09T15:00:59+07:00" } }`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "Invalid Params",
"response_timestamp": "2021-02-
17T17:46:31+07:00" }
}`,
            },
            {
              title: t.t("services.6.api.8.title", tlang),
              desc: t.t("services.6.api.8.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/address/subdistrict",
              res_success: `Body
{
"data": [],
"general_response": {
"response_status": true,
"response_code": 200, "response_message":
"Success",
"response_timestamp": "2021-02-
09T15:09:13+07:00" }
}`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "Field kecamatan is
required.", "response_timestamp": "2021-02-
17T17:46:18+07:00" }
}`,
            },
            {
              title: t.t("services.6.api.9.title", tlang),
              desc: t.t("services.6.api.9.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/withdraw/address",
              res_success: `Body
{
"data": {
"request_id": "4118",
"phone_number": "085777090084",
"province": "DKI Jakarta",
"city": "Jakarta Pusat",
"district": "Gambir",
"subdistrict": "Duri Pulo",
"address": "Jl. Sinyar 6 Rt.12 Rw.09",
"zip_code": "10140",
"note": "tes",
"ktp": "3174010109970007" },
"general_response": {
"response_status": true,
"response_code": 200,
"response_message": "Success",
"response_timestamp": "2021-02-
10T10:44:55+07:00" }
}`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "User Not Found",
"response_timestamp": "2021-02-
17T17:53:33+07:00" }
}`,
            },
            {
              title: t.t("services.6.api.10.title", tlang),
              desc: t.t("services.6.api.10.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/withdraw/address",
              res_success: `Body
{
"data": {
"request_id": "4118",
"phone_number": "085777090084",
"province": "DKI Jakarta",
"city": "Jakarta Pusat",
"district": "Gambir",
"subdistrict": "Duri Pulo",
"address": "Jl. Sinyar 6 Rt.12 Rw.09",
"zip_code": "10140",
"note": "tes",
"ktp": "3174010109970007" },
"general_response": {
"response_status": true,
"response_code": 200,
"response_message": "Success",
"response_timestamp": "2021-02-
10T10:44:55+07:00" }
}`,
              res_fail: "",
            },
            {
              title: t.t("services.6.api.11.title", tlang),
              desc: t.t("services.6.api.11.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/withdraw/confirm",
              res_success: `Body
{
"data": {
"request_id": "1613018314",
"response_id": "10821021106", "phone_number":
"085777090084",
"gold_weight": 2,
"amount": 147975,
"fee": 0,
"additional": {
"product": "LM Antam 99.99% (2 gram)",
"shipping": "SAP - REG, 1 - 3 Hari",
"estimation_product": 1318000,
"shipping_cost": 47975,
"certificate_fee": 100000,
"grand_total": 147975 }, "session_id":
"D780E36D125EDA2CEB0FCCCEB552B5AA" },
"general_response": {
"response_status": true,
"response_code": 200,
"response_message": "Success",
"response_timestamp": "2021-02-
11T10:30:57+07:00" }
}`,
              res_fail: "",
            },
            {
              title: t.t("services.6.api.12.title", tlang),
              desc: t.t("services.6.api.12.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/transfer/checkout",
              res_success: `Body
{
"data": {
"response_id": "1613022031",
"phone_number": "085777090084",
"gold_weight": 0.5,
"amount": 423250,
"fee": 0,
"additional": { 
"product": "Emas Logam Mulia 99.99%",
"recipient": " (082185720070)",
"remarks": "Test Roy" } },
"general_response": {
"response_status": true,
"response_code": 200,
"response_message": "Success",
"response_timestamp": "2021-02-
11T11:31:23+07:00" }
}`,
              res_fail: "",
            },
            {
              title: t.t("services.6.api.13.title", tlang),
              desc: t.t("services.6.api.13.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id/v2/gold-pro/transfer/confirm",
              res_success: `Body
{
"data": {
"request_id": "1613022031",
"response_id": "9674",
"phone_number": "08xxxxxxxx084",
"gold_weight": 0.5,
"amount": 423250, 
"fee": 0,
"additional": {
"product": "Emas Logam Mulia 99.99%",
"recipient": "Dicha Putri Pradita (08xxxxxxx70)",
"remarks": "Test Roy" },
"session_id":
"5B1DB2793E21B5E6908D75BF96F3C3FA" },
"general_response": {
"response_status": true,
"response_code": 200,
"response_message": "Success",
"response_timestamp": "2021-02-
11T11:37:37+07:00" }
}`,
              res_fail: `Body
{
"general_response": {
"response_status": false,
"response_code": 400,
"response_message": "Invalid recipient",
"response_timestamp": "2021-02-
17T18:01:05+07:00" }
}
`,
            },
          ],
        },
        vcn: {
          title: t.t("services.7.title", tlang),
          desc: t.t("services.7.desc", tlang),
          link: "/user-journey/vcn",
          img: imageLink("vcn.png"),
          apis: [],
        },
        "buy-now": {
          title: t.t("services.8.title", tlang),
          desc: t.t("services.8.desc", tlang),
          link: "/user-journey/buy-now",
          img: imageLink("buy_now.png"),
          apis: [
            {
              title: t.t("services.8.api.0.title", tlang),
              desc: t.t("services.8.api.0.desc", tlang),
              endpoint: "[POST]\n{{hostname}}/payment/qris/paylater",
              res_success: `{
"code":"000000",
"message":"Success",
"responseId":"8249287347634"
"customerPan":"9360081292038475",
"merchantPan":"9360071903920183",
"acquirerName":"ID.KASPRO.WWW"
}`,
              res_fail: ``,
            },
            {
              title: t.t("services.8.api.1.title", tlang),
              desc: t.t("services.8.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/qris-issuer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "code": [string],
 "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [string],
"message": [string]
}`,
            },
          ],
        },
        kaspro: {
          title: t.t("services.9.title", tlang),
          desc: t.t("services.9.desc", tlang),
          link: "/user-journey/kaspro",
          img: imageLink("kaspro.png"),
          apis: [
            {
              title: t.t("services.9.api.0.title", tlang),
              desc: t.t("services.9.api.0.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/K2KB/GetBalance",
              res_success: `{
 "general_response": {
 "response_status": true,
 "response_code": "200",
 "response_message": "Success",
 "response_timestamp": "2021-04-29 14:27:10.794"
 },
 "data": {
"getBalanceResponse": {
"parameters": {
 "responseCode": "0001",
 "responseMessage": "Request has been processed successfully",
 "customerName": "KASPROBANK TESTEST",
 "accountCurrency": "IDR",
 "accountBalance": "2012979"
 }
 }
 }
 }
`,
              res_fail: `{
“message”: “ERROR”,
"result": "[GE-0001] Unauthorize"
}`,
            },
            {
              title: t.t("services.9.api.1.title", tlang),
              desc: t.t("services.9.api.1.desc", tlang),
              endpoint:
                "https://apigwdevel.kaspro.id/kasprobankdevBSS/api/v1/KasprobankConfig/FindName/Bank.Code.",
              res_success: `{
"message": "OK",
"result": [
"JABAR",
"BPDDKI",
"BPDDIY",
"BPDJATENG",
"BPDJATIM",
"BPDJAMBI",
"BPDACEHSYA",
"BPDACEH",
"BPDSUMUT",
"BPDSUMBAR",
"BPDRIAU",
"BPDSUMSEL",
"BPDLAMPUNG",
"BPDKALSEL",
"BPDKALBAR",
"BPDKALTIMTARA",
"BPDKALTENG",
"BPDSULSELBAR",
"BPDSULUTGO",
"BPDNTBSYA",
"BPDNTB",
"BPDBALI",
"BPDNTT",
"BPDMALUKU",
"BPDPAPUA",
"BPDBENGKULU",
"BPDSULTENG",
"BPDSULTRA",
"BPDBANTEN",
"PARAHYANGAN",
"SWADESI",
"MUAMALAT",
"MESTIKADHARMA",
"METROEKS",
"MASPIONINDO",
"HAGAKITA",
"GANESHA",
"WINDUKENTJANA",
"HALIM",
"HARMONI",
"QNB",
"BTN",
"HS1906",
"JENIUS",
"BTPN",
"SWAGUNA",
"BJBSYA",
"BSM",
"BISNISINT",
"SRIPARTHA",
"JASAJAKARTA",
"BINTANGMANUNGGAL",
"MNCINT",
"YUDHABHAKTI",
"MITRANIAGA",
"AGRONIAGA",
"INDOMONEX",
"ROYALINDO",
"ALFINDO",
"MEGASYA",
"INAPERDANA",
"PANINSYA",
"HARFA",
"PRIMAMASTER",
"PERSYARIKATANINDO",
"SAMPOERNA",
"AKITA",
"LIMAN",
"ANGLOMAS",
"KESEJAHTERAANEKONOMI",
"ARTOSINDO",
"PURBADANARTA",
"BTPNSYA",
"MULTIARTA",
"MAYORAINDO",
"INDEXSELINDO",
"EKSEKUTIF",
"CENTRATAMA",
"FAMA",
"HARAPANBALI",
"VICTORIA",
"HARDA",
"BPRKS",
"AGRIS",
"MERINCORP",
"MAYBANKINDO",
"OCBCINDO",
"CTBC",
"BI",
"BRISYA",
"BRI",
"EXPORINDO",
"MANDIRI",
"BNISYA",
"BNI",
"DANAMON",
"DANAMONSYA",
"PERMATA",
"PERMATASYA",
"BCA",
"MAYBANK",
"MAYBANKSYA",
"PANIN",
"ARTANIAGA",
"NIAGASYA", "NIAGA",
"UOB",
"LIPPO",
"NISP",
"AMEX",
"CITIBANK",
"JPMORGAN",
"BANKOFAMERICA",
"ING",
"WINDUKENTJANAINT",
"ARTHAGRAHA",
"CAI",
"BANGKOK",
"HSBC",
"HSBCSYA",
"BANKOFTOKYO",
"SUMITOMO",
"DIGIBANK",
"DBS",
"RESONAPERDANIA",
"MIZUHO",
"STANDARDCHARTERED",
"ABNAMRO",
"KTB",
"CAPITALINDO",
"BNPPARIBAS",
"UOBINDO",
"KEBINDO",
"RAPOBANK",
"ANZ",
"DEUTSCHE",
"WOORI",
"BANKOFCHINA",
"BUMIARTA",
"EKORAHARJA",
"ANTARDAERAH",
"HAGA",
"IFI",
"IFISYA",
"CENTURY",
"MAYAPADA",
"BPDDKISYA",
"BPDDIYSYA",
"BPDKALSELSYA",
"BPDKALTIMSYA",
"SINARMAS",
"BTNSYA",
"JASAARTA",
"MEGA",
"BUKOPINSYA",
"BUKOPIN",
"BPR",
"EKABUMIARTHA",
"INDOSAT",
"PTBC",
"TCASH"
]
}
`,
              res_fail: `{
"message": "ERROR",
"result": null
}`,
            },
            {
              title: t.t("services.9.api.2.title", tlang),
              desc: t.t("services.9.api.2.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/Inquiry/KasproBank",
              res_success: `{
"general_response": {
"response_status": true,
"response_code": "200",
"response_message": "Success", "response_timestamp": "2020-10-04 12:47:24.49"
}, "data": {
"refference_id": "20201004124723661115471119" }
}
`,
              res_fail: `{
"general_response": {
"response_status": false,
"response_code": "9999",
"response_message": "[TF-0019] 202104291645489824310701020000005 Transaction ID Is Not
Found",
"response_timestamp": "2021-05-06 16:44:35.605" },
"data": null
}`,
            },
            {
              title: t.t("services.9.api.3.title", tlang),
              desc: t.t("services.9.api.3.desc", tlang),
              endpoint:
                "{{hostname}}/pil-transactions-v2/kasprobank/external/api/v1/Transfer/Wrapper?mobileNumber=",
              res_success: `{
"general_response": {
"response_status": true,
"response_code": "200",
"response_message": "Success", "response_timestamp": "2020-10-04 12:47:24.49"
}, "data": {
"refference_id": "20201004124723661115471119" }
}`,
              res_fail: `{
"general_response": {
"response_status": false,
"response_code": "9999",
"response_message": "[TF-0019] 202104291645489824310701020000005 Transaction ID Is Not
Found",
"response_timestamp": "2021-05-06 16:44:35.605" },
"data": null
}`,
            },
            {
              title: t.t("services.9.api.4.title", tlang),
              desc: t.t("services.9.api.4.desc", tlang),
              endpoint:
                "{{hostname}}/pil-transactions-v2/kasprobank/external/api/v1/Transfer/Wrapper?mobileNumber=081100299988",
              res_success: `{
"message": "OK",
"result": [
{
"id": 5173,
"secureId": "4025b45b-837f-4d48-8eaa-5ac13e149891",
"creationDate": "2021-04-29T04:57:32.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:57:32.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291157323054310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "50000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "50000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "0",
"balanceAfter": "50000",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "IN",
"orderID": "202104291157277524310701410000001",
"inOut": "IN",
"description": "tes",
"invoice": false
},
{
"id": 5179,
"secureId": "fde4988b-329a-4405-84ed-67387729a4f4",
"creationDate": "2021-04-29T04:57:33.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:57:33.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291157324354310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701410000001",
        "debitName": "KASPROBANK FAKEFAKE",
        "creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "5000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "5000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "2236272467",
"balanceAfter": "2236267467",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "FEE",
"orderID": "202104291157277524310701410000001",
"inOut": "OUT",
"description": "tes",
"invoice": false
},
{
"id": 5180,
"secureId": "6bcac53a-6a77-4e91-8a2b-1e1a31266fbf",
"creationDate": "2021-04-29T04:57:33.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:57:33.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291157320784310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
        "accType": "CPM",
"debitAcc": "4310701410000001",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "50000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "50000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "2236322467",
"balanceAfter": "2236272467",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "MAIN",
"orderID": "202104291157277524310701410000001",
"inOut": "OUT",
"description": "tes",
"invoice": false
},
{
"id": 5191,
"secureId": "4ff8b35e-24e8-4fc9-b8ed-ad6158184fe5",
"creationDate": "2021-04-29T04:58:06.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:58:06.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291158055924310701420000003",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "5141",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701420000003",
"debitName": "KASPROBANK Sukses Sejahtera",
"creditAcc": "4310701410000001",
"creditName": "KASPROBANK FAKEFAKE",
"sku": "KasproBank",
"amount": "20000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "20000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701420000003",
"dest": "4310701410000001",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "invoice",
"trxId": null,
"balanceBefore": "50000",
"balanceAfter": "30000",
"senderAddress": "Jl. Cempaka Putih",
"senderCity": "0111",
"receiverCity": null,
"type": "MAIN",
"orderID": "202104291157527404310701420000003",
"inOut": "OUT",
"description": "invoice",
"invoice": true
},
{
"id": 5772,
"secureId": "43b47b3c-1674-48ae-ab48-7754b5eab0cd",
"creationDate": "2021-05-05T19:28:29.000+0000",
"createdBy": "system",
"modificationDate": "2021-05-05T19:28:29.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202105060228283494310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701410000001",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "10000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "10000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "30000",
"balanceAfter": "40000",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "IN",
"orderID": "202105060228167754310701410000001",
"inOut": "IN",
"description": "202105060228167754310701410000001",
"invoice": false
},
{
"id": 5778,
"secureId": "ca4f50f5-2ea9-439a-8530-0b451f751256",
"version": 0,
"tid": "202105060228283494310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701410000001",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "10000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "10000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "30000",
"balanceAfter": "40000",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "IN",
"orderID": "202105060228167754310701410000001",
"inOut": "IN",
"description": "202105060228167754310701410000001",
"invoice": false
}
        ]
}
`,
              res_fail: `{
"message": "ERROR",
"result": "[GE-0001] Unauthorize" }`,
            },
          ],
        },
        ekyc: {
          title: t.t("services.10.title", tlang),
          desc: t.t("services.10.desc", tlang),
          link: "/user-journey/ekyc",
          img: imageLink("ekyc.png"),
          apis: [
            {
              title: t.t("services.10.api.0.title", tlang),
              desc: t.t("services.10.api.0.desc", tlang),
              endpoint:
                "[POST]\nhttps://{{hostname}}/pil-partner/ekyc-upgrade-premium-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 0,
 "message": "Berhasil",
 "data": {
 "requestId": "16xxxxxxxx242",
 "idCardNumber": "19xxxxxxxxxx001",
 "name": "Dxxxx Pxxxx Pxxxxxx",
 "religion": "Ixxxx",
 "rtrw": "xxx/yyy",
 "address": "JL. xxxxxx",
 "province": "Kxxxxxxx Bxxxxx Bxxxxxxx",
 "district": "Txxxxxx Pxxxxx",
 "subDistrict": "Kxxxxxx Bxxxxxxx",
 "occupation": "Pxxxxxxxxxxxxx",
 "birthPlace": "Txxxxxxxxxxx",
 "gender": "Pxxxxxxxxx",
 "birthDate": "dd-mm-yyyy",
 "idExpiredDate": "SEUMUR HIDUP",
 "bloodType": "-",
 "villageDistrict": "Pxxxxx Lxxxxxx",
 "nationality": "WNI",
 "maritalStatus": "xxxxxxxxx"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
 "code": 110005,
 "message": "Customer not found"
}`,
            },
            {
              title: t.t("services.10.api.1.title", tlang),
              desc: t.t("services.10.api.1.desc", tlang),
              endpoint:
                "[POST]\nhttps://{{hostname}}/pil-partner-ekyc-upgrade-premium/",
              res_success: `Headers
Content-Type:application/json
Body
{
 data {"requestId": [string]},
 "message": [string],
 "code": [int]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"reponseId": [string],
"code": [int],
"message": [string]
}`,
            },
          ],
        },
        "cash-in": {
          title: t.t("services.11.title", tlang),
          desc: t.t("services.11.desc", tlang),
          link: "/user-journey/cash-in",
          img: imageLink("cash_in.png"),
          apis: [
            {
              title: t.t("services.11.api.0.title", tlang),
              desc: t.t("services.11.api.0.desc", tlang),
              endpoint: "",
              res_success: "",
            },
            {
              title: t.t("services.11.api.1.title", tlang),
              desc: t.t("services.11.api.1.desc", tlang),
              endpoint: "",
              res_success: "",
            },
            {
              title: t.t("services.11.api.2.title", tlang),
              desc: t.t("services.11.api.2.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "code": [int],
 "data": {
 "bankAccount": [numeric],
 "bankName": [string],
 "bankAccountName": [string],
 "serviceFee": [money],
 "amount": [money],
 "totalAmount": [money],
 "balanceBefore": [money],
 "balanceAfter": [money],
 "date": [datetime],
 },
 "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("services.11.api.3.title", tlang),
              desc: t.t("services.11.api.3.desc", tlang),
              endpoint: "[GET]\nhttps://{{hostname}}/bank-list/?mobileNumber=",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": [int],
 "data": [
 {
 "bankCode": [string],
 "bankName": [string],
 }
 ],
 "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int],
"message": [string],
"timestamp": [timestamp]
}`,
            },
            {
              title: t.t("services.11.api.4.title", tlang),
              desc: t.t("services.11.api.4.desc", tlang),
              endpoint:
                "[POST]\nhttps://{{hostname}}/transfer-to-bank-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "code": [int],
 "retrievalReference": [string][m],
 "data": {
 "bankAccountName": [string],
 "bankAccount": [string],
 "serviceFee":[money],
 },
 "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("services.11.api.5.title", tlang),
              desc: t.t("services.11.api.5.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/generate-payment-token/",
              res_success: `Headers
Content-Type:application/json
Body
{
  "responseId": [string],
  "code": [int],
  "message": [string],
  "data": {
    "type:": [enum],
    "token": [numeric],
    "expiry": [numeric]
  }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("services.11.api.6.title", tlang),
              desc: t.t("services.11.api.6.desc", tlang),
              endpoint:
                "[POST]\nhttps://apigw-devel.kaspro.id /danamon/v2/request_inquiry",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 00000,
 "data": {
 "transaction_name": "Cash withdrawal via
Danamon ATM",
 "msisdn": "085777090084",
 "amount": 100000,
 "fee": 3500,
 "total_amount": 103500
 },
 "message": "Success"
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
 "code": 000814,
 "message": "User need to be premium"
}`,
            },
          ],
        },
        "facility-management": {
          img: imageUseCaseLink("facility_management.webp"),
          title: t.t("usecases.0.title", tlang),
          desc: t.t("usecases.0.desc", tlang),
          apis: [
            {
              title: t.t("usecases.0.api.0.title", tlang),
              desc: t.t("usecases.0.api.0.desc", tlang),
              endpoint: "",
              res_success: "",
            },
            {
              title: t.t("usecases.0.api.1.title", tlang),
              desc: t.t("usecases.0.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "message": [string],
              "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "responseId ": [string],
              "code": [int],
              "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.2.title", tlang),
              desc: t.t("usecases.0.api.2.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/pil-partner-get-otp/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                  "message": [string],
                  "code": [int],
                  "data": {
                  "expiry": [int]
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.3.title", tlang),
              desc: t.t("usecases.0.api.3.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/validate-otp/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "message": [string],
                "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.4.title", tlang),
              desc: t.t("usecases.0.api.4.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/customer-account-inquiry/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                  "code": [int]
                  "message": [string]
                  "data": {
                  "firstName": [string],
                  "middleName": [string],
                  "lastName": [string],
                  "fullName": [string],
                  "accountType": [string],
                  "accountStatus": [string],
                  "customerType": [string],
                  "email": [string],
                  "mobileNumber": [numeric],
                  "kycStatus": [string],
                  "balance":
                {
                  "pocketId": [string],
                  "availableBalance": [string]
                }
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.5.title", tlang),
              desc: t.t("usecases.0.api.5.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                "account": "888xxxxxxxxx918",
                "amount": "4xxx9",
                "price": "4xxx9",
                "serviceFee": "6xxx",
                "reffId": "OLSFQ20220214101951114",
                "flowType": "Async"
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.6.title", tlang),
              desc: t.t("usecases.0.api.6.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.0.api.7.title", tlang),
              desc: t.t("usecases.0.api.7.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                "account": "888xxxxxxxxx918",
                "amount": "4xxx9",
                "price": "4xxx9",
                "serviceFee": "6xxx",
                "reffId": "OLSFQ20220214101951114",
                "flowType": "Async"
              }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.8.title", tlang),
              desc: t.t("usecases.0.api.8.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/p2p-transaction/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "data": {
                "pocketId": [int],
                "walletTransactions": [
              {
                "account": [string],
                "amount": [numeric],
                "reference": [numeric],
                "balanceBefore": [money],
                "balanceAfter": [money]
              }
              ],
              }
                "message": [string]
              }`,
              res_fail: `Headers
            Content-Type:application/json
            Body
            {
              "responseId": [string],
              "code": [int],
              "message": [string]
            }`,
            },
            {
              title: t.t("usecases.0.api.9.title", tlang),
              desc: t.t("usecases.0.api.9.desc", tlang),
              endpoint: "",
            },
          ],
        },
        "kaspro-supply": {
          img: imageUseCaseLink("kaspro_supply.webp"),
          title: t.t("usecases.1.title", tlang),
          desc: t.t("usecases.1.desc", tlang),
          apis: [
            {
              title: t.t("usecases.10.api.0.title", tlang),
              desc: t.t("usecases.10.api.0.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/K2KB/GetBalance",
              res_success: `{
 "general_response": {
 "response_status": true,
 "response_code": "200",
 "response_message": "Success",
 "response_timestamp": "2021-04-29 14:27:10.794"
 },
 "data": {
"getBalanceResponse": {
"parameters": {
 "responseCode": "0001",
 "responseMessage": "Request has been processed successfully",
 "customerName": "KASPROBANK TESTEST",
 "accountCurrency": "IDR",
 "accountBalance": "2012979"
 }
 }
 }
 }
`,
              res_fail: `{
“message”: “ERROR”,
"result": "[GE-0001] Unauthorize"
}`,
            },
            {
              title: t.t("usecases.10.api.1.title", tlang),
              desc: t.t("usecases.10.api.1.desc", tlang),
              endpoint:
                "https://apigwdevel.kaspro.id/kasprobankdevBSS/api/v1/KasprobankConfig/FindName/Bank.Code.",
              res_success: `{
"message": "OK",
"result": [
"JABAR",
"BPDDKI",
"BPDDIY",
"BPDJATENG",
"BPDJATIM",
"BPDJAMBI",
"BPDACEHSYA",
"BPDACEH",
"BPDSUMUT",
"BPDSUMBAR",
"BPDRIAU",
"BPDSUMSEL",
"BPDLAMPUNG",
"BPDKALSEL",
"BPDKALBAR",
"BPDKALTIMTARA",
"BPDKALTENG",
"BPDSULSELBAR",
"BPDSULUTGO",
"BPDNTBSYA",
"BPDNTB",
"BPDBALI",
"BPDNTT",
"BPDMALUKU",
"BPDPAPUA",
"BPDBENGKULU",
"BPDSULTENG",
"BPDSULTRA",
"BPDBANTEN",
"PARAHYANGAN",
"SWADESI",
"MUAMALAT",
"MESTIKADHARMA",
"METROEKS",
"MASPIONINDO",
"HAGAKITA",
"GANESHA",
"WINDUKENTJANA",
"HALIM",
"HARMONI",
"QNB",
"BTN",
"HS1906",
"JENIUS",
"BTPN",
"SWAGUNA",
"BJBSYA",
"BSM",
"BISNISINT",
"SRIPARTHA",
"JASAJAKARTA",
"BINTANGMANUNGGAL",
"MNCINT",
"YUDHABHAKTI",
"MITRANIAGA",
"AGRONIAGA",
"INDOMONEX",
"ROYALINDO",
"ALFINDO",
"MEGASYA",
"INAPERDANA",
"PANINSYA",
"HARFA",
"PRIMAMASTER",
"PERSYARIKATANINDO",
"SAMPOERNA",
"AKITA",
"LIMAN",
"ANGLOMAS",
"KESEJAHTERAANEKONOMI",
"ARTOSINDO",
"PURBADANARTA",
"BTPNSYA",
"MULTIARTA",
"MAYORAINDO",
"INDEXSELINDO",
"EKSEKUTIF",
"CENTRATAMA",
"FAMA",
"HARAPANBALI",
"VICTORIA",
"HARDA",
"BPRKS",
"AGRIS",
"MERINCORP",
"MAYBANKINDO",
"OCBCINDO",
"CTBC",
"BI",
"BRISYA",
"BRI",
"EXPORINDO",
"MANDIRI",
"BNISYA",
"BNI",
"DANAMON",
"DANAMONSYA",
"PERMATA",
"PERMATASYA",
"BCA",
"MAYBANK",
"MAYBANKSYA",
"PANIN",
"ARTANIAGA",
"NIAGASYA", "NIAGA",
"UOB",
"LIPPO",
"NISP",
"AMEX",
"CITIBANK",
"JPMORGAN",
"BANKOFAMERICA",
"ING",
"WINDUKENTJANAINT",
"ARTHAGRAHA",
"CAI",
"BANGKOK",
"HSBC",
"HSBCSYA",
"BANKOFTOKYO",
"SUMITOMO",
"DIGIBANK",
"DBS",
"RESONAPERDANIA",
"MIZUHO",
"STANDARDCHARTERED",
"ABNAMRO",
"KTB",
"CAPITALINDO",
"BNPPARIBAS",
"UOBINDO",
"KEBINDO",
"RAPOBANK",
"ANZ",
"DEUTSCHE",
"WOORI",
"BANKOFCHINA",
"BUMIARTA",
"EKORAHARJA",
"ANTARDAERAH",
"HAGA",
"IFI",
"IFISYA",
"CENTURY",
"MAYAPADA",
"BPDDKISYA",
"BPDDIYSYA",
"BPDKALSELSYA",
"BPDKALTIMSYA",
"SINARMAS",
"BTNSYA",
"JASAARTA",
"MEGA",
"BUKOPINSYA",
"BUKOPIN",
"BPR",
"EKABUMIARTHA",
"INDOSAT",
"PTBC",
"TCASH"
]
}
`,
              res_fail: `{
"message": "ERROR",
"result": null
}`,
            },
            {
              title: t.t("usecases.10.api.2.title", tlang),
              desc: t.t("usecases.10.api.2.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/Inquiry/KasproBank",
              res_success: `{
 "message": "OK",
 "result": {
 "tid": "202104191432025144310701410000001",
 "sourceAccount": "431xxxxxxxxxx001",
 "sourceName":
 "KASPROBANK FAKEFAKE",
 "destinationAccount": "431xxxxxxxxxx002",
 "destinationName": "KASPROBANK TESTEST",
 "amount": "10000",
 "adminFee": "0",
 "interBankFee": "0",
 "totalAmount": "10000",
 "sourceType": "CPM"
} 
}`,
              res_fail: `{
"message": "DATA_NOT_FOUND", "result": "Source account not found"
}`,
            },
            {
              title: t.t("usecases.10.api.3.title", tlang),
              desc: t.t("usecases.10.api.3.desc", tlang),
              endpoint:
                "{{hostname}}/pil-transactions-v2/kasprobank/external/api/v1/Transfer/Wrapper?mobileNumber=",
              res_success: `{
"general_response": {
"response_status": true,
"response_code": "200",
"response_message": "Success", "response_timestamp": "2020-10-04 12:47:24.49"
}, "data": {
"refference_id": "20201004124723661115471119" }
}`,
              res_fail: `{
"general_response": {
"response_status": false,
"response_code": "9999",
"response_message": "[TF-0019] 202104291645489824310701020000005 Transaction ID Is Not
Found",
"response_timestamp": "2021-05-06 16:44:35.605" },
"data": null
}`,
            },
          ],
        },
        "retail-chain": {
          img: imageUseCaseLink("retail.webp"),
          title: t.t("usecases.2.title", tlang),
          desc: t.t("usecases.2.desc", tlang),
          apis: [
            {
              title: t.t("usecases.2.api.0.title", tlang),
              desc: t.t("usecases.2.api.0.desc", tlang),
              endpoint: "",
              res_success: "",
            },
            {
              title: t.t("usecases.2.api.1.title", tlang),
              desc: t.t("usecases.2.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "message": [string],
 "code": [int]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId ": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.2.api.2.title", tlang),
              desc: t.t("usecases.2.api.2.desc", tlang),
              endpoint:
                "[POST]\nhttps://{{hostname}}/pil-partner/ekyc-upgrade-premium-inquiry",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 0,
 "message": "Berhasil",
 "data": {
 "requestId": "16xxxxxxxx242",
 "idCardNumber": "19xxxxxxxxxx001",
 "name": "Dxxxx Pxxxx Pxxxxxx",
 "religion": "Ixxxx",
 "rtrw": "xxx/yyy",
 "address": "JL. xxxxxx",
 "province": "Kxxxxxxx Bxxxxx Bxxxxxxx",
 "district": "Txxxxxx Pxxxxx",
 "subDistrict": "Kxxxxxx Bxxxxxxx",
 "occupation": "Pxxxxxxxxxxxxx",
 "birthPlace": "Txxxxxxxxxxx",
 "gender": "Pxxxxxxxxx",
 "birthDate": "dd-mm-yyyy",
 "idExpiredDate": "SEUMUR HIDUP",
 "bloodType": "-",
 "villageDistrict": "Pxxxxx Lxxxxxx",
 "nationality": "WNI",
 "maritalStatus": "xxxxxxxxx"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
 "code": 110005,
 "message": "Customer not found"
}`,
            },
            {
              title: t.t("usecases.2.api.3.title", tlang),
              desc: t.t("usecases.2.api.3.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/customer-account-inquiry/?mobileNumber=",
              res_success: `Headers
Content-Type:application/json
Body
{
"code": [int] "message": [string] "data": {
"firstName": [string], "middleName": [string],
"lastName": [string], "fullName": [string], "accountType": [string], "accountStatus": [string], "customerType": [string], "email": [string], "mobileNumber": [numeric], "kycStatus": [string], "balance":
{
"pocketId": [string],
"availableBalance": [string] }
} }`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int], "message": [string] }`,
            },
            {
              title: t.t("usecases.2.api.4.title", tlang),
              desc: t.t("usecases.2.api.4.desc", tlang),
              endpoint: "",
              res_success: "",
            },
            {
              title: t.t("usecases.2.api.5.title", tlang),
              desc: t.t("usecases.2.api.5.desc", tlang),
              endpoint:
                "[POST]\nhttps://{{hostname}}/pil-partner-merchant-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
"code": [int], "data": {
}, "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int], "message": [string] }`,
            },
            {
              title: t.t("usecases.2.api.6.title", tlang),
              desc: t.t("usecases.2.api.6.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/p2p-transaction/",
              res_success: `Headers
Content-Type:application/json
Body
{
"responseId": [string], "code": [int],
"data": {
"pocketId": [int], "walletTransactions": [
{
"account": [string], "amount": [numeric], "reference": [numeric], "balanceBefore": [money], "balanceAfter": [money]
} ],
}
"message": [string] }`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string], "code": [int], "message": [string]
}`,
            },
          ],
        },
        p2p: {
          img: imageUseCaseLink("p2p.webp"),
          title: t.t("usecases.3.title", tlang),
          desc: t.t("usecases.3.desc", tlang),
          apis: [
            {
              title: t.t("usecases.3.api.0.title", tlang),
              desc: t.t("usecases.3.api.0.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.3.api.1.title", tlang),
              desc: t.t("usecases.3.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "message": [string],
 "code": [int]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId ": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.3.api.2.title", tlang),
              desc: t.t("usecases.3.api.2.desc", tlang),
              endpoint:
                "[POST]\nhttps:// {{hostname}}/pil-partner/ekyc-upgrade-premium-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 0,
 "message": "Berhasil",
 "data": {
 "requestId": "16xxxxxxxx242",
 "idCardNumber": "19xxxxxxxxxx001",
 "name": "Dxxxx Pxxxx Pxxxxxx",
 "religion": "Ixxxx",
 "rtrw": "xxx/yyy",
 "address": "JL. xxxxxx",
 "province": "Kxxxxxxx Bxxxxx Bxxxxxxx",
 "district": "Txxxxxx Pxxxxx",
 "subDistrict": "Kxxxxxx Bxxxxxxx",
 "occupation": "Pxxxxxxxxxxxxx",
 "birthPlace": "Txxxxxxxxxxx",
 "gender": "Pxxxxxxxxx",
 "birthDate": "dd-mm-yyyy",
 "idExpiredDate": "SEUMUR HIDUP",
 "bloodType": "-",
 "villageDistrict": "Pxxxxx Lxxxxxx",
 "nationality": "WNI",
 "maritalStatus": "xxxxxxxxx"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
 "code": 110005,
 "message": "Customer not found"
}`,
            },
            {
              title: t.t("usecases.3.api.3.title", tlang),
              desc: t.t("usecases.3.api.3.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/pil-partner-get-otp/?mobileNumber=",
              res_success: `Headers
Content-Type:application/json
Body
{
 "message": [string],
 "code": [int],
 "data": {
 "expiry": [int]
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.3.api.4.title", tlang),
              desc: t.t("usecases.3.api.4.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/validate-otp/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "message": [string],
                "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.3.api.5.title", tlang),
              desc: t.t("usecases.3.api.5.desc", tlang),
              endpoint:
                "[POST]\nhttps:// {{hostname}}/pil-partner/ekyc-upgrade-premium-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 0,
 "message": "Berhasil",
 "data": {
 "requestId": "16xxxxxxxx242",
 "idCardNumber": "19xxxxxxxxxx001",
 "name": "Dxxxx Pxxxx Pxxxxxx",
 "religion": "Ixxxx",
 "rtrw": "xxx/yyy",
 "address": "JL. xxxxxx",
 "province": "Kxxxxxxx Bxxxxx Bxxxxxxx",
 "district": "Txxxxxx Pxxxxx",
 "subDistrict": "Kxxxxxx Bxxxxxxx",
 "occupation": "Pxxxxxxxxxxxxx",
 "birthPlace": "Txxxxxxxxxxx",
 "gender": "Pxxxxxxxxx",
 "birthDate": "dd-mm-yyyy",
 "idExpiredDate": "SEUMUR HIDUP",
 "bloodType": "-",
 "villageDistrict": "Pxxxxx Lxxxxxx",
 "nationality": "WNI",
 "maritalStatus": "xxxxxxxxx"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
 "code": 110005,
 "message": "Customer not found"
}`,
            },
            {
              title: t.t("usecases.3.api.6.title", tlang),
              desc: t.t("usecases.3.api.6.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `
              Headers
Content-Type:application/json
Body
{
 "code": 1,
 "message": "In Process",
 "responseId":
"OLSFQ20220214101951114",
 "data": {
 "account": "888xxxxxxxxx918",
 "amount": "4xxx9",
 "price": "4xxx9",
 "serviceFee": "6xxx",
 "reffId": "OLSFQ20220214101951114",
 "flowType": "Async"
 }
}
              `,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.3.api.7.title", tlang),
              desc: t.t("usecases.3.api.7.desc", tlang),
              endpoint: "",
            },
          ],
        },
        "delivery-service": {
          img: imageUseCaseLink("delivery.webp"),
          title: t.t("usecases.4.title", tlang),
          desc: t.t("usecases.4.desc", tlang),
          apis: [
            {
              title: t.t("usecases.3.api.0.title", tlang),
              desc: t.t("usecases.3.api.0.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.3.api.1.title", tlang),
              desc: t.t("usecases.3.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "message": [string],
 "code": [int]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId ": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.3.api.3.title", tlang),
              desc: t.t("usecases.3.api.3.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/pil-partner-get-otp/?mobileNumber=",
              res_success: `Headers
Content-Type:application/json
Body
{
 "message": [string],
 "code": [int],
 "data": {
 "expiry": [int]
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.3.api.4.title", tlang),
              desc: t.t("usecases.3.api.4.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/validate-otp/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "message": [string],
                "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.2.api.3.title", tlang),
              desc: t.t("usecases.2.api.3.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/customer-account-inquiry/?mobileNumber=",
              res_success: `Headers
Content-Type:application/json
Body
{
"code": [int] "message": [string] "data": {
"firstName": [string], "middleName": [string],
"lastName": [string], "fullName": [string], "accountType": [string], "accountStatus": [string], "customerType": [string], "email": [string], "mobileNumber": [numeric], "kycStatus": [string], "balance":
{
"pocketId": [string],
"availableBalance": [string] }
} }`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"code": [int], "message": [string] }`,
            },
            {
              title: t.t("usecases.0.api.6.title", tlang),
              desc: t.t("usecases.0.api.6.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("services.11.api.2.title", tlang),
              desc: t.t("services.11.api.2.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "responseId": [string],
 "code": [int],
 "data": {
 "bankAccount": [numeric],
 "bankName": [string],
 "bankAccountName": [string],
 "serviceFee": [money],
 "amount": [money],
 "totalAmount": [money],
 "balanceBefore": [money],
 "balanceAfter": [money],
 "date": [datetime],
 },
 "message": [string]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.8.api.1.title", tlang),
              desc: t.t("usecases.8.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                "account": "888xxxxxxxxx918",
                "amount": "4xxx9",
                "price": "4xxx9",
                "serviceFee": "6xxx",
                "reffId": "OLSFQ20220214101951114",
                "flowType": "Async"
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.4.api.8.title", tlang),
              desc: t.t("usecases.4.api.8.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
            },
            {
              title: t.t("usecases.0.api.9.title", tlang),
              desc: t.t("usecases.0.api.9.desc", tlang),
              endpoint: "",
            },
          ],
        },
        transportation: {
          img: imageUseCaseLink("transportation.webp"),
          title: t.t("usecases.5.title", tlang),
          desc: t.t("usecases.5.desc", tlang),
          apis: [
            {
              title: t.t("usecases.3.api.0.title", tlang),
              desc: t.t("usecases.3.api.0.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.0.api.1.title", tlang),
              desc: t.t("usecases.0.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "message": [string],
              "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "responseId ": [string],
              "code": [int],
              "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.2.title", tlang),
              desc: t.t("usecases.0.api.2.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/pil-partner-get-otp/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                  "message": [string],
                  "code": [int],
                  "data": {
                  "expiry": [int]
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.0.api.3.title", tlang),
              desc: t.t("usecases.0.api.3.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/validate-otp/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "message": [string],
                "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.3.api.2.title", tlang),
              desc: t.t("usecases.3.api.2.desc", tlang),
              endpoint:
                "[POST]\nhttps:// {{hostname}}/pil-partner/ekyc-upgrade-premium-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 0,
 "message": "Berhasil",
 "data": {
 "requestId": "16xxxxxxxx242",
 "idCardNumber": "19xxxxxxxxxx001",
 "name": "Dxxxx Pxxxx Pxxxxxx",
 "religion": "Ixxxx",
 "rtrw": "xxx/yyy",
 "address": "JL. xxxxxx",
 "province": "Kxxxxxxx Bxxxxx Bxxxxxxx",
 "district": "Txxxxxx Pxxxxx",
 "subDistrict": "Kxxxxxx Bxxxxxxx",
 "occupation": "Pxxxxxxxxxxxxx",
 "birthPlace": "Txxxxxxxxxxx",
 "gender": "Pxxxxxxxxx",
 "birthDate": "dd-mm-yyyy",
 "idExpiredDate": "SEUMUR HIDUP",
 "bloodType": "-",
 "villageDistrict": "Pxxxxx Lxxxxxx",
 "nationality": "WNI",
 "maritalStatus": "xxxxxxxxx"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
 "code": 110005,
 "message": "Customer not found"
}`,
            },
            {
              title: t.t("usecases.5.api.5.title", tlang),
              desc: t.t("usecases.5.api.5.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/customer-account-inquiry/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
                {
                  "code": [int]
                  "message": [string]
                  "data": {
                  "firstName": [string],
                  "middleName": [string],
                  "lastName": [string],
                  "fullName": [string],
                  "accountType": [string],
                  "accountStatus": [string],
                  "customerType": [string],
                  "email": [string],
                  "mobileNumber": [numeric],
                  "kycStatus": [string],
                  "balance":
                {
                  "pocketId": [string],
                  "availableBalance": [string]
                }
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.5.api.6.title", tlang),
              desc: t.t("usecases.5.api.6.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.5.api.7.title", tlang),
              desc: t.t("usecases.5.api.7.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.5.api.8.title", tlang),
              desc: t.t("usecases.5.api.8.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 1,
 "message": "In Process",
 "responseId":
"OLSFQ20220214101951114",
 "data": {
 "account": "888xxxxxxxxx918",
 "amount": "4xxx9",
 "price": "4xxx9",
 "serviceFee": "6xxx",
 "reffId": "OLSFQ20220214101951114",
 "flowType": "Async"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.5.api.9.title", tlang),
              desc: t.t("usecases.5.api.9.desc", tlang),
              endpoint: "-",
            },
            {
              title: t.t("usecases.5.api.10.title", tlang),
              desc: t.t("usecases.5.api.10.desc", tlang),
              endpoint: "-",
            },
            {
              title: t.t("usecases.5.api.11.title", tlang),
              desc: t.t("usecases.5.api.11.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/p2p-transaction/",
              res_success: `Headers
Content-Type:application/json
Body
{
"responseId": [string], "code": [int],
"data": {
"pocketId": [int], "walletTransactions": [
{
"account": [string], "amount": [numeric], "reference": [numeric], "balanceBefore": [money], "balanceAfter": [money]
} ],
}
"message": [string] }`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string], "code": [int], "message": [string]
}`,
            },
            {
              title: t.t("usecases.5.api.12.title", tlang),
              desc: t.t("usecases.5.api.12.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
Content-Type:application/json
Body
{
 "code": 1,
 "message": "In Process",
 "responseId":
"OLSFQ20220214101951114",
 "data": {
 "account": "888xxxxxxxxx918",
 "amount": "4xxx9",
 "price": "4xxx9",
 "serviceFee": "6xxx",
 "reffId": "OLSFQ20220214101951114",
 "flowType": "Async"
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.5.api.13.title", tlang),
              desc: t.t("usecases.5.api.13.desc", tlang),
              endpoint: "",
            },
          ],
        },
        "biller-management": {
          img: imageUseCaseLink("biller.webp"),
          title: t.t("usecases.6.title", tlang),
          desc: t.t("usecases.6.desc", tlang),
          apis: [
            {
              title: t.t("usecases.6.api.0.title", tlang),
              desc: t.t("usecases.6.api.0.desc", tlang),
              endpoint: "[POST]\nhttps:// {{hostname}}/biller-inquiry/",
              res_success: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string],
"data": [
{
"Name": [string],
"Code": [string],
"Desc": [string],
"Nominal": [nt],
"Price": [nt],
"Fee": [nt],
"Category": [string]
}
…. [other data varies by product]
]
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.6.api.1.title", tlang),
              desc: t.t("usecases.6.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/biller-payment/",
              res_success: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string],
"data": {
 "mobileNumber": [numeric],
 "amount": [money],
 "price": [money],
 "serviceFee": [money],
 "reffId": [numeric]
 ….[other data varies by product]
 }
}`,
              res_fail: `Headers
Content-Type:application/json
Body
{
"responseId": [string],
"code": [int],
"message": [string]
}`,
            },
            {
              title: t.t("usecases.6.api.2.title", tlang),
              desc: t.t("usecases.6.api.2.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.6.api.3.title", tlang),
              desc: t.t("usecases.6.api.3.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.6.api.4.title", tlang),
              desc: t.t("usecases.6.api.4.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.6.api.5.title", tlang),
              desc: t.t("usecases.6.api.5.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.6.api.6.title", tlang),
              desc: t.t("usecases.6.api.6.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.6.api.7.title", tlang),
              desc: t.t("usecases.6.api.7.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.6.api.8.title", tlang),
              desc: t.t("usecases.6.api.8.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.6.api.9.title", tlang),
              desc: t.t("usecases.06api.9.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.6.api.10.title", tlang),
              desc: t.t("usecases.06api.10.desc", tlang),
              endpoint: "",
            },
            {
              title: t.t("usecases.6.api.11.title", tlang),
              desc: t.t("usecases.06api.11.desc", tlang),
              endpoint: "",
            },
          ],
        },
        "qris-aggregator": {
          img: imageUseCaseLink("qris.webp"),
          title: t.t("usecases.7.title", tlang),
          desc: t.t("usecases.7.desc", tlang),
          apis: [
            {
              title: t.t("usecases.7.api.0.title", tlang),
              desc: t.t("usecases.7.api.0.desc", tlang),
              endpoint:
                "POST\nhttps://apigw-devel.kaspro.id/qris-merchant/branch/submit",
              res_success: `{
"general_response": {
"response_status": true,
"response_code": 200,
"response_message": "Success insert branch",
"response_timestamp": "2021-01-04T10:44:04+07:00"
}
}`,
              res_fail: ``,
            },
            {
              title: t.t("usecases.7.api.1.title", tlang),
              desc: t.t("usecases.7.api.1.desc", tlang),
              endpoint: "https://apigw.dev.kaspro.id/qr/dynamic/save",
              res_success: `{
"TxnRefNo" : "10519102010000002",
"RequestTime" : "20201020120000",
"Amount" : 230000,
"QRID": "ID0000000001",
"TerminalID" : "A01",
"MerchantID" : "0215ABxxxxxxxx8901",
"MerchantPan" : "0118xxxxxxxxxxxx4321",
"RawQr" :
"00020101021226620013ID.KASPRO.WWW0118936008121234568901021292373306
29150303UMI5204581253033605502015802ID5912COLICO KOKAS6015JAKARTA
SELATAN610512870630496AA"
}`,
              res_fail: ``,
            },
            {
              title: t.t("usecases.7.api.2.title", tlang),
              desc: t.t("usecases.7.api.2.desc", tlang),
              endpoint: "-",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.7.api.3.title", tlang),
              desc: t.t("usecases.7.api.3.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/qris-payment-callback",
              res_success: `{
 "ResponseCode": "000000",
 "ResponseTime": "2021/08/27 19:21:01",
 "ResponseDesc": "Success"
}`,
              res_fail: ``,
            },
            {
              title: t.t("usecases.7.api.4.title", tlang),
              desc: t.t("usecases.7.api.4.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/acquirer/refund",
              res_success: `{
              "code": "0",
              "message": " Success ", "responseId": "1631602835382"
              }`,
              res_fail: `{
              "code": "999999",
              "message": "General Error", "responseId": "1631602835382"
              }`,
            },
            {
              title: t.t("usecases.7.api.5.title", tlang),
              desc: t.t("usecases.7.api.5.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.7.api.6.title", tlang),
              desc: t.t("usecases.7.api.6.desc", tlang),
              endpoint: "[POST] https://{{hostname}}/qris-payment-callback",
              res_success: `{
"ResponseCode": "000000",
"ResponseTime": "2021/08/27 19:21:01",
"ResponseDesc": "Success"
}`,
              res_fail: ``,
            },
          ],
        },
        "cash-management": {
          img: imageUseCaseLink("integrated_cash.webp"),
          title: t.t("usecases.8.title", tlang),
          desc: t.t("usecases.8.desc", tlang),
          apis: [
            {
              title: t.t("usecases.8.api.0.title", tlang),
              desc: t.t("usecases.8.api.0.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.8.api.1.title", tlang),
              desc: t.t("usecases.8.api.1.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                  "account": "888xxxxxxxxx918",
                  "amount": "4xxx9",
                  "price": "4xxx9",
                  "serviceFee": "6xxx",
                  "reffId": "OLSFQ20220214101951114",
                  "flowType": "Async"
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.8.api.2.title", tlang),
              desc: t.t("usecases.8.api.2.desc", tlang),
              endpoint: "-",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.8.api.3.title", tlang),
              desc: t.t("usecases.8.api.3.desc", tlang),
              endpoint: "-",
              res_success: ``,
            },
          ],
        },
        "account-linkpage": {
          img: imageUseCaseLink("account.webp"),
          title: t.t("usecases.9.title", tlang),
          desc: t.t("usecases.9.desc", tlang),
          apis: [
            {
              title: t.t("usecases.9.api.0.title", tlang),
              desc: t.t("usecases.9.api.0.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/register-customer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "message": [string],
                "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId ": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.9.api.1.title", tlang),
              desc: t.t("usecases.9.api.1.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.9.api.2.title", tlang),
              desc: t.t("usecases.9.api.2.desc", tlang),
              endpoint:
                "[POST]\nhttps:// {{hostname}}/pil-partner/ekyc-upgrade-premium-inquiry/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "code": 0,
                "message": "Berhasil",
                "data": {
                 "requestId": "16xxxxxxxx242",
                 "idCardNumber": "19xxxxxxxxxx001",
                 "name": "Dxxxx Pxxxx Pxxxxxx",
                 "religion": "Ixxxx",
                 "rtrw": "xxx/yyy",
                 "address": "JL. xxxxxx",
                 "province": "Kxxxxxxx Bxxxxx Bxxxxxxx",
                 "district": "Txxxxxx Pxxxxx",
                 "subDistrict": "Kxxxxxx Bxxxxxxx",
                 "occupation": "Pxxxxxxxxxxxxx",
                 "birthPlace": "Txxxxxxxxxxx",
                 "gender": "Pxxxxxxxxx",
                 "birthDate": "dd-mm-yyyy",
                 "idExpiredDate": "SEUMUR HIDUP",
                 "bloodType": "-",
                 "villageDistrict": "Pxxxxx Lxxxxxx",
                 "nationality": "WNI",
                 "maritalStatus": "xxxxxxxxx"
                }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": 110005,
                "message": "Customer not found"
              }`,
            },
            {
              title: t.t("usecases.9.api.3.title", tlang),
              desc: t.t("usecases.9.api.3.desc", tlang),
              endpoint:
                "[GET]\nhttps://{{hostname}}/pil-partner-get-otp/?mobileNumber=",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "message": [string],
                "code": [int],
                "data": {
                "expiry": [int]
              }
              `,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.9.api.4.title", tlang),
              desc: t.t("usecases.9.api.4.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/validate-otp/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "message": [string],
              "code": [int]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "code": [int],
              "message": [string]
              }`,
            },
            {
              title: t.t("usecases.9.api.5.title", tlang),
              desc: t.t("usecases.9.api.5.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
            {
              title: t.t("usecases.9.api.6.title", tlang),
              desc: t.t("usecases.9.api.6.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/qris-issuer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "code": [string],
              "message": [string]
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
              "responseId": [string],
              "code": [string],
              "message": [string]
              }`,
            },
            {
              title: t.t("usecases.9.api.7.title", tlang),
              desc: t.t("usecases.9.api.7.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "code": 1,
                "message": "In Process",
                "responseId":
                "OLSFQ20220214101951114",
                "data": {
                "account": "888xxxxxxxxx918",
                "amount": "4xxx9",
                "price": "4xxx9",
                "serviceFee": "6xxx",
                "reffId": "OLSFQ20220214101951114",
                "flowType": "Async"
              }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.9.api.8.title", tlang),
              desc: t.t("usecases.9.api.8.desc", tlang),
              endpoint: "[POST]\nhttps://{{hostname}}/bank-transfer/",
              res_success: `
              Headers
              Content-Type:application/json
              Body
              {
              "code": 1,
              "message": "In Process",
              "responseId":
              "OLSFQ20220214101951114",
              "data": {
              "account": "888xxxxxxxxx918",
              "amount": "4xxx9",
              "price": "4xxx9",
              "serviceFee": "6xxx",
              "reffId": "OLSFQ20220214101951114",
              "flowType": "Async"
              }
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "responseId": [string],
                "code": [int],
                "message": [string]
              }`,
            },
            {
              title: t.t("usecases.9.api.9.title", tlang),
              desc: t.t("usecases.9.api.9.desc", tlang),
              endpoint:
                "[GET]\nhttps:/ /pil-transaction/report-transaction- v2?mobileNumber=082185720070&dateFrom=2021-12-23&dateTo=2022-01-01",
              res_success: `Headers
              Content-Type:application/json
              Body
              {
                "id": 360385,
                "referenceId": "1082112280000140", 
                "transactionDate": "2021-12-28 02:53:54 AM",
                "status": "SUCCESS",
                "amount": "7,500", 
                "balanceBefore": "324,963", 
                "balanceAfter": "317,463", 
                "transactionType": "GUITRANSFER", 
                "type": "Credit",
                "fee": "0", 
                "transactionTotalAmount": 7500,
              }`,
              res_fail: `Headers
              Content-Type:application/json
              Body
              {
                "id": 360385,
                "referenceId": "1082112280000140", 
                "transactionDate": "2021-12-28 02:53:54 AM", 
                "status": "SUCCESS",
                "amount": "7,500",
                "balanceBefore": "324,963", 
                "balanceAfter": "317,463", 
                "transactionType": "FUNDTRANSFER", 
                "type": "Debit",
                "fee": "0","transactionTotalAmount": 7500, 
              }`,
            },
            {
              title: t.t("usecases.9.api.10.title", tlang),
              desc: t.t("usecases.9.api.10.desc", tlang),
              endpoint: "",
              res_success: ``,
              res_fail: ``,
            },
          ],
        },
        "kaspro-bank": {
          img: imageUseCaseLink("kaspro_bank.webp"),
          title: t.t("usecases.10.title", tlang),
          desc: t.t("usecases.10.desc", tlang),
          apis: [
            {
              title: t.t("usecases.10.api.0.title", tlang),
              desc: t.t("usecases.10.api.0.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/K2KB/GetBalance",
              res_success: `{
 "general_response": {
 "response_status": true,
 "response_code": "200",
 "response_message": "Success",
 "response_timestamp": "2021-04-29 14:27:10.794"
 },
 "data": {
"getBalanceResponse": {
"parameters": {
 "responseCode": "0001",
 "responseMessage": "Request has been processed successfully",
 "customerName": "KASPROBANK TESTEST",
 "accountCurrency": "IDR",
 "accountBalance": "2012979"
 }
 }
 }
 }
`,
              res_fail: `{
“message”: “ERROR”,
"result": "[GE-0001] Unauthorize"
}`,
            },
            {
              title: t.t("usecases.10.api.1.title", tlang),
              desc: t.t("usecases.10.api.1.desc", tlang),
              endpoint:
                "https://apigwdevel.kaspro.id/kasprobankdevBSS/api/v1/KasprobankConfig/FindName/Bank.Code.",
              res_success: `{
"message": "OK",
"result": [
"JABAR",
"BPDDKI",
"BPDDIY",
"BPDJATENG",
"BPDJATIM",
"BPDJAMBI",
"BPDACEHSYA",
"BPDACEH",
"BPDSUMUT",
"BPDSUMBAR",
"BPDRIAU",
"BPDSUMSEL",
"BPDLAMPUNG",
"BPDKALSEL",
"BPDKALBAR",
"BPDKALTIMTARA",
"BPDKALTENG",
"BPDSULSELBAR",
"BPDSULUTGO",
"BPDNTBSYA",
"BPDNTB",
"BPDBALI",
"BPDNTT",
"BPDMALUKU",
"BPDPAPUA",
"BPDBENGKULU",
"BPDSULTENG",
"BPDSULTRA",
"BPDBANTEN",
"PARAHYANGAN",
"SWADESI",
"MUAMALAT",
"MESTIKADHARMA",
"METROEKS",
"MASPIONINDO",
"HAGAKITA",
"GANESHA",
"WINDUKENTJANA",
"HALIM",
"HARMONI",
"QNB",
"BTN",
"HS1906",
"JENIUS",
"BTPN",
"SWAGUNA",
"BJBSYA",
"BSM",
"BISNISINT",
"SRIPARTHA",
"JASAJAKARTA",
"BINTANGMANUNGGAL",
"MNCINT",
"YUDHABHAKTI",
"MITRANIAGA",
"AGRONIAGA",
"INDOMONEX",
"ROYALINDO",
"ALFINDO",
"MEGASYA",
"INAPERDANA",
"PANINSYA",
"HARFA",
"PRIMAMASTER",
"PERSYARIKATANINDO",
"SAMPOERNA",
"AKITA",
"LIMAN",
"ANGLOMAS",
"KESEJAHTERAANEKONOMI",
"ARTOSINDO",
"PURBADANARTA",
"BTPNSYA",
"MULTIARTA",
"MAYORAINDO",
"INDEXSELINDO",
"EKSEKUTIF",
"CENTRATAMA",
"FAMA",
"HARAPANBALI",
"VICTORIA",
"HARDA",
"BPRKS",
"AGRIS",
"MERINCORP",
"MAYBANKINDO",
"OCBCINDO",
"CTBC",
"BI",
"BRISYA",
"BRI",
"EXPORINDO",
"MANDIRI",
"BNISYA",
"BNI",
"DANAMON",
"DANAMONSYA",
"PERMATA",
"PERMATASYA",
"BCA",
"MAYBANK",
"MAYBANKSYA",
"PANIN",
"ARTANIAGA",
"NIAGASYA", "NIAGA",
"UOB",
"LIPPO",
"NISP",
"AMEX",
"CITIBANK",
"JPMORGAN",
"BANKOFAMERICA",
"ING",
"WINDUKENTJANAINT",
"ARTHAGRAHA",
"CAI",
"BANGKOK",
"HSBC",
"HSBCSYA",
"BANKOFTOKYO",
"SUMITOMO",
"DIGIBANK",
"DBS",
"RESONAPERDANIA",
"MIZUHO",
"STANDARDCHARTERED",
"ABNAMRO",
"KTB",
"CAPITALINDO",
"BNPPARIBAS",
"UOBINDO",
"KEBINDO",
"RAPOBANK",
"ANZ",
"DEUTSCHE",
"WOORI",
"BANKOFCHINA",
"BUMIARTA",
"EKORAHARJA",
"ANTARDAERAH",
"HAGA",
"IFI",
"IFISYA",
"CENTURY",
"MAYAPADA",
"BPDDKISYA",
"BPDDIYSYA",
"BPDKALSELSYA",
"BPDKALTIMSYA",
"SINARMAS",
"BTNSYA",
"JASAARTA",
"MEGA",
"BUKOPINSYA",
"BUKOPIN",
"BPR",
"EKABUMIARTHA",
"INDOSAT",
"PTBC",
"TCASH"
]
}
`,
              res_fail: `{
"message": "ERROR",
"result": null
}`,
            },
            {
              title: t.t("usecases.10.api.2.title", tlang),
              desc: t.t("usecases.10.api.2.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/Inquiry/KasproBank",
              res_success: `{
 "message": "OK",
 "result": {
 "tid": "202104191432025144310701410000001",
 "sourceAccount": "431xxxxxxxxxxx001",
 "sourceName":
 "KASPROBANK FAKEFAKE",
 "destinationAccount": "431xxxxxxxxxxx002",
 "destinationName": "KASPROBANK TESTEST",
 "amount": "10000",
 "adminFee": "0",
 "interBankFee": "0",
 "totalAmount": "10000",
 "sourceType": "CPM"
} 
}`,
              res_fail: `{
 "message": "OK",
 "result": {
 "tid": "202104191432025144310701410000001",
 "sourceAccount": "431xxxxxxxxxxx001",
 "sourceName":
 "KASPROBANK FAKEFAKE",
 "destinationAccount": "431xxxxxxxxxxx002",
 "destinationName": "KASPROBANK TESTEST",
 "amount": "10000",
 "adminFee": "0",
 "interBankFee": "0",
 "totalAmount": "10000",
 "sourceType": "CPM"
} 
}`,
            },
            {
              title: t.t("usecases.10.api.3.title", tlang),
              desc: t.t("usecases.10.api.3.desc", tlang),
              endpoint:
                "https://apigw-devel.kaspro.id/kasprobankdevBSS/api/v1/Inquiry/KasproBank",
              res_success: `{
"general_response": {
"response_status": true,
"response_code": "200",
"response_message": "Success", "response_timestamp": "2020-10-04 12:47:24.49"
}, "data": {
"refference_id": "20201004124723661115471119" }
}`,
              res_fail: `{
"general_response": {
"response_status": false,
"response_code": "9999",
"response_message": "[TF-0019] 202104291645489824310701020000005 Transaction ID Is Not
Found",
"response_timestamp": "2021-05-06 16:44:35.605" },
"data": null
}`,
            },
            {
              title: t.t("usecases.10.api.4.title", tlang),
              desc: t.t("usecases.10.api.4.desc", tlang),
              endpoint:
                "{{hostname}}/pil-transactions-v2/kasprobank/external/api/v1/Transfer/Wrapper?mobileNumber=081100299988",
              res_success: `{
"message": "OK",
"result": [
{
"id": 5173,
"secureId": "4025b45b-837f-4d48-8eaa-5ac13e149891",
"creationDate": "2021-04-29T04:57:32.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:57:32.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291157323054310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "431xxxxxxxxxx003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "50000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "50000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "431xxxxxxxxxx001",
"dest": "431xxxxxxxxxx003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "0",
"balanceAfter": "50000",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "IN",
"orderID": "202104291157277524310701410000001",
"inOut": "IN",
"description": "tes",
"invoice": false
},
{
"id": 5179,
"secureId": "fde4988b-329a-4405-84ed-67387729a4f4",
"creationDate": "2021-04-29T04:57:33.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:57:33.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291157324354310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701410000001",
        "debitName": "KASPROBANK FAKEFAKE",
        "creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "5000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "5000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "2236272467",
"balanceAfter": "2236267467",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "FEE",
"orderID": "202104291157277524310701410000001",
"inOut": "OUT",
"description": "tes",
"invoice": false
},
{
"id": 5180,
"secureId": "6bcac53a-6a77-4e91-8a2b-1e1a31266fbf",
"creationDate": "2021-04-29T04:57:33.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:57:33.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291157320784310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
        "accType": "CPM",
"debitAcc": "4310701410000001",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "50000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "50000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "2236322467",
"balanceAfter": "2236272467",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "MAIN",
"orderID": "202104291157277524310701410000001",
"inOut": "OUT",
"description": "tes",
"invoice": false
},
{
"id": 5191,
"secureId": "4ff8b35e-24e8-4fc9-b8ed-ad6158184fe5",
"creationDate": "2021-04-29T04:58:06.000+0000",
"createdBy": "system",
"modificationDate": "2021-04-29T04:58:06.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202104291158055924310701420000003",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "5141",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701420000003",
"debitName": "KASPROBANK Sukses Sejahtera",
"creditAcc": "4310701410000001",
"creditName": "KASPROBANK FAKEFAKE",
"sku": "KasproBank",
"amount": "20000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "20000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701420000003",
"dest": "4310701410000001",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "invoice",
"trxId": null,
"balanceBefore": "50000",
"balanceAfter": "30000",
"senderAddress": "Jl. Cempaka Putih",
"senderCity": "0111",
"receiverCity": null,
"type": "MAIN",
"orderID": "202104291157527404310701420000003",
"inOut": "OUT",
"description": "invoice",
"invoice": true
},
{
"id": 5772,
"secureId": "43b47b3c-1674-48ae-ab48-7754b5eab0cd",
"creationDate": "2021-05-05T19:28:29.000+0000",
"createdBy": "system",
"modificationDate": "2021-05-05T19:28:29.000+0000",
"modifiedBy": "system",
"version": 0,
"tid": "202105060228283494310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701410000001",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "10000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "10000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "30000",
"balanceAfter": "40000",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "IN",
"orderID": "202105060228167754310701410000001",
"inOut": "IN",
"description": "202105060228167754310701410000001",
"invoice": false
},
{
"id": 5778,
"secureId": "ca4f50f5-2ea9-439a-8530-0b451f751256",
"version": 0,
"tid": "202105060228283494310701410000001",
"partnerId": "2803",
"partnerName": "FAKEFAKE",
"senderId": "2817",
"msisdn": null,
"accType": "CPM",
"debitAcc": "4310701410000001",
"debitName": "KASPROBANK FAKEFAKE",
"creditAcc": "4310701420000003",
"creditName": "KASPROBANK Sukses Sejahtera",
"sku": "KasproBank",
"amount": "10000",
"destinationBankCode": null,
"paymentMethod": "ONLINE",
"chargingModelId": "OUR",
"adminFee": "0",
"interBankFee": "0",
"totalAmount": "10000",
"currency": "IDR",
"bankRef": null,
"custRef": null,
"status": "Success",
"remark": "Success",
"sender": "4310701410000001",
"dest": "4310701420000003",
"paymentNtb": null,
"email": null,
"address1": null,
"address2": null,
"datetimeISO": null,
"paymentAmount": null,
"feeMethod": "transaction",
"trxId": null,
"balanceBefore": "30000",
"balanceAfter": "40000",
"senderAddress": "fake fake fake",
"senderCity": "0191",
"receiverCity": null,
"type": "IN",
"orderID": "202105060228167754310701410000001",
"inOut": "IN",
"description": "202105060228167754310701410000001",
"invoice": false
}
        ]
}`,
              res_fail: `{
"message": "ERROR",
"result": "[GE-0001] Unauthorize" }`,
            },
          ],
        },
      },
    };
  },
  components: {
    Navbar,
    JourneyHeader,
    ContactUsBanner,
    Copyright,
    Footer,
  },
  mounted() {
    this.card = this.cards[this.$route.params.id];
    // console.log("card", this.$route.params.id, this.cards);
  },
};
</script>
