<template>
  <div id="footer-second" class="section footer-second">
    <div class="container max-w-none">
      <div>
        <div>
          <h3>{{ t.t("contact-us", lang) }}</h3>
        </div>
        <div>
          <div><SvgLocation /></div>
          <div>
            <strong>Digiasia Bios</strong><br />
            <Addressed />
          </div>
        </div>
        <div>
          <div><SvgEmail /></div>
          <div>inquiry@digiasia.asia<br />investor.relation@digiasia.asia</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgLocation from "@/components/logo/SvgLocation";
import SvgEmail from "@/components/logo/SvgEmail";
import i18n from "@/plugin/locales/i18n";
import Addressed from "@/components/portal/Addressed";

const tlang = localStorage.getItem("lang");

export default {
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
  components: {
    SvgLocation,
    SvgEmail,
    Addressed,
  },
  props: {
    msg: String,
  },
};
</script>

<style scoped>
div.section.footer-second > div.container {
  background-color: #310c2f; /* ungu tua banget */
  color: #fff;
  padding: 70px 0;
  width: 100%;
}

div.section.footer-second > div.container > div {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

div.section.footer-second > div.container > div > div {
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 33%;
}

div.section.footer-second > div.container > div > div:nth-child(1) {
  font-size: 40px;
  font-weight: 700;
}

div.section.footer-second > div.container > div > div:nth-child(1) > h3 {
  color: #fff;
  margin-block-start: 0;
  margin-block-end: 0;
}

div.section.footer-second > div.container > div > div > div:nth-child(1) {
  padding: 0 10px;
}
</style>
