<template>
  <section class="flex items-center flex-col pt-40 relative">
    <div class="absolute left-0 w-40 top-0">
      <img src="@/assets/templates/Ornamen_Bulat_04.png" />
    </div>
    <div>
      <hr class="line-title" />
    </div>
    <div class="text-center px-40 md:px-64 mb-14">
      <h3>License</h3>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-10 p-5">
      <div
        class="card w-96 bg-base-100 shadow-xl"
        v-for="(img, index) in imgs"
        :key="index"
      >
        <a class="card-body" :href="img.link" target="_blank">
          <figure>
            <img :src="cardImage(img.value)" alt="img" />
          </figure>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "License",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      imgs: [
        { value: "Lisensi-KasPro", link: "https://kaspro.id" },
        { value: "Lisensi-KreditPro", link: "https://kreditpro.id" },
        { value: "Lisensi-RemitPro", link: "https://www.remitpro.id" },
        { value: "Lisensi-DigiBos", link: "https://kaspro.id/feature/digibos" },
      ],
    };
  },
  methods: {
    cardImage(img) {
      return require(`@/assets/license/${img}.webp`);
    },
  },
};
</script>
