<template>
  <div class="header lg:hidden !bg-white !relative">
    <div class="container-mobile">
      <div class="topnav">
        <div>
          <a href="/">
            <img class="nav-logo" :src="bgImage" />
          </a>
        </div>
        <div id="myLinks">
          <a
            id="embed_mobile_id"
            href="javascript:void(0)"
            class="navlink dropbtn !text-black !text-opacity-70"
            v-on:click="drop_embed('embed')"
          >
            {{ t.t("embedded_finance", lang) }}
            <i class="fa fa-caret-down"></i>
          </a>
          <div id="embed" class="dropdown-mobile pb-4">
            <div>
              <a
                @click="drop_mobileNav"
                class="!font-medium !text-black !text-opacity-70"
                href="/#our-solution"
                >{{ t.t("our_solution", lang) }}</a
              >
              <a
                @click="drop_mobileNav"
                class="!font-medium !text-black !text-opacity-70"
                href="/#use-cases"
                >{{ t.t("user_journey", lang) }}</a
              >
              <a
                @click="drop_mobileNav"
                class="!font-medium !text-black !text-opacity-70"
                >{{ t.t("open_api", lang) }}</a
              >
            </div>
          </div>
          <a
            @click="drop_mobileNav"
            class="!text-black !text-opacity-70"
            href="/about"
            >{{ t.t("aboutus", lang) }}</a
          >
          <a class="!text-black !text-opacity-70" href="/ourValue">{{
            t.t("ourvalue", lang)
          }}</a>
          <a class="!text-black !text-opacity-70" href="/newsroom">{{
            t.t("newsroom", lang)
          }}</a>
          <a class="!text-black !text-opacity-70" href="/blog">blog</a>
          <a class="!text-black !text-opacity-70" href="/career">{{
            t.t("career", lang)
          }}</a>
          <a
            class="!text-black !text-opacity-70"
            href="https://investors.digiasia.asia/"
            target="_blank"
          >
            Investor
          </a>
          <!--div id="investor" class="dropdown-mobile pb-4">
            <div>
              <a
                @click="drop_mobileNav"
                class="!font-medium !text-black !text-opacity-70"
                href="/investor/relations"
                >Relation</a
              >
              <a
                @click="drop_mobileNav"
                class="!font-medium !text-black !text-opacity-70"
                href="/investor/presentations"
                >Presentations</a
              >
              <a
                @click="drop_mobileNav"
                class="!font-medium !text-black !text-opacity-70"
                href="/investor/secfilings"
                >SEC Filings</a
              >
            </div>
          </div-->
          <a
            id="lang_mobile_id"
            href="javascript:void(0)"
            class="navlink dropbtn !text-black !text-opacity-70"
            v-on:click="drop_mobile"
          >
            {{ t.t("lang", lang) }}
            <i class="fa fa-caret-down"></i>
          </a>
          <div id="drop_mobile" class="dropdown-mobile pb-4">
            <div>
              <p @click="change_lang('ind')">Indonesia</p>
              <p @click="change_lang('en')">English</p>
            </div>
          </div>
        </div>
        <a
          href="javascript:void(0);"
          class="icon"
          v-on:click="mobileNav"
          :style="boxShadow"
        >
          <i class="fa fa-bars" :style="fontColor2"></i>
        </a>
      </div>
    </div>
  </div>

  <nav class="navbar mb-2 shadow-lg h-28 justify-center hidden lg:flex">
    <div>
      <div class="flex-none">
        <router-link to="/">
          <img class="nav-logo" :src="bgImage" />
        </router-link>
      </div>
      <div class="flex-1 pl-28 pt-4">
        <ul class="menu menu-horizontal p-0 wrap-menu">
          <li tabindex="0">
            <a
              href="/"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
            >
              {{ t.t("embedded_finance", lang) }}
              <i class="fa fa-caret-down"></i>
            </a>
            <ul
              class="p-2 bg-white flex-row shadow-lg -translate-x-20 h-28 items-center rounded-lg z-50"
            >
              <li class="w-fit">
                <a
                  href="/#our-solution"
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                  >{{ t.t("our_solution", lang) }}</a
                >
              </li>
              <li class="w-fit border-r h-12 border-gray-300"></li>
              <li class="w-fit">
                <a
                  href="/#use-cases"
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                >
                  {{ t.t("user_journey", lang) }}
                </a>
              </li>
              <li class="w-fit border-r h-12 border-gray-300"></li>
              <li class="w-fit">
                <a
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                  >{{ t.t("open_api", lang) }}</a
                >
              </li>
            </ul>
          </li>
          <li>
            <a
              href="/about"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
            >
              {{ t.t("aboutus", lang) }}
            </a>
          </li>
          <li>
            <router-link
              to="/ourValue"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
            >
              {{ t.t("ourvalue", lang) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/newsroom"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
            >
              {{ t.t("newsroom", lang) }}
            </router-link>
          </li>
          <li>
            <router-link
              to="/blog"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
              @click="loadPage('/blog')"
            >
              blog
            </router-link>
          </li>
          <li>
            <router-link
              to="/career"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
            >
              {{ t.t("career", lang) }}
            </router-link>
          </li>
          <li>
            <a
              href="https://investors.digiasia.asia/"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
              target="_blank"
            >
              {{ t.t("investor_relation", lang) }}
            </a>
            <!--ul
              class="p-2 bg-white flex-row shadow-lg -translate-x-20 h-28 items-center rounded-lg z-50"
            >
              <li class="w-fit">
                <a
                  href="/investor/presentations"
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                  >Investor Presentations</a
                >
              </li>
              <li class="w-fit border-r h-12 border-gray-300"></li>
              <li class="w-fit">
                <a
                  href="/investor/secfilings"
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                  >SEC Filings</a
                >
              </li>
            </ul-->
          </li>
          <li tabindex="0">
            <a
              href="javascript:void(0)"
              class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
            >
              {{ t.t("lang", lang) }}
              <i class="fa fa-caret-down"></i>
            </a>
            <ul
              class="p-2 bg-white flex-row shadow-lg -translate-x-20 h-28 items-center rounded-lg z-50"
            >
              <li @click="change_lang('ind')" class="w-fit">
                <a
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                  >Indonesia</a
                >
              </li>
              <li class="w-fit border-r h-12 border-gray-300"></li>
              <li @click="change_lang('en')" class="w-fit">
                <a
                  class="btn btn-ghost hover:bg-white rounded-btn text-2xl text-black text-opacity-70 hover:text-[#77307c]"
                  >English</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import i18n from "@/plugin/locales/i18n";
import router from "@/router";

export default {
  props: {
    bgimage: String,
    fontcolor2: String,
    boxshadow: String,
  },
  data() {
    return {
      t: i18n.global,
      lang: this.lang,
      bgImage: require("@/assets/templates/" + this.bgimage),
      hover: this.fontcolor2,
      scrollPosition: null,
    };
  },
  created() {
    document.title = "Digiasia Bios";
    document.favicon = "@/assets/images/icon.png";

    // declare and set variable language into localstorage
    this.lang = localStorage.getItem("lang");
    if (this.lang === null) {
      localStorage.setItem("lang", "en");
    }
  },
  methods: {
    change_lang: function (lang) {
      if (this.lang !== lang) {
        localStorage.setItem("lang", lang) || "en";
        router.go();
      }
    },
    mobileNav: function () {
      var x = document.getElementById("myLinks");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    },
    drop_mobile: function () {
      document.getElementById("drop_mobile").classList.toggle("showDropMobile");
      var lang_id = document.getElementById("lang_mobile_id");
      var strClass = lang_id.className;
      if (strClass.indexOf("active") === -1) {
        lang_id.classList.add("active");
      } else {
        lang_id.classList.remove("active");
      }
    },
    drop_embed: function (str) {
      document.getElementById(str).classList.toggle("showDropMobile");
      var embed_id = document.getElementById("embed_mobile_id");
      var strClass = embed_id.className;
      if (strClass.indexOf("active") === -1) {
        embed_id.classList.add("active");
      } else {
        embed_id.classList.remove("active");
      }
    },
    drop_mobileNav: function () {
      var embed_id = document.getElementById("drop_embed");
      if (embed_id.className.includes("showDropMobile")) {
        document
          .getElementById("drop_embed")
          .classList.toggle("showDropMobile");
      }
      var x = document.getElementById("myLinks");
      x.style.display = "none";
    },
    boxShadow() {
      return {
        "box-shadow": "0px 0px 1px 1px " + this.boxshadow,
      };
    },
    fontColor2() {
      return {
        color: this.fontcolor2,
      };
    },
    loadPage(page) {
      location.href = page;
    },
  },
};
</script>
