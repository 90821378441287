<template>
  <div id="careerDetails" class="section careerDetails">
    <div class="container">
      <div>
        <div>{{ data.jobtitle }}</div>
        <div>{{ data.status }}</div>
        <div>
          <a href="mailto:recruitment@digiasia.asia">Apply Now</a>
        </div>
      </div>
      <div>
        <div>Description</div>
        <div>
          <div
            style="color: rgba(0, 0, 0, 0.6); font-weight: 600; padding: 10px 0"
          >
            Job Description
          </div>
          <div v-html="data.jobdesc"></div>
          <div
            style="
              color: rgba(0, 0, 0, 0.6);
              font-weight: 600;
              padding: 10px 0 5px 0;
            "
          >
            Job Request
          </div>
          <div v-html="data.jobreq"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import axios from "axios";
import dummy from "@/assets/_dummy/career.json";

export default {
  data() {
    return {
      t: i18n.global,
      data: [],
      dummy: dummy,
    };
  },
  mounted() {
    axios
      .get(`http://localhost:8081/v1/careerDetails/${this.$route.params.id}`)
      .then((res) => {
        this.data = res.data.data[0];
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        for (var i = 0; i < this.dummy.length; i++) {
          if (this.dummy[i].id == this.$route.params.id) {
            this.data = this.dummy[i];
          }
        }
      });
  },
};
</script>

<style>
div.section.careerDetails {
  margin-top: 10%;
  margin-bottom: 5%;
}

div.section.careerDetails > div.container {
  border: 1px solid rgba(86, 86, 86, 0.3);
  border-radius: 20px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5%;
  width: 90%;
}

div.section.careerDetails > div.container > div {
  text-align: left;
}

div.section.careerDetails > div.container > div:nth-child(1) {
  margin-right: 2%;
  width: 30%;
}
div.section.careerDetails > div.container > div:nth-child(2) {
  margin-left: 2%;
  width: 70%;
}

div.section.careerDetails > div.container > div:nth-child(1) > div {
  display: inline-block;
  width: 100%;
}

div.section.careerDetails
  > div.container
  > div:nth-child(2)
  > div:nth-child(3) {
  color: rgba(86, 86, 86, 0.9); /* abu abu*/
}

div.section.careerDetails
  > div.container
  > div:nth-child(1)
  > div:nth-child(1) {
  font-size: 30px;
  font-weight: 700;
}

div.section.careerDetails
  > div.container
  > div:nth-child(1)
  > div:nth-child(2) {
  color: rgba(86, 86, 86, 0.8);
  font-size: 18px;
  padding: 5% 0;
}

div.section.careerDetails
  > div.container
  > div:nth-child(1)
  > div:nth-child(3) {
  font-size: 16px;
  font-weight: 700;
}

div.section.careerDetails
  > div.container
  > div:nth-child(1)
  > div:nth-child(3)
  > a {
  background-color: #8a2785; /* ungu tua */
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  padding: 2% 0;
  text-decoration: none;
  text-align: center;
  width: 100%;
}

div.section.careerDetails
  > div.container
  > div:nth-child(2)
  > div:nth-child(1) {
  color: #8a2785; /* ungu tua */
  font-size: 20px;
  font-weight: 700;
}
</style>
