<template>
  <section
    class="flex items-center flex-col pt-0 relative h-[80vh] md:h-[unset]"
  >
    <div
      class="absolute h-full w-full bg-[rgba(0,0,0,0.6)] flex flex-col items-center justify-center px-8 md:px-[10em]"
    >
      <h3 class="text-white text-center mb-10">
        {{ word }}
      </h3>
    </div>
    <video
      id="videoBanner"
      autoplay
      muted
      playsinline
      loop
      class="h-full md:h-[unset] md:w-full object-cover"
    >
      <source src="@/assets/heroVideo/dab.mp4" type="video/mp4" />
    </video>
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const t = i18n.global;
const tlang = localStorage.getItem("lang");
export default {
  name: "HeroVideoBanner",
  data() {
    return {
      lang: tlang,
      word: t.t("hero_video.0", tlang),
      words: [
        t.t("hero_video.0", tlang),
        t.t("hero_video.1", tlang),
        t.t("hero_video.2", tlang),
        t.t("hero_video.3", tlang),
        t.t("hero_video.4", tlang),
      ],
    };
  },
  mounted() {
    this.getWords();
    document.getElementById("videoBanner").play();
  },
  methods: {
    getWords() {
      let wordNum = 0;
      setInterval(() => {
        if (wordNum === 4) {
          wordNum = 0;
        } else {
          wordNum += 1;
        }
        console.log("wordnum", wordNum);
        this.word = this.words[wordNum];
      }, 5000);
    },
  },
};
</script>
