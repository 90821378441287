<template>
  <Navbar bgimage="Logo_Digiasia_Color.png" />
  <CareerDetails />
  <Footer />
  <Copyright />
</template>

<script>
import Navbar from "@/components/portal/Navbar.vue";
import CareerDetails from "@/components/portal/CareerDetails.vue";
import Footer from "@/components/portal/PageFooterSecond.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "OurValue",
  components: {
    Navbar,
    CareerDetails,
    Footer,
    Copyright,
  },
};
</script>
