<template>
  <section class="flex items-center flex-col pt-48 relative">
    <div class="absolute right-0 w-40 top-0">
      <img src="@/assets/templates/Ornamen_Bulat_05.png" />
    </div>
    <div id="use-cases">
      <hr class="line-title" />
    </div>
    <div class="text-center px-40 md:px-64 mb-14">
      <h3>Use Cases</h3>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
      <Card v-for="(card, index) in filterCard" :key="index" :value="card" />
    </div>
    <div class="text-center px-40 md:px-64 mb-14" v-if="!isCollapseCard">
      <button
        class="btn !normal-case bg-[#8a2785] border-none rounded-full min-w-48 text-2xl mt-28 text-white"
        @click="onSeeMoreCard"
      >
        {{ t.t("more", lang) }}
      </button>
    </div>
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import Card from "@/components/portal/Card.vue";

const tlang = localStorage.getItem("lang");
export default {
  name: "UseCase",
  components: {
    Card,
  },
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      isCollapseCard: false,
      cards: [
        {
          img: "facility_management",
          title: "usecases.0.title",
          desc: "usecases.0.desc",
          link: "/user-journey/facility-management",
        },
        {
          img: "kaspro_supply",
          title: "usecases.1.title",
          desc: "usecases.1.desc",
          link: "/user-journey/kaspro-supply",
        },
        {
          img: "retail",
          title: "usecases.2.title",
          desc: "usecases.2.desc",
          link: "/user-journey/retail-chain",
        },
        {
          img: "p2p",
          title: "usecases.3.title",
          desc: "usecases.3.desc",
          link: "/user-journey/p2p",
        },
        {
          img: "delivery",
          title: "usecases.4.title",
          desc: "usecases.4.desc",
          link: "/user-journey/delivery-service",
        },
        {
          img: "transportation",
          title: "usecases.5.title",
          desc: "usecases.5.desc",
          link: "/user-journey/transportation",
        },
        {
          img: "biller",
          title: "usecases.6.title",
          desc: "usecases.6.desc",
          link: "/user-journey/biller-management",
        },
        {
          img: "qris",
          title: "usecases.7.title",
          desc: "usecases.7.desc",
          link: "/user-journey/qris-aggregator",
        },
        {
          img: "integrated_cash",
          title: "usecases.8.title",
          desc: "usecases.8.desc",
          link: "/user-journey/cash-management",
        },
        {
          img: "account",
          title: "usecases.9.title",
          desc: "usecases.9.desc",
          link: "/user-journey/account-linkpage",
        },
        {
          img: "kaspro_bank",
          title: "usecases.10.title",
          desc: "usecases.10.desc",
          link: "/user-journey/kaspro-bank",
        },
      ],
    };
  },
  methods: {
    onSeeMoreCard() {
      this.isCollapseCard = true;
    },
  },
  computed: {
    filterCard() {
      return this.isCollapseCard ? this.cards : this.cards.slice(0, 6);
    },
  },
};
</script>
