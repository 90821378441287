<template>
  <div id="nrheader" class="section nrheader">
    <div class="container">
      <div>
        <div>
          <div v-if="data.date !== undefined">{{ loadDate(data.date) }}</div>
          <div v-if="data.title !== undefined">
            <span v-if="lang == 'ind'">{{ data.title }}</span>
            <span v-if="lang == 'en'">{{ data.title_en }}</span>
          </div>
          <div v-if="data.description !== undefined">
            <span v-if="lang == 'ind'" v-html="data.description"></span>
            <span v-if="lang == 'en'" v-html="data.description_en"></span>
          </div>
          <div>
            <a v-if="data.id !== undefined" :href="loadLink(data.id)">{{
              t.t("more-info", lang)
            }}</a>
          </div>
        </div>
        <div>
          <div
            :style="loadBgImg(data.image)"
            v-if="data.image !== undefined"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import dummy from "@/assets/_dummy/news_top.json";
import i18n from "@/plugin/locales/i18n";
import moment from "moment";

const tlang = localStorage.getItem("lang");

export default {
  name: "newsRoomHeader",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      data: [],
      dummy: dummy,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    loadDate(value) {
      return moment(String(value)).format("DD MMMM YYYY");
    },
    loadBgImg(imgPath) {
      const path = require(`@/assets/newsroom/${imgPath}`);
      return {
        "background-size": "cover",
        "background-position": "center center",
        //"background-image": `url(${process.env.VUE_APP_IMAGE_PATH}news/${imgPath})`,
        "background-image": `url(${path})`,
      };
    },
    loadLink(id) {
      return "/newsRoomDetails/" + id;
    },
  },
  mounted() {
    /*axios
      .get(process.env.VUE_APP_IP_SERVER + "newsTopic", {
        timeout: 5000,
      })
      .then((res) => {
        this.data = res.data.data[0];
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        this.data = this.dummy[0];
      });*/
    this.data = this.dummy[0];
  },
};
</script>

<style>
div.section.nrheader {
  margin-top: 5%;
  margin-bottom: 5%;
}

div.section.nrheader > div.container {
  background-color: #f4f4f4;
  border-radius: 10px;
  display: inline-block;
  padding: 60px 20px;
  width: 85%;
}

div.section.nrheader > div.container > div {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

div.section.nrheader > div.container > div > div {
  padding: 0 20px;
  text-align: left;
  width: 50%;
}

div.section.nrheader
  > div.container
  > div
  > div:nth-child(1)
  > div:nth-child(4)
  > a {
  text-decoration: none;
}

div.section.nrheader > div.container > div > div:nth-child(2) > div {
  border-radius: 10px;
  height: 80%;
  width: 100%;
}

div.section.nrheader > div.container > div > div > div:nth-child(1) {
  color: rgba(146, 146, 146, 1); /* abu abu*/
  font-size: 18px;
}

div.section.nrheader > div.container > div > div > div:nth-child(2) {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.3;
  padding: 10px 0 20px 0;
}

div.section.nrcontent > div.container > div > div > div:nth-child(2) > a {
  color: #8a2785; /* ungu tua */
  text-decoration: none;
}

div.section.nrheader > div.container > div > div > div:nth-child(3) {
  color: rgba(121, 121, 121, 1); /* abu abu*/
  font-size: 20px;
  min-height: 200px;
}

div.section.nrheader > div.container > div > div > div:nth-child(4) > a {
  background-color: #8a2785; /* ungu tua */
  border-radius: 20px;
  color: #fff;
  padding: 10px 20px;
  min-height: 30px;
}
</style>
