<template>
  <section class="flex justify-center pt-32 lg:pt-60">
    <div
      class="bg-[#F6F8F9] lg:pl-16 w-8/12 card lg:card-side bg-white shadow-xl rounded-[4rem]"
    >
      <figure
        class="md:max-h-96 lg:max-h-full lg:max-w-3xl lg:relative lg:-top-32"
      >
        <img :src="data.img" v-if="data" />
      </figure>
      <div class="card-body" v-if="data">
        <h2 class="card-title !text-black lg:!text-4xl">
          {{ data.title }}
        </h2>
        <p>{{ data.desc }}</p>
      </div>
    </div>
  </section>
  <section class="w-8/12 pt-32 pb-32 mt-0 mx-auto">
    <h2 class="!text-black lg:!text-4xl font-bold pl-8">
      {{ t.t("user_journey", lang) }}
    </h2>
    <div class="divider" />
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-4 journey-divider"
      v-if="data"
    >
      <div v-for="(api, index) in data.apis" :key="index" class="px-8">
        <div>
          <input type="checkbox" :id="index" class="modal-toggle" />
          <div class="modal">
            <div
              class="modal-box w-11/12 max-w-5xl rounded-3xl relative overflow-hidden h-full w-full"
            >
              <div class="absolute top-0 h-20 overflow-hidden w-full pt-6">
                <label
                  :for="index"
                  class="btn btn-sm btn-ghost absolute right-2 top-2 text-2xl font-bold right-16 top-6"
                  ><i class="fa fa-times fa-3"></i
                ></label>
                <h3 class="text-lg font-bold text-4xl text-[#77307c]">
                  {{ api.title }}
                </h3>
              </div>
              <div
                class="absolute overflow-auto top-20 bottom-20 right-8 left-0 px-8"
              >
                <p class="py-4">
                  {{ api.desc }}
                </p>
                <p class="font-bold">Endpoint</p>
                <pre>{{ api.endpoint }}</pre>
                <div v-if="api.res_success">
                  <p class="font-bold mt-4">Response (Success)</p>
                  <pre>{{ api.res_success?.replace(/(\n)\s+/g, "$1") }}</pre>
                </div>
                <div v-if="api.res_fail">
                  <p class="font-bold mt-4">Response (Fail)</p>
                  <pre>{{ api.res_fail?.replace(/(\n)\s+/g, "$1") }}</pre>
                </div>
              </div>
              <div
                class="modal-action absolute overflow-hidden bottom-2.5 right-8 h-20 overflow-hidden"
              >
                <label :for="index" class="btn btn-lg btn-wide bg-[#77307c]"
                  >Close</label
                >
              </div>
            </div>
          </div>
        </div>
        <p class="font-medium">
          {{ api.title }}
        </p>
        <label
          :for="index"
          class="font-medium text-[#77307c] cursor-pointer"
          v-if="api.endpoint"
          >View API ></label
        >
      </div>
    </div>
    <div class="divider" />
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "JourneyHeader",
  props: {
    data: {
      img: String,
      title: String,
      desc: String,
      apis: Array,
    },
  },
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
  watch: {
    data: {
      handler() {
        //console.log("value", value);
      },
      deep: true,
    },
  },
};
</script>
