<template>
  <div id="career" class="section career">
    <div class="container">
      <div>
        <img :src="image" />
      </div>
      <div>{{ t.t("finding-jobs", lang) }}!</div>
      <div>
        <div v-for="(job, index) in jobs" :key="index">
          <div>{{ job.jobtitle }}</div>
          <div>{{ job.status }}</div>
          <div>
            <a :href="loadLink(job.id)">Details</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import axios from "axios";
import dummy from "@/assets/_dummy/career.json";

const tlang = localStorage.getItem("lang");

export default {
  name: "About",
  props: {
    msg: String,
  },
  methods: {
    loadLink(id) {
      return "/careerdetails/" + id;
    },
  },
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      image: require("@/assets/career/Banner_Career.png"),
      jobs: [],
      dummy: dummy,
    };
  },
  mounted() {
    axios
      .get("http://localhost:8081/v1/career")
      .then((res) => {
        this.jobs = res.data.data;
      })
      .catch((error) => {
        console.log(error);
        console.log("Lokalisasi");
        this.jobs = this.dummy;
      });
  },
};
</script>

<style>
div.section.career {
  margin-top: 10%;
  margin-bottom: 6%;
}

div.section.career > div.container {
  display: inline-block;
  width: 83%;
}

div.section.career > div.container > div:nth-child(1) > img {
  width: 100%;
}

div.section.career > div.container > div:nth-child(2) {
  color: rgba(0, 0, 0, 0.8);
  font-size: 55px;
  font-weight: 700;
  padding: 3% 14%;
}

div.section.career > div.container > div:nth-child(3) {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

div.section.career > div.container > div:nth-child(3) > div {
  border: 1px solid rgba(86, 86, 86, 0.2); /* abu abu*/
  border-radius: 10px;
  padding: 20px;
  text-align: left;
}

div.section.career > div.container > div:nth-child(3) > div > div:nth-child(1) {
  color: #8a2785; /* ungu tua */
  font-size: 20px;
  font-weight: 700;
}

div.section.career > div.container > div:nth-child(3) > div > div:nth-child(2) {
  color: rgba(86, 86, 86, 0.8); /* abu abu*/
  font-size: 16px;
  padding: 15px 0 20px 0;
}

div.section.career > div.container > div:nth-child(3) > div > div:nth-child(3) {
  background-color: #8a2785; /* ungu tua */
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  width: 100%;
}

div.section.career
  > div.container
  > div:nth-child(3)
  > div
  > div:nth-child(3)
  > a {
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 10px 0;
  text-decoration: none;
  width: 100%;
}
</style>
