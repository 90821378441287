<template>
  <Navbar bgimage="Logo_Digiasia_Color.png" />
  <HeroVideoBanner />
  <EfaaS />
  <Services />
  <UseCase />
  <License />
  <ContactUs />
  <Footer />
  <Copyright />
</template>

<script>
import Navbar from "@/components/portal/Navbar.vue";
import HeroVideoBanner from "@/components/portal/HeroVideoBanner.vue";
import EfaaS from "@/components/portal/EfaaS.vue";
import Services from "@/components/portal/Services.vue";
import UseCase from "@/components/portal/UseCase.vue";
import License from "@/components/portal/License.vue";
import ContactUs from "@/components/portal/ContactUs.vue";
import Footer from "@/components/portal/Footer.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "NewHome",
  components: {
    Navbar,
    HeroVideoBanner,
    EfaaS,
    Services,
    UseCase,
    License,
    ContactUs,
    Footer,
    Copyright,
  },
};
</script>
