<template>
  <div id="ourslide" class="section ourslide">
    <!--vueper-slides :arrows="false" :bullets="false">
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :title="slide.title"
        :image="slide.link"
      />
    </vueper-slides-->
    <div class="container">
      <img :src="loadImages" />
      <div class="ourslide-text text-left width-63p height-66p">
        <h3 class="our-slide-font" v-html="t.t('our slide text')"></h3>
        <hr class="margin-left-0 margin-top-10 width-110 line-bold" />
      </div>
    </div>
  </div>
</template>

<script>
import "vueperslides/dist/vueperslides.css";
//import { VueperSlides, VueperSlide } from "vueperslides";
import i18n from "@/plugin/locales/i18n";

const images = [
  { title: "", link: require("@/assets/ourvalue/BANNER-CORE-VALUE-01.png") },
];

export default {
  data() {
    return {
      t: i18n.global,
      slides: images,
      loadImages: require("@/assets/ourvalue/BANNER-CORE-VALUE-01.png"),
    };
  },
  components: {
    //VueperSlides,
    //VueperSlide,
  },
};
</script>

<style>
div.section.ourslide {
  margin-top: 11rem;
  max-height: 65%;
  top: 6px;
  z-index: 0;
}

div.section.ourslide > div.container {
  display: inline-block;
  width: 80%;
}

div.section.ourslide > div.container > img {
  border-radius: 20px;
  width: 100%;
}

.ourslide-text {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  margin: auto;
  z-index: 1;
  display: inline-block;
  transform: translate(-50%, -50%);
}

.ourslide-text > h3 {
  color: #fff;
  margin-block-start: 0;
  margin-block-end: 0;
}
</style>
