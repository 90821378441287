<template>
  <div>
    <Navbar bgimage="Logo_Digiasia_Color.png" />
    <div id="blog" class="section blog">
      <div class="container">
        <div class="page-left">
          <blogHome :param="listBlog" v-if="showDetail == false" />
          <blogDetails :param="paramDetails" v-if="showDetail == true" />
        </div>
        <div class="banner" ref="banner">
          <div class="hideseek" ref="btnHideSeek" @click="hideshow">
            <span v-if="show == true">Hide</span>
            <span v-if="show == false">Show</span>
          </div>
          <div class="wrapper">
            <template v-for="(bn, index) in banner" :key="index">
              <div class="lists" @click="openBannerLink(bn.link)">
                <img :src="bn.img" />
              </div>
            </template>
          </div>
          <div class="artikel" v-if="showDetail == true">
            <div class="title">Artikel Lainnya</div>
            <div
              class="lists"
              v-for="(lb, idx) in listBlog"
              :key="idx"
              @click="artikelClick(lb.hash)"
            >
              <span>{{ lb.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Copyright />
  </div>
</template>

<script>
import Navbar from "@/components/portal/Navbar";
import blogHome from "@/components/portal/BlogHome";
import blogDetails from "@/components/portal/BlogDetails";
import dummy from "@/assets/_dummy/blog.json";
import Footer from "@/components/portal/PageFooterSecond.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "BlogParent",
  components: {
    Navbar,
    blogHome,
    blogDetails,
    Footer,
    Copyright,
  },
  methods: {
    artikelClick(hash) {
      location.href = `/blog/${hash}`;
    },
    callbackDetail(params) {
      this.showDetail = true;
      this.paramDetails = params;
    },
    openBannerLink(link) {
      window.open(link);
    },
    hideshow() {
      if (this.show == false) {
        this.show = true;
        this.$refs.banner.style.right = "0";
        this.$refs.btnHideSeek.style.right = "10px";
        this.$refs.btnHideSeek.style.border = "none";
      } else {
        this.show = false;
        this.$refs.banner.style.right = "-165px";
        this.$refs.btnHideSeek.style.right = "-10px";
        this.$refs.btnHideSeek.style.border = "1px solid #848484";
      }
    },
    findRow(row, target) {
      for (var i = 0; i < row.length; i++) {
        if (row[i].hash == target) {
          this.callbackDetail(row[i]);
        }
      }
    },
  },
  data: () => ({
    paramDetails: [],
    listBlog: dummy,
    showDetail: false,
    show: false,
    banner: [
      {
        img: require("@/assets/blog/banner/BannerWeb_1.png"),
        link: "https://kaspro.id",
      },
      {
        img: require("@/assets/blog/banner/BannerWeb_2.png"),
        link: "https://www.remitpro.id",
      },
      {
        img: require("@/assets/blog/banner/BannerWeb_3.png"),
        link: "https://kreditpro.id",
      },
    ],
  }),
  mounted() {
    if (this.$route.params.id == undefined) {
      this.showDetail = false;
    } else {
      this.findRow(this.listBlog, this.$route.params.id);
    }
  },
};
</script>

<style>
div.section.blog {
  display: inline-block;
  margin-top: 8rem;
  position: relative;
  text-align: center;
  width: 100%;
}

div.section.blog > .container {
  display: inline-flex;
  width: 85%;
}

div.section.blog > .container > .page-left {
  text-align: left;
  width: 75%;
}

div.section.blog > .container > .page-left {
  display: inline-block;
}

div.section.blog > .container > .page-left .lists {
  align-items: flex-start;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  display: inline-flex;
  margin-bottom: 22px;
  padding: 15px;
  width: 98%;
}

div.section.blog > .container > .page-left .lists > div:nth-child(1) {
  padding: 5px;
  min-width: 250px;
}

div.section.blog > .container > .page-left .lists > div:nth-child(2) {
  display: inline-grid;
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
  width: 90%;
}

div.section.blog > .container > .page-left .lists > div:nth-child(2) > div {
  margin-bottom: 10px;
}

div.section.blog > .container > .page-left .pagination {
  display: inline-block;
  font-size: 14px;
  margin: 10px 0;
  text-align: right;
  width: 98%;
}

div.section.blog > .container > .page-left .pagination > span {
  cursor: pointer;
  font-size: 16px;
  margin: 2px;
  padding: 0 5px;
}

div.section.blog
  > .container
  > .page-left
  .lists
  > div:nth-child(2)
  > div:nth-child(1) {
  font-size: 13px;
}

div.section.blog
  > .container
  > .page-left
  .lists
  > div:nth-child(2)
  > div:nth-child(2) {
  color: #8a2785;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
}

div.section.blog
  > .container
  > .page-left
  .lists
  > div:nth-child(2)
  > div:nth-child(3) {
  font-size: 14px;
}

div.section.blog
  > .container
  > .page-left
  .lists
  > div:nth-child(2)
  > div:nth-child(3)
  > button {
  font-weight: 600;
}

div.section.blog > .container > .page-left .lists img {
  width: 300px;
}

div.section.blog > .container > .banner {
  width: 25%;
}

div.section.blog > .container > .banner > .hideseek {
  display: none;
}

div.section.blog > .container > .banner > .wrapper {
  display: inline-block;
  padding: 0;
}

div.section.blog > .container > .banner > .wrapper > .lists {
  margin-bottom: 20px;
  padding: 0 8px 8px 8px;
}

div.section.blog > .container > .banner > .wrapper > .lists > img {
  border-radius: 12px;
  cursor: pointer;
}

div.section.blog > .container > .banner > .artikel {
  display: inline-block;
  margin-bottom: 50px;
  margin-top: 30px;
  padding: 0 10px;
  text-align: left;
  width: 100%;
}

div.section.blog > .container > .banner > .artikel > .title {
  font-size: 16px;
  margin-bottom: 10px;
}

div.section.blog > .container > .banner > .artikel > .lists {
  color: #595959;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}
</style>
