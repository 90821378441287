<template>
  <div class="app-container overflow-x-hidden">
    <Navbar bgimage="Logo_Digiasia_Color.png" />
    <Slide msg="Component Slide" />
    <Partner msg="Component Partner" />
    <About msg="Component About" />
    <Achievement msg="Component Achievement" />
    <!--Transaction msg="Component Transaction" /-->
    <Product msg="Component Product" />
    <!--VisiMisi msg="Component Visi dan Misi" /-->
    <!--Why msg="Component Why Digiasia" /-->
    <!--Management msg="Component Management Digiasia" />
    <BoardMember msg="Component Board" /-->
    <LeaderBoard />
    <Testimoni msg="Component Testimoni" />
    <!--Solution msg="Component Solution" /-->
    <!--div class="paralax"></div-->
    <Footer />
    <Copyright />
  </div>
</template>

<script>
import Navbar from "@/components/portal/Navbar.vue";
import Slide from "@/components/portal/PageSlide.vue";
import About from "@/components/portal/PageAbout.vue";
import Achievement from "@/components/portal/PageAchievement.vue";
//import Transaction from "@/components/portal/PageTransaction.vue";
//import VisiMisi from "@/components/portal/PageVisiMisi.vue";
//import Why from "@/components/portal/PageWhy.vue";
//import Management from "@/components/portal/PageManagement.vue";
//import BoardMember from "@/components/portal/PageBoardMember.vue";
import LeaderBoard from "@/components/portal/InvestorLeaderBoard.vue";
import Product from "@/components/portal/PageProduct.vue";
import Testimoni from "@/components/portal/PageTestimoni.vue";
//import Solution from "@/components/portal/PageSolutionOffer.vue";
import Partner from "@/components/portal/PagePartner.vue";
import Footer from "@/components/portal/PageFooterMain.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Slide,
    About,
    Achievement,
    //Transaction,
    //VisiMisi,
    //Why,
    //Management,
    //BoardMember,
    LeaderBoard,
    Product,
    Testimoni,
    //Solution,
    Partner,
    Footer,
    Copyright,
  },
};
</script>
