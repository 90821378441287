<template>
  <span>
    Sequis Center 3rd Floor unit 303 <br />
    Jl. Jenderal Sudirman No.71, RT.5/RW.3, <br />
    Senayan, Kec. Kebayoran Baru, <br />
    Kota Jakarta Selatan, <br />
    Daerah Khusus Ibukota Jakarta 12190
  </span>
</template>

<script>
export default {
  name: "Addressed",
};
</script>
