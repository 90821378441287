<template>
  <section class="flex items-center flex-col pt-52 relative">
    <div class="absolute left-0 w-40 top-[10%] md:top-[20%]">
      <img src="@/assets/templates/Ornamen_Bulat_04.png" />
    </div>
    <div id="efaas">
      <hr class="line-title" />
    </div>
    <div class="text-center px-40 md:px-64">
      <h3>
        {{ t.t("efaas", lang) }}
      </h3>
      <p class="mt-10">
        {{ t.t("efassdesc.p1", lang) }}
      </p>
      <p>
        {{ t.t("efassdesc.p2", lang) }}
      </p>
      <p>
        {{ t.t("efassdesc.p3", lang) }}
      </p>
      <p>
        {{ t.t("efassdesc.li1", lang) }}
      </p>
      <p>{{ t.t("efassdesc.li2", lang) }}</p>
    </div>
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "EfaaS",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
};
</script>
