<template>
  <div class="wrap">
    <div><b>Kebijakan Privasi</b></div>
    <div>
      Setiap Data Pribadi yang telah dituangkan di Situs ini diperuntukkan untuk
      PT. ISS Indonesia (PT. ISS Indonesia) khusus untuk layanan OneISS Pocket.
      Halaman ini memberikan penjelasan kepada Anda mengenai Kebijakan PT. ISS
      Indonesia terkait dengan pengumpulan, penggunaan dan pengungkapan Data
      Pribadi untuk layanan OneISS Pocket. PT. ISS Indonesia menggunakan Data
      Pribadi Anda hanya untuk kebutuhan pengembangan dan peningkatan kualitas
      layanan OneISS Pocket atau kebutuhan lainnya yang wajar dan tidak
      bertentangan dengan peraturan perundang-undangan yang berlaku di
      Indonesia. Dengan memberikan Data Pribadi ini, Anda setuju atas
      pengumpulan dan penggunaan informasi yang tertuang di dalam Kebijakan
      Privasi untuk Layanan ISS ini. Dalam menggunakan Data Pribadi Anda, OneISS
      Pocket dapat bekerjasama dengan pihak ketiga dengan tetap memperhatikan
      aspek kerahasiaan atas Data Pribadi Anda.
    </div>

    <div class="space">
      <b>Pengumpulan dan Penggunaan Informasi</b>
      <div>
        Pada saat Anda menggunakan Situs ini, OneISS Pocket akan meminta Anda
        untuk memberikan beberapa informasi Data Pribadi yang akan PT. ISS
        Indonesia gunakan untuk mengidentifikasi dan menghubungi Anda. Informasi
        data pribadi termasuk namun tidak terbatas pada: Nama Lengkap, Nomor
        Ponsel, Alamat Email, Tanggal Lahir, Nomor KTP, Data yang PT. ISS
        Indonesia peroleh langsung dari Anda melalui pengisian pada OneISS
        Pocket, termasuk data tentang transaksi serta data yang dikumpulkan
        ketika Anda menggunakan OneISS Pocket yang mungkin PT. ISS Indonesia
        gunakan untuk mengidentifikasi Anda dan mengesahkan pengguna OneISS
        Pocket dengan tujuan pencegahan kehilangan dan penipuan.
        <br /><br />
        Lebih dari itu, PT. ISS Indonesia akan menggunakan Data yang Anda
        berikan untuk urusan administrasi Akun Anda dengan PT. ISS Indonesia
        untuk keperluan verifikasi dan mengelola transaksi yang berhubungan
        dengan OneISS Pocket, melakukan riset mengenai data demografis pengguna
        OneISS Pocket, mengembangkan OneISS Pocket, mengirimkan Anda informasi
        yang PT. ISS Indonesia anggap berguna untuk Anda termasuk informasi
        tentang layanan dari OneISS Pocket setelah Anda memberikan persetujuan
        kepada Kami bahwa Anda tidak keberatan dihubungi mengenai layanan OneISS
        Pocket.
        <br /><br />
        PT. ISS Indonesia dapat menggunakan informasi pribadi Anda, termasuk
        tanggal lahir untuk melakukan verifikasi identitas, membantu pengguna,
        menentukan Layanan yang tepat untuk Anda dan bermanfaat kepada Anda
        serta untuk memahami bagian yang paling menarik dari OneISS Pocket.
        <br /><br />
        Data gabungan akan dianggap sebagai informasi non-pribadi untuk tujuan
        kebijakan privasi ini. Bila Anda mendaftar sebagai pengguna OneISS
        Pocket, PT. ISS Indonesia juga akan menggunakan informasi pribadi Anda
        untuk mengirimkan kepada Anda pemasaran layanan dari waktu ke waktu.
        Anda dapat berhenti berlanggan layanan PT. ISS Indonesia kapan saja
        dengan cara unsubscribe.
        <br /><br />
        Transaksi yang Anda lakukan melalui OneISS Pocket diproses oleh PT. ISS
        Indonesia Indonesia. Anda harus memberikan Data yang akurat dan tidak
        menyesatkan kepada PT. ISS Indonesia. Anda harus memperbaharui dan
        memberitahukan kepada PT. ISS Indonesia apabila ada perubahan Data Anda.
        Rincian transaksi Anda akan disimpan untuk alasan keamanan PT. ISS
        Indonesia. Anda dapat mengakses informasi tersebut melalui Akun Anda
        pada OneISS Pocket
        <br /><br />
        Anda dapat melihat rincian transaksi yang pernah Anda lakukan, informasi
        dana dan langganan dari layanan pemasaran yang OneISS Pocket lakukan.
        Anda tidak akan membiarkan pihak ketiga untuk dapat mengakses Data
        pribadi Anda. PT. ISS Indonesia tidak bertanggung jawab atas
        penyalahgunaan password dan/atau PIN. Anda wajib memberitahukan kepada
        PT. ISS Indonesia apabila password dan/atau PIN Anda disalahgunakan oleh
        pihak lain.
      </div>
    </div>
    <br /><br />

    <div class="space">
      <b>Penggunaan Data Pribadi</b>
      <div>
        Data pribadi yang PT. ISS Indonesia kumpulkan memungkinkan PT. ISS
        Indonesia untuk menginformasikan kepada Anda tentang layanan terkini,
        pembaruan perangkat lunak. Jika Anda keberatan dan tidak ingin
        disertakan lagi dalam informasi tersebut, Anda dapat keluar kapan pun
        dengan memperbarui preferensi.
      </div>
    </div>

    <div class="space">
      <b>Keamanan Informasi Pribadi</b>
      <div>
        PT. ISS Indonesia memastikan bahwa informasi dan/atau Data yang
        dikumpulkan akan disimpan dengan aman. PT. ISS Indonesia menyimpan
        informasi dan/atau Data pribadi Anda selama diperlukan untuk memenuhi
        tujuan yang dijelaskan dalam kebijakan privasi ini.
      </div>
    </div>

    <div class="space">
      <b>Perubahan Dalam Kebijakan Privasi</b>
      <div>
        OneISS Pocket memiliki hak untuk mengubah kebijakan privasi sesuai
        dengan Syarat & Ketentuan OneISS Pocket dari waktu ke waktu.
      </div>
    </div>
  </div>
</template>

<style>
.wrap {
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 0 50px;
  text-align: justify;
}
.space {
  margin-top: 20px;
}
</style>
