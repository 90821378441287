<template>
  <section class="flex items-center flex-col pt-52 relative">
    <div id="our-solution">
      <hr class="line-title" />
    </div>
    <div class="text-center px-10 md:px-64">
      <h3>
        {{ t.t("services.title", lang) }}
      </h3>
      <div class="flex flex-col gap-10 mt-40">
        <ServicesCard
          v-for="(card, index) in filterCard"
          :key="index"
          :values="card.card"
          :right="card.right"
        />
        <div class="text-center px-40 md:px-64 mb-14" v-if="!isCollapseCard">
          <button
            class="btn !normal-case bg-[#8a2785] border-none rounded-full min-w-48 text-2xl mt-28 text-white"
            @click="onSeeMoreCard"
          >
            {{ t.t("more", lang) }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";
import ServicesCard from "@/components/portal/ServicesCard.vue";

const tlang = localStorage.getItem("lang");
const t = i18n.global;
const imageLink = (name) => require(`@/assets/services/${name}`);

export default {
  name: "Services",
  components: {
    ServicesCard,
  },
  data() {
    return {
      t,
      lang: tlang,
      isCollapseCard: false,
      cards: [
        {
          right: false,
          card: [
            {
              title: t.t("services.0.title", tlang),
              desc: t.t("services.0.desc", tlang),
              link: "/user-journey/digital-wallet",
              img: imageLink("digital_wallet.png"),
            },
            {
              title: t.t("services.1.title", tlang),
              desc: t.t("services.1.desc", tlang),
              link: "/user-journey/qris-payment",
              img: imageLink("qris_payment.png"),
            },
            {
              title: t.t("services.2.title", tlang),
              desc: t.t("services.2.desc", tlang),
              link: "",
              img: imageLink("cash_management.png"),
            },
          ],
        },
        {
          right: true,
          card: [
            {
              title: t.t("services.3.title", tlang),
              desc: t.t("services.3.desc", tlang),
              link: "",
              img: imageLink("b2b_loan.png"),
            },
            {
              title: t.t("services.4.title", tlang),
              desc: t.t("services.4.desc", tlang),
              link: "/user-journey/digital-product",
              img: imageLink("digital_product.png"),
            },
            {
              title: t.t("services.5.title", tlang),
              desc: t.t("services.5.desc", tlang),
              link: "",
              img: imageLink("remittance.png"),
            },
          ],
        },
        {
          right: false,
          card: [
            {
              title: t.t("services.6.title", tlang),
              desc: t.t("services.6.desc", tlang),
              link: "/user-journey/gold-saving",
              img: imageLink("gold_saving.png"),
            },
            {
              title: t.t("services.7.title", tlang),
              desc: t.t("services.7.desc", tlang),
              link: "",
              img: imageLink("vcn.png"),
            },
            {
              title: t.t("services.8.title", tlang),
              desc: t.t("services.8.desc", tlang),
              link: "/user-journey/buy-now",
              img: imageLink("buy_now.png"),
            },
          ],
        },
        {
          right: true,
          card: [
            {
              title: t.t("services.9.title", tlang),
              desc: t.t("services.9.desc", tlang),
              link: "/user-journey/kaspro",
              img: imageLink("kaspro.png"),
            },
            {
              title: t.t("services.10.title", tlang),
              desc: t.t("services.10.desc", tlang),
              link: "/user-journey/ekyc",
              img: imageLink("ekyc.png"),
            },
            {
              title: t.t("services.11.title", tlang),
              desc: t.t("services.11.desc", tlang),
              link: "/user-journey/cash-in",
              img: imageLink("cash_in.png"),
            },
          ],
        },
      ],
    };
  },
  methods: {
    onSeeMoreCard() {
      this.isCollapseCard = true;
    },
  },
  computed: {
    filterCard() {
      return this.isCollapseCard ? this.cards : this.cards.slice(0, 2);
    },
  },
};
</script>
