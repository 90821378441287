<template>
  <div id="product" class="section product">
    <div class="container">
      <div>
        <h3>
          <hr class="line-title my-0 mx-auto" />
          {{ t.t("product-and-service", lang) }}
        </h3>
      </div>
      <div>
        <div class="flowers-center">
          <img
            src="@/assets/templates/Product_Services_Open.png"
            id="center_flower"
          />
          <img
            src="@/assets/templates/Button_KasPro.png"
            id="btnlogo_1"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Button_KreditPro.png"
            id="btnlogo_2"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Button_RemitPro.png"
            id="btnlogo_3"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Button_DigiBos.png"
            id="btnlogo_4"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Logo_KasPro_White.png"
            id="blogo_1"
            v-show="icon"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Logo_KreditPro_White.png"
            id="blogo_2"
            v-show="icon"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Logo_RemitPro_White.png"
            id="blogo_3"
            v-show="icon"
            @click="clicked(this.display, this.icon)"
          />
          <img
            src="@/assets/templates/Logo_DigiBos_White.png"
            id="blogo_4"
            v-show="icon"
            @click="clicked(this.display, this.icon)"
          />

          <span class="spanLogo_1">
            <img
              src="@/assets/templates/Hover_Online_Transaction.png"
              class="hLogo"
              id="hLogo_1"
            />
            <div id="hLogo_content_1" class="hidden-logo">
              <div>{{ t.t("dab_icon.payment_online.title", lang) }}</div>
              <div>{{ t.t("dab_icon.payment_online.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_2">
            <img
              src="@/assets/templates/Hover_MSME.png"
              class="hLogo"
              id="hLogo_2"
            />
            <div id="hLogo_content_2" class="hidden-logo">
              <div>{{ t.t("dab_icon.msme.title", lang) }}</div>
              <div>{{ t.t("dab_icon.msme.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_3">
            <img
              src="@/assets/templates/Hover_Merchant_on_Record.png"
              class="hLogo"
              id="hLogo_3"
            />
            <div id="hLogo_content_3" class="hidden-logo">
              <div>{{ t.t("dab_icon.mor.title", lang) }}</div>
              <div>{{ t.t("dab_icon.mor.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_4">
            <img
              src="@/assets/templates/Hover_Brachless_Banking.png"
              class="hLogo"
              id="hLogo_4"
            />
            <div id="hLogo_content_4" class="hidden-logo">
              <div>{{ t.t("dab_icon.branchless_banking.title", lang) }}</div>
              <div>{{ t.t("dab_icon.branchless_banking.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_5">
            <img
              src="@/assets/templates/Hover_Serve_Unbanked.png"
              class="hLogo"
              id="hLogo_5"
            />
            <div id="hLogo_content_5" class="hidden-logo">
              <div>{{ t.t("dab_icon.unbanked_customer.title", lang) }}</div>
              <div>{{ t.t("dab_icon.unbanked_customer.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_6">
            <img
              src="@/assets/templates/Hover_Cash_Management.png"
              class="hLogo"
              id="hLogo_6"
            />
            <div id="hLogo_content_6" class="hidden-logo">
              <div>{{ t.t("dab_icon.cash_management.title", lang) }}</div>
              <div>{{ t.t("dab_icon.cash_management.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_7">
            <img
              src="@/assets/templates/Hover_KasPro_Bank.png"
              class="hLogo"
              id="hLogo_7"
            />
            <div id="hLogo_content_7" class="hidden-logo">
              <div>{{ t.t("dab_icon.kaspro.title", lang) }}</div>
              <div>{{ t.t("dab_icon.kaspro.content", lang) }}</div>
            </div>
          </span>
          <span class="spanLogo_8">
            <img
              src="@/assets/templates/Hover_Money_Transfer.png"
              class="hLogo"
              id="hLogo_8"
            />
            <div id="hLogo_content_8" class="hidden-logo">
              <div>{{ t.t("dab_icon.money_Transfer.title", lang) }}</div>
              <div>{{ t.t("dab_icon.money_Transfer.content", lang) }}</div>
            </div>
          </span>
        </div>
        <div class="flowers">
          <div v-show="sDisplay">
            <div :style="loadImg('Product_Services_1.png')" v-show="display">
              <div>
                <div>
                  <a target="_blank" href="https://kaspro.id/">
                    <img src="@/assets/product/kaspro.png" width="90" />
                  </a>
                </div>
                <div>{{ t.t("product.product1", lang) }}</div>
              </div>
              <img
                src="@/assets/templates/Logo_PCI_DSS.png"
                width="140"
                id="slogo_1"
              />
            </div>
          </div>
          <div v-show="sDisplay">
            <div :style="loadImg('Product_Services_2.png')" v-show="display">
              <div>
                <div>
                  <a target="_blank" href="https://kreditpro.id/">
                    <img src="@/assets/product/kreditpro.png" width="120" />
                  </a>
                </div>
                <div>{{ t.t("product.product2", lang) }}</div>
              </div>
              <img
                src="@/assets/templates/Logo_OJK.png"
                width="110"
                id="slogo_2"
              />
            </div>
          </div>
          <div v-show="sDisplay">
            <div :style="loadImg('Product_Services_3.png')" v-show="display">
              <div>
                <div>
                  <a target="_blank" href="https://www.remitpro.id/en">
                    <img src="@/assets/product/remitpro.png" width="120" />
                  </a>
                </div>
                <div>{{ t.t("product.product3", lang) }}</div>
              </div>
              <img
                src="@/assets/templates/Logo_Bank_Indonesia.png"
                width="140"
                id="slogo_3"
              />
            </div>
          </div>
          <div v-show="sDisplay">
            <div :style="loadImg('Product_Services_4.png')" v-show="display">
              <div>
                <div>
                  <a target="_blank" href="https://kaspro.id/feature/digibos">
                    <img src="@/assets/product/digibos.png" width="120" />
                  </a>
                </div>
                <div>{{ t.t("product.product4", lang) }}</div>
              </div>
              <img
                src="@/assets/templates/Logo_Bank_Indonesia.png"
                width="140"
                id="slogo_4"
              />
            </div>
          </div>
        </div>
      </div>
      <!--img id="ornamen-3" src="@/assets/templates/Ornamen_Bulat_03.png" />
      <img id="ornamen-4" src="@/assets/templates/Ornamen_Bulat_04.png" /-->
    </div>
  </div>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "PageProduct",
  props: {
    msg: String,
  },
  methods: {
    loadImg(img) {
      const str = require(`@/assets/templates/${img}`);
      return {
        "background-image": `url(${str})`,
        "background-size": "100% 100%",
        "background-repeat": "no-repeat",
        "background-position": "center",
      };
    },
    clicked(dKelopak, dIcon) {
      this.display = dKelopak == false ? true : false;
      this.icon = dIcon == false ? true : false;

      if (window.screen.width <= 560) {
        this.sDisplay = dKelopak == false ? true : false;
      }
    },
  },
  data() {
    return {
      t: i18n.global,
      lang: tlang,
      display: false,
      sDisplay: false,
      icon: false,
      windowHeight: window.innerHeight,
    };
  },
  mounted() {
    this.display = true;
    this.sDisplay = true;
    this.icon = false;
    this.clicked(this.display, this.icon);
  },
};
</script>

<style>
#slogo_1 {
  position: absolute;
  bottom: 100px;
  left: 100px;
}
#slogo_2 {
  position: absolute;
  bottom: 100px;
  right: 100px;
}
#slogo_3 {
  position: absolute;
  top: 100px;
  left: 100px;
}
#slogo_4 {
  position: absolute;
  top: 100px;
  right: 100px;
}

#center_flower {
  z-index: 1;
}
#blogo_1 {
  cursor: pointer;
  position: absolute;
  top: 56px;
  left: 56px;
  width: 80px;
  z-index: 1;
}
#blogo_2 {
  cursor: pointer;
  position: absolute;
  top: 60px;
  right: 50px;
  width: 90px;
  z-index: 1;
}
#blogo_3 {
  cursor: pointer;
  position: absolute;
  bottom: 55px;
  left: 65px;
  width: 80px;
  z-index: 1;
}
#blogo_4 {
  cursor: pointer;
  position: absolute;
  bottom: 60px;
  right: 60px;
  width: 80px;
  z-index: 1;
}

#btnlogo_1 {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 233px;
  z-index: 1;
}
#btnlogo_2 {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 234px;
  z-index: 1;
}
#btnlogo_3 {
  cursor: pointer;
  position: absolute;
  bottom: 2px;
  left: 1px;
  width: 234px;
  z-index: 1;
}
#btnlogo_4 {
  cursor: pointer;
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 234px;
  z-index: 1;
}

/* Logo Circle */
.spanLogo_1 {
  position: absolute;
  top: 10.6rem;
  right: 17.5rem;
  z-index: 1;
}
.spanLogo_2 {
  position: absolute;
  top: 17rem;
  right: 11.5rem;
  z-index: 1;
}
.spanLogo_3 {
  position: absolute;
  top: 25.2rem;
  right: 11.5rem;
  z-index: 1;
}
.spanLogo_4 {
  position: absolute;
  top: 32.3rem;
  right: 17.5rem;
  z-index: 1;
}
.spanLogo_5 {
  position: absolute;
  top: 10.6rem;
  left: 17.5rem;
  z-index: 1;
}
.spanLogo_6 {
  position: absolute;
  top: 17rem;
  left: 11.5rem;
  z-index: 1;
}
.spanLogo_7 {
  position: absolute;
  top: 25.2rem;
  left: 11.5rem;
  z-index: 1;
}
.spanLogo_8 {
  position: absolute;
  top: 32.3rem;
  left: 17.5rem;
  z-index: 1;
}

.hidden-logo {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  display: none;
  position: absolute;
  padding: 20px 30px;
}
.hidden-logo > div {
  min-width: 300px;
}
.hidden-logo > div:nth-child(1) {
  color: #8a2785;
  font-weight: 600;
  margin-bottom: 10px;
}
.hidden-logo > div:nth-child(2) {
  font-size: 16px;
}
.hLogo {
  cursor: pointer;
  width: 62px;
}

#hLogo_content_1,
#hLogo_content_2 {
  top: -12rem;
  left: 40px;
}
#hLogo_content_3,
#hLogo_content_4 {
  top: 3rem;
  left: 40px;
}
#hLogo_content_5,
#hLogo_content_6 {
  top: -12rem;
  right: 4rem;
}
#hLogo_content_7,
#hLogo_content_8 {
  top: 3rem;
  right: 4rem;
}

#hLogo_1:hover + #hLogo_content_1,
#hLogo_2:hover + #hLogo_content_2,
#hLogo_3:hover + #hLogo_content_3,
#hLogo_4:hover + #hLogo_content_4,
#hLogo_5:hover + #hLogo_content_5,
#hLogo_6:hover + #hLogo_content_6,
#hLogo_7:hover + #hLogo_content_7,
#hLogo_8:hover + #hLogo_content_8 {
  display: block;
}
</style>
