<template>
  <div>
    <div>{{ year }}</div>
    <div v-html="lists"></div>
  </div>
  <div><img :src="loadImg(image)" /></div>
</template>

<script>
export default {
  name: "journey",
  props: {
    image: {
      type: String,
      default: "",
    },
    year: {
      type: String,
      default: "",
    },
    lists: {
      type: String,
      default: "",
    },
  },
  methods: {
    loadImg(img) {
      return require(`@/assets/journey/${img}`);
    },
  },
};
</script>
