import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import UserJourney from "../views/UserJourney.vue";
import NewHome from "../views/NewHome.vue";
import OurValue from "../views/OurValue.vue";
import NewsRoom from "../views/NewsRoom";
import NewsRoomDetails from "../views/NewsRoomDetails";
import BLOG from "../views/Blog";
import CareerMain from "../views/Career";
import CareerDetails from "../views/CareerDetails";
//import CrtUsrPage from "../views/CrtUsr";
//import LoginPage from "../views/Login";
//import DashPage from "../views/Dashboard";
import PrivacyISS from "../views/PrivacyISS";

import Investors from "../views/Investors";

const routes = [
  {
    path: "/",
    name: "NewHome",
    component: NewHome,
  },
  {
    path: "/user-journey/:id",
    name: "User Journey",
    component: UserJourney,
  },
  {
    path: "/ourValue",
    name: "OurValue",
    component: OurValue,
  },
  {
    path: "/newsRoom",
    name: "NewsRoom",
    component: NewsRoom,
  },
  {
    path: "/newsRoomDetails/:id",
    name: "NewsRoomDetails",
    component: NewsRoomDetails,
  },
  {
    path: "/blog",
    name: "BLOG",
    component: BLOG,
  },
  {
    path: "/blog/:id",
    name: "BlogDetail",
    component: BLOG,
  },
  {
    path: "/career",
    name: "Career",
    component: CareerMain,
  },
  {
    path: "/careerdetails/:id",
    name: "CareerDetails",
    component: CareerDetails,
  },
  {
    path: "/about",
    name: "Home",
    component: Home,
  },
  {
    path: "/investor/relations",
    name: "InvestorRelations",
    component: Investors,
  },
  {
    path: "/investor/presentations",
    name: "InvestorPresentations",
    component: Investors,
  },
  {
    path: "/investor/secfilings",
    name: "SecFilings",
    component: Investors,
  },
  {
    path: "/privacyiss",
    name: "PrivacyISS",
    component: PrivacyISS,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
