<template>
  <Navbar bgimage="Logo_Digiasia_Color.png" />
  <InvestorRelations v-if="segmentRelations == true" />
  <InvestorPresentations v-if="segmentPresentations == true" />
  <InvestorFilings v-if="segmentFilings == true" />
  <ContactUsInvestors />
  <Copyright />
</template>

<script>
import Navbar from "@/components/portal/Navbar.vue";
import InvestorRelations from "@/components/portal/InvestorRelations.vue";
import InvestorPresentations from "@/components/portal/InvestorPresentations.vue";
import InvestorFilings from "@/components/portal/InvestorFilings.vue";
import ContactUsInvestors from "@/components/portal/ContactUsInvestors.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "Investors",
  components: {
    Navbar,
    InvestorRelations,
    InvestorPresentations,
    InvestorFilings,
    ContactUsInvestors,
    Copyright,
  },
  data: () => ({
    segmentRelations: false,
    segmentPresentations: false,
    segmentFilings: false,
  }),
  mounted() {
    switch (window.location.pathname) {
      case "/investor/relations":
        this.segmentRelations = true;
        break;
      case "/investor/presentations":
        this.segmentPresentations = true;
        break;
      case "/investor/secfilings":
        this.segmentFilings = true;
        break;
    }
  },
};
</script>
