<template>
  <section class="flex justify-center my-40">
    <div
      class="card lg:card-side w-8/12 bg-[#812780] shadow-xl xl:h-[36rem] rounded-[4rem]"
    >
      <figure class="flex xl:!hidden">
        <img src="@/assets/journey/contact_us.png" />
      </figure>
      <div class="card-body max-w-3xl">
        <h2 class="card-title !text-white lg:!text-4xl font-bold">
          {{ t.t("user_journey_detail.get_started", lang) }}
        </h2>
        <p class="!text-white text-4xl mt-8">
          {{ t.t("user_journey_detail.get_started_desc", lang) }}
        </p>
        <div class="card-actions mb-12 mt-8">
          <a
            href="/#contact-us"
            class="btn btn-wide btn-lg bg-white text-black rounded-full hover:bg-white hover:text-black"
          >
            {{ t.t("contact-us", lang) }}
          </a>
        </div>
      </div>
      <figure
        class="md:max-h-96 max-h-full max-w-3xl relative lg:absolute right-0 bottom-56 !overflow-visible !hidden xl:!flex"
        style="overflow: visible"
      >
        <img src="@/assets/journey/contact_us.png" />
      </figure>
    </div>
  </section>
</template>

<script>
import i18n from "@/plugin/locales/i18n";

const tlang = localStorage.getItem("lang");

export default {
  name: "ContactUs",
  data() {
    return {
      t: i18n.global,
      lang: tlang,
    };
  },
};
</script>
