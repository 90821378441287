<template>
  <Navbar bgimage="Logo_Digiasia_Color.png" />
  <OurSlide />
  <OurSpirit />
  <OurYoutube />
  <Footer />
  <Copyright />
</template>

<script>
import Navbar from "@/components/portal/Navbar.vue";
import OurSlide from "@/components/portal/OurSlide.vue";
import OurSpirit from "@/components/portal/OurSpirit.vue";
import OurYoutube from "@/components/portal/OurYoutube.vue";
import Footer from "@/components/portal/PageFooterSecond.vue";
import Copyright from "@/components/portal/PageCopyright.vue";

export default {
  name: "OurValue",
  components: {
    Navbar,
    OurSlide,
    OurSpirit,
    OurYoutube,
    Footer,
    Copyright,
  },
};
</script>
