<template>
  <div class="blog-details">
    <div class="date">{{ loadDate(param.date) }}</div>
    <div class="title">{{ param.title }}</div>
    <div class="img">
      <img :src="loadImg(param.img)" />
    </div>
    <div class="desc" v-html="param.description"></div>
    <div class="share">
      <span>Share:</span>
      <span>
        <a :href="loadLinkSosmed('fb')" target="_blank">
          <img src="@/assets/icons/icon_facebook.png" />
        </a>
      </span>
      <span>
        <a :href="loadLinkSosmed('tw')" target="_blank">
          <img src="@/assets/icons/icon_twitter.png" />
        </a>
      </span>
      <span>
        <a :href="loadLinkSosmed('ln')" target="_blank">
          <img src="@/assets/icons/icon_linkedin.png" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BlogDetails",
  props: {
    param: Object,
  },
  methods: {
    loadDate(value) {
      if (value == "" || value == undefined) {
        return "-";
      } else {
        return moment(String(value)).format("DD MMMM YYYY");
      }
    },
    loadImg(img) {
      return require(`@/assets/blog/lists/${img}`);
    },
    loadLinkSosmed(nil) {
      var searchURL = new URL(window.location);
      var val;

      switch (nil) {
        case "fb":
          val = `https://www.facebook.com/sharer/sharer.php?u=${
            searchURL["origin"] + searchURL["pathname"]
          }`;
          break;
        case "tw":
          val = `http://twitter.com/intent/tweet?url=${
            searchURL["origin"] + searchURL["pathname"]
          }`;
          break;
        case "ln":
          val = `https://www.linkedin.com/shareArticle?mini=true&url=${
            searchURL["origin"] + searchURL["pathname"]
          }`;
          break;
      }
      return val;
    },
  },
  data: () => ({
    //
  }),
  mounted() {
    //
  },
};
</script>

<style>
.blog-details {
  display: inline-block;
  margin-top: 5px;
  padding-right: 30px;
  position: relative;
  text-align: left;
  width: 100%;
}
.date {
  font-size: 14px;
  margin-bottom: 10px;
}
.title {
  color: #8a2785;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.img > img {
  border-radius: 10px;
  margin-bottom: 40px;
  width: 100%;
}
.desc {
  font-size: 16px;
  margin-bottom: 50px;
  text-align: justify;
}
.share {
  color: #8a2785;
  display: inline-flex;
  margin-bottom: 50px;
}
.share img {
  margin-left: 10px;
  width: 32px;
}
</style>
