<template>
  <div id="ouryoutube" class="section ouryoutube">
    <div class="container">
      <div class="youtitle">
        <div>Youtube Channel</div>
        <div>
          <a
            href="https://www.youtube.com/channel/UC-UEkBY6QRxlCORbl3W7-Ig/videos"
            target="_blank"
          >
            <SvgYoutube />&nbsp;<span>Subscribe Digiasia Bios</span>
          </a>
        </div>
      </div>
      <div
        class="youcontent"
        :style="{ 'grid-template-columns': `repeat(${data.length}, 1fr)` }"
        v-if="data.length < 5"
      >
        <iframe
          class="frameYoutube"
          frameBorder="0"
          v-for="(datas, index) in data"
          :key="index"
          :src="loadYTLink(datas.video_id)"
        ></iframe>
      </div>
      <div
        class="youcontent"
        :style="{ 'grid-template-columns': `repeat(5, 1fr)` }"
        v-else
      >
        <iframe
          class="frameYoutube"
          frameBorder="0"
          v-for="(datas, index) in data"
          :key="index"
          :src="loadYTLink(datas.video_id)"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import SvgYoutube from "@/components/logo/SvgYoutube";
import axios from "axios";
import dummy from "@/assets/_dummy/youtube.json";

export default {
  components: {
    SvgYoutube,
  },
  methods: {
    loadYTLink(id) {
      return "https://www.youtube.com/embed/" + id;
    },
  },
  data() {
    return {
      data: [],
      dummy: dummy,
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_IP_SERVER + "youtube")
      .then((res) => {
        this.data = res.data.data;
        console.log(this.data);
      })
      .catch((error) => {
        console.log(error);
        this.data = this.dummy;
      });
  },
};
</script>

<style scoped>
div.section.ouryoutube > .container {
  display: inline-block;
  padding: 4% 0;
  width: 80%;
}

div.section.ouryoutube > .container > .youtitle {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  margin-bottom: 30px;
}

div.section.ouryoutube > .container > .youtitle > div:nth-child(1) {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
}

div.section.ouryoutube > .container > .youtitle > div:nth-child(2) {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  letter-spacing: 0.5px;
}

div.section.ouryoutube > .container > .youtitle > div:nth-child(2) > a {
  background-color: #e3d2e2;
  border-radius: 5px;
  color: #8a2785; /* ungu tua */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 5px 20px;
  text-decoration: none;
}

div.section.ouryoutube > .container > .youcontent {
  display: grid;
  align-content: center;
  justify-content: center;
  /*grid-template-columns: repeat(5, 1fr);*/
  gap: 1px;
}

div.section.ouryoutube > .container > .youcontent > iframe {
  width: 270px;
}
</style>
